<template>
  <div class="valid-phone-wrapper">
    <van-nav-bar left-arrow :border="false" @click-left="onClickLeft">
    </van-nav-bar>
    <div class="valid-main">
      <div class="title">首次登录，请验证账号</div>
      <div class="mobile">发送至：{{ mobile }}</div>
      <div
        class="vcode-input-box"
        :class="{ red: errorMsg }"
        @click="clickInput"
      >
        <span class="error-msg">{{ errorMsg }}</span>
        <input
          ref="vcodeInput"
          v-model="tempVcode"
          class="vcode-input"
          type="number"
          @input="inputVcode"
        />
        <span
          v-for="(item, index) in vcodeList"
          :key="index"
          class="vcode-item"
          >{{ item }}</span
        >
      </div>
      <div class="get-vcode-box">
        <span class="get-vcode" :class="{ dis: lock }" @click="onGetVcode">{{
          vcodeBtnText
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/api/authorization";
import { Toast } from "vant";
import { mapMutations, mapState } from "vuex";
const defaultTime = 120;
const errorMsg = "验证码输入错误，请重新输入";
export default {
  name: "ValidPhone",
  data() {
    return {
      errorMsg: "",
      mobile: "",
      tempVcode: "",
      // vcode: "",
      lock: false,
      isFirst: true,
      vcodeBtn1: "获取验证码",
      vcodeBtn2: "重新获取验证码",
      vcodeBtn3: "", // 用作倒计时
      time: defaultTime
    };
  },
  computed: {
    ...mapState({
      vcode: state => state.login.validPhoneInfo.code
    }),
    vcodeList() {
      const arr = this.vcode.split("");
      arr.length = 6;
      return arr;
    },
    vcodeBtnText() {
      if (this.isFirst) {
        return this.vcodeBtn1;
      } else {
        if (this.lock) {
          return this.vcodeBtn3;
        } else {
          return this.vcodeBtn2;
        }
      }
    }
  },
  created() {
    const { mobile } = this.$route.query;
    this.mobile = mobile;
    this.timer = null;
    this.tempVcode = this.vcode;
  },
  methods: {
    ...mapMutations({
      setValidPhoneInfo: "setValidPhoneInfo"
    }),
    setVcode(code) {
      this.setValidPhoneInfo({
        mobile: this.mobile,
        code
      });
    },
    onClickLeft() {
      if (window.APP.getPlatform().isAndroid) {
        window.android.logout();
      } else {
        this.$router.replace("/login");
      }
    },
    clickInput() {
      const v = this.$refs.vcodeInput.value;
      this.$refs.vcodeInput.focus();
      this.tempVcode = "";
      this.tempVcode = v;
    },
    onGetVcode() {
      if (this.lock) {
        return;
      }
      if (this.mobile.length !== 11) {
        this.$toast("手机号格式不正确");
        return;
      }
      this.getVcode();
    },
    renderButton() {
      this.vcodeBtn3 = this.time + "s";
      this.timer = setInterval(() => {
        this.time--;
        this.vcodeBtn3 = this.time + "s";
        if (this.time === 0) {
          this.timer && clearInterval(this.timer);
          this.timer = null;
          this.time = defaultTime;
          this.lock = false;
          return;
        }
      }, 1000);
    },
    getVcode() {
      this.lock = true;
      if (this.isFirst) {
        this.isFirst = false;
      }
      auth
        .getAuthorizationInfoTelVerificationCode(this.mobile)
        .then(res => {
          console.log("发送状态:", res);
          Toast(`验证码发送成功！`);
          this.renderButton();
        })
        .catch(() => {
          Toast(`验证码获取失败！`);
        });
    },
    inputVcode() {
      if (`${this.tempVcode}`.length > 6) {
        this.tempVcode = `${this.tempVcode}`.substr(0, 6);
        return;
      }
      this.setVcode(this.tempVcode);
      if (this.vcode.length === 6) {
        auth
          .getAuthorizationIValidateTelVerificationCode({
            mobile: this.mobile,
            code: this.vcode
          })
          .then(res => {
            this.errorMsg = "";
            if (res) {
              this.$router.push({
                path: "/user/setnewpwd"
              });
              return;
            }
            this.errorMsg = errorMsg;
          })
          .catch(e => {
            console.log("e", e);
          });
      } else {
        this.errorMsg = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.valid-phone-wrapper {
  .van-nav-bar {
    background-color: #fff;
    ::v-deep .van-icon {
      color: #3b4664;
    }
  }
  .valid-main {
    padding: 0 24px;
    .title {
      font-size: 24px;
      color: #3b4664;
      margin-top: 12px;
    }
    .mobile {
      font-size: 12px;
      color: #8c8f97;
      margin-top: 12px;
    }
    .vcode-input-box {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 78px;
      height: 26px;
      .vcode-input {
        position: absolute;
        top: -30px;
        width: 300px;
        left: -1000px;
      }
      &.red {
        .vcode-item {
          color: #f13030;
          &::after {
            background-color: #f13030;
          }
        }
      }
      .vcode-item {
        position: relative;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        flex: 1;
        background-color: #fff;
        color: #3b4664;
        &:not(:last-child) {
          margin-right: 12px;
        }
        &::after {
          position: absolute;
          content: "";
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #c7c9d0;
        }
      }
      .error-msg {
        position: absolute;
        bottom: -24px;
        left: 0;
        white-space: nowrap;
        font-size: 12px;
        color: #f13030;
      }
    }
    .get-vcode-box {
      margin-top: 16px;
      text-align: right;
      .get-vcode {
        font-size: 12px;
        color: $--color-primary;
        &.dis {
          color: #8c8f97;
        }
      }
    }
  }
}
</style>
