export default {
  state: {
    cacheSize: 0,
    versionData: {}
  },
  mutations: {
    setCacheSize(state, data) {
      state.cacheSize = data;
    },
    setVersion(state, data) {
      state.versionData = data;
    }
  },
  actions: {
    clearCacheCallBack({ commit }, data) {
      this.setCacheSize();
    }
  }
};
