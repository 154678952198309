export default {
  state: {
    geo: null,
    saveAccidentReportId: "",
    activeAccident: "",
    activeResource: ""
  },
  mutations: {
    setGeo(state, val) {
      state.geo = val;
    },
    setSaveAccidentReportId(state, val) {
      state.saveAccidentReportId = val;
    },
    setActiveAccident(state, val) {
      state.activeAccident = val;
    },
    setActiveResource(state, val) {
      state.activeResource = val;
    }
  }
};
