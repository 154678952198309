const InspectionEquipmentDetail = () =>
  import("@/views/inspection/InspectionEquipmentDetail");

export default [
  {
    path: "/inspection",
    name: "inspection",
    component: () => import("@/views/inspection/Inspection"),
    meta: { roles: ["inspection"] }
  },
  {
    path: "/inspection/:id(\\d+)",
    name: "inspectionDetail",
    meta: { roles: ["inspection"] },

    component: () => import("@/views/inspection/InspectionDetail")
  },
  {
    path: "/inspection/:id(\\d+)/equipments/:equipmentId(\\d+)",
    name: "inspectionEquipmentDetail",
    meta: { roles: ["inspection"] },
    component: InspectionEquipmentDetail
  },
  {
    path: "/inspection/:id(\\d+)/equipments/:equipmentId(\\d+)/edit",
    name: "inspectionEquipmentEdit",
    meta: { roles: ["inspection"] },
    component: InspectionEquipmentDetail
  },
  {
    path: "/inspection/:id(\\d+)/equipments/:type",
    name: "inspectionEquipmentAdd",
    meta: { roles: ["inspection"] },
    component: InspectionEquipmentDetail
  }
];
