import axios from "@/utils/axios";
// import { psm as API_PSM } from "../utils/axios";
import { WORKTICKET_TYPE } from "@/views/workticket/utils/constant";

import apiPath from "../utils/apiPath";
const API_PSM = apiPath.psm;
const API_AUTHORIZATION = apiPath.authorization;
const basics = apiPath.basics;
const camera = apiPath.camera;

/**
 * 作业查询
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkticketList = params =>
  axios.get(API_PSM + "/page/app-work", { params: { ...params } });

/**
 * 作业查询:与我有关
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkticketListMine = params =>
  axios.get(API_PSM + "/page/app-work/mine", { params: { ...params } });

/**
 * 作业查询:待处理
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkticketListTodo = () =>
  axios.get(API_PSM + "/list/app-work/todo", { params: {} });

/**
 * 作业流程
 * @param ticketId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkticketProcess = ticketId =>
  axios.get(API_PSM + "/list/app-work/operation-log", { params: { ticketId } });

/**
 * 作业前安全分析 过程记录
 * @param jsaId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkBeforeProcess = jsaId =>
  axios.get(API_PSM + "/list/work/jsa/operation", { params: { jsaId } });

/**
 * 安全作业详情，基本信息接口
 * @param ticketId
 * @returns {*}
 */
export const getWorkticketBaseInfo = ticketId =>
  axios.get(API_PSM + "/info/work_tickets/" + ticketId);

/**
 * 获取作业类型详情
 * @returns {*}
 * @param specialJobType
 * @param ticketId 作业id
 */
export const getWorkticketTypeDetail = (specialJobType, specialId) =>
  axios.get(
    API_PSM + `/info/special_works/${specialJobType}/${specialId}?baseInfo`
  );

export const getJSAList = params =>
  axios.get(API_PSM + "/page/jsas", { params: { ...params } });

/**
 * 安全分析查询
 * @param ticketId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSafetyAnalysis = ticketId =>
  axios.get(API_PSM + "/list/app-work/safety-analysis", {
    params: { ticketId }
  });

export const updateSafetyAnalysis = (id, params) =>
  axios.post(API_PSM + `/update/app-work/analysis/${id}`, { ...params });

export const addSafetyAnalysis = params =>
  axios.post(API_PSM + `/add/app-work/analysis`, { ...params });

/**
 * 完成作业安全分析
 * @param ticketId
 * @returns {*}
 */
export const finishSafetyAnalysis = ticketId =>
  axios.post(API_PSM + `/update/app-work/finish-analysis/${ticketId}`);

/**
 * 完成审批
 * @param ticketId
 * @returns {*}
 */
export const finishApproving = ticketId =>
  axios.post(API_PSM + `/update/app-work/finish-approve/${ticketId}`);

/**
 * 进入安全分析
 * @param ticketId
 * @returns {*}
 */
export const updateGoSafetyAnalysis = ticketId =>
  axios.post(API_PSM + `/update/app-work/start-analyse/${ticketId}`);

/**
 * 获取危害辨识列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkHazard = workTypeId =>
  axios.get(API_PSM + `/select/work-hazard`, { params: { workTypeId } });

/**
 * 获取危害辨识列表,带安全措施
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkHazardSafetyMeasure = workTypeId =>
  axios.get(API_PSM + `/select/work-hazard/safety-measure`, {
    params: { workTypeId }
  });

/**
 * 一般检修-专项下拉数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getOverhaulSpecial = () =>
  axios.get(API_PSM + "/select/work/type/special");

/**
 * 一般检修-其他下拉数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getOverhaulOther = () =>
  axios.get(API_PSM + "/select/work/type/other");

/**
 * 受限空间类型
 * @returns {*}
 */
export const getWorkSpaceType = () => axios.get(API_PSM + `/select/space-type`);

/**
 * 更新作业
 * @param specialJobType
 * @param specialWorkId
 * @param params
 * @returns {*}
 */
export const updateSpecialWorks = (specialJobType, specialWorkId, params) => {
  let name = Object.keys(WORKTICKET_TYPE).find(
    key => WORKTICKET_TYPE[key] == specialJobType && isNaN(key)
  );
  return axios.post(
    API_PSM + `/update/special_works/${name}/${specialWorkId}`,
    { ...params }
  );
};

/**
 * 获取安全交底
 * @param ticketId
 * @returns {*}
 */
export const getWorkSafeWork = ticketId =>
  axios.get(API_PSM + `/list/app-work/worker`, { params: { ticketId } });

/**
 * 添加作业安全交底签名
 * @param fileId
 * @param personId
 * @param personType
 * @param ticketId
 * @returns {*}
 */
export const addWorkSignature = params =>
  axios.post(API_PSM + `/add/app-work/signature`, { ...params });

/**
 * 开票审批
 * @param ticketId
 * @param operation  通过=1；取消=0
 * @param remark 备注：取消原因
 * @returns {*}
 */
export const appWorkStartApprove = (ticketId, operation = 1, remark) =>
  axios.post(API_PSM + `/update/app-work/start-approve/${ticketId}`, {
    operation,
    remark
  });

/**
 * 添加气体检测
 * @param specialTicketId
 * @param monitorItemList
 * @param validTime
 * @param result
 * @param remark
 * @returns {*}
 */
export const addWorkGasMonitor = params =>
  axios.post(API_PSM + `/add/app-work/gas-monitor`, { ...params });

/**
 * 完成安全交底
 * @param ticketId
 * @returns {*}
 */
export const appWorkSafekDisclosure = (ticketId, files) =>
  axios.post(API_PSM + `/update/app-work/disclosure/${ticketId}`, {
    files: [...files]
  });

/**
 * 查询作业记录
 * @param ticketId
 * @returns {*}
 */
export const getWorkAttachment = ticketId =>
  axios.get(API_PSM + `/list/app-work/work-record`, { params: { ticketId } });

/**
 * 修改作业记录
 * @param ticketId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateWorkAttachment = (ticketId, record, files) =>
  axios.post(API_PSM + `/add/app-work/work-record/${ticketId}`, {
    record,
    files
  });

/**
 * 查询安全交底现场照片
 * @param ticketId
 * @returns {*}
 */
export const getWorkWorkSite = ticketId =>
  axios.get(API_PSM + `/list/app-work/work-site`, { params: { ticketId } });

/**
 * 完成气体检测
 * @param ticketId
 * @returns {*}
 */
export const finishGasMonitor = ticketId =>
  axios.post(API_PSM + `/update/app-work/finish-monitor/${ticketId}`);

/**
 * 开始作业
 * @param ticketId
 * @returns {*}
 */
export const startWorking = (ticketId, params) =>
  axios.post(API_PSM + `/update/app-work/to-work/${ticketId}`, params);

/**
 * 完成作业
 * @param ticketId
 * @returns {*}
 */
export const finishWorking = (ticketId, params) =>
  axios.post(API_PSM + `/update/app-work/finish/${ticketId}`, params);

/**
 * 终止作业
 * @param ticketId
 * @returns {*}
 */
export const stopWorking = (ticketId, realStart, remark) =>
  axios.post(API_PSM + `/update/app-work/stop/${ticketId}`, {
    realStart,
    remark
  });

/**
 * 作业验收
 * @param operation  通过=1；取消=0
 * @param remark 备注：取消原因
 * @returns {*}
 */
export const finishWorkingDeliver = (
  ticketId,
  operation = 1,
  remark,
  deliverList,
  deliverListInfo
) =>
  axios.post(API_PSM + `/update/app-work/deliver/${ticketId}`, {
    operation,
    remark,
    deliverList,
    deliverListInfo
  });

/**
 * 安全分析按钮检测编辑状态
 * @param ticketId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const validateWorkWork = ticketId =>
  axios.get(API_PSM + `/validate/app-work/ticket-submit`, {
    params: { ticketId }
  });

// LiYajie 过滤禁用的人员
/**
 * 作业人员查询
 * @param workTypeId
 * @param department
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkWorkPerson = workTypeId =>
  axios.get(API_PSM + `/list/person`, {
    params: { workTypeId }
  });

/**
 * 统计特殊作业(状态饼图)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkStatusAnalysis = params =>
  axios.get(API_PSM + `/works/count/status`, {
    params: { ...params }
  });

/**
 * 统计特殊作业(类型饼图)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkTypeAnalysis = params =>
  axios.get(API_PSM + `/works/count/type`, {
    params: { ...params }
  });

/**
 * 统计作业数量趋势(类型)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkNumAnalysis = params =>
  axios.get(API_PSM + `/works/count/type-group`, {
    params: { ...params }
  });

/**
 * 作业类别和区域总数
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkCountNumAnalysis = params =>
  axios.get(API_PSM + `/works/count/sums`, {
    params: { ...params }
  });

/**
 * 作业单位的汇总
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkDeptNumAnalysis = params =>
  axios.get(API_PSM + `/works/count/sums/dept-group`, {
    params: { ...params }
  });

/**
 * 统计作业趋势(作业区域)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkPoiAnalysis = params =>
  axios.get(API_PSM + `/works/count/poi-group`, {
    params: { ...params }
  });

/**
 * 统计作业趋势(作业单位)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkDeptAnalysis = params =>
  axios.get(API_PSM + `/works/count/dept-group`, {
    params: { ...params }
  });

/**
 * 获取气体检测项
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkGasMonitorItems = params =>
  axios.get(API_PSM + `/list/work-type/gas-monitor-items`, {
    params
  });

/**
 * 气体检测列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkGasMonitorList = params =>
  axios.get(API_PSM + `/page/gas-monitor-records`, {
    params: { ...params }
  });

/**
 * 查询气体检测记录列表
 * @param specialTicketId
 * @returns {*}
 */
export const getWorkGasMonitor = specialId =>
  axios.get(API_PSM + `/list/app/gas-monitor-tip`, {
    params: { specialId }
  });

/**
 * 气体检测详情
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkGasMonitorDetail = id =>
  axios.get(API_PSM + `/gas-monitor-records/${id}`);

/**
 * 最近一条气体检测记录
 * @param specialTicketId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkLatestGasMonitor = specialTicketId =>
  axios.get(API_PSM + `/list/app-work/gas-monitor`, {
    params: { specialTicketId }
  });

/**
 * 获取安全措施
 * @param hazardId  危害辨识，","拼接
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkSafetyMeasure = hazardId =>
  axios.get(API_PSM + `/list/work-hazard/safety-measure`, {
    params: { hazardId }
  });

// LiYajie 过滤禁用的人员
/**
 * 获取作业人员
 * @param workTypeId  特殊作业类型id
 * @param department  所属部门id,多个逗号拼接
 * @param contractorId 承包商部门id,多个逗号拼接
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkListPersons = ({
  type,
  workTypeId,
  department = "",
  contractorId,
  accountStates = 20
}) => {
  return axios.get(API_PSM + `/list/person`, {
    params: { type, workTypeId, department, contractorId, accountStates }
  });
};
// LiYajie 过滤禁用的人员
/**
 * 获取作业人员, 有资质的并且资质没有过期的
 * @param workTypeId  特殊作业类型id
 * @param department  所属部门id,多个逗号拼接
 * @param contractorId 承包商部门id,多个逗号拼接
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getWorkListPersonsWorker = params => {
  params.accountStates = 20;
  return axios.get(`${API_PSM}/select/special-work/worker`, {
    params
  });
};

/**
 * 详情-作业单位人员-吊装相关人员
 * @param workTypeId  特殊作业类型id
 * @param department  所属部门id,多个逗号拼接
 * @param contractorId 承包商部门id,多个逗号拼接
 * @param licenseType 资质，多个逗号拼接
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getWorkerByLicenseType(params) {
  params.accountStates = 20;
  return axios.get(`${API_PSM}/select/special-work/worker/license-type`, {
    params
  });
}

/**
 * 暂停作业
 */
export const pauseWork = (ticketId, remark) =>
  axios.post(API_PSM + `/update/app-work/pause-work/${ticketId}`, { remark });

/**
 * 继续作业
 */
export const continueWork = ticketId =>
  axios.post(API_PSM + `/update/app-work/continue-work/${ticketId}`);

/**
 * 关闭作业
 */
export const closeWork = (ticketId, params) =>
  axios.post(API_PSM + `/update/app-work/close/${ticketId}`, params);

/**
 * 人员变更-作业类别下拉
 */
export const getWorkType = ticketId =>
  axios.get(API_PSM + `/select/work-type/${ticketId}`);

/**
 * 人员变更-人员类型下拉
 */
export const getWorkPerson = params =>
  axios.get(API_PSM + `/select/worker-type`, { params });

/**
 * 人员变更-人员类型下拉
 */
export const getWorkCurrentPerson = (workTypeId, params) =>
  axios.get(API_PSM + `/select/work-current-person/${workTypeId}`, { params });

/**
 * 人员变更-提交
 */
export const modifyPerson = (ticketId, params) =>
  axios.post(API_PSM + `/update/app-work/modify-person/${ticketId}`, params);

/**
 * 人员变更记录
 */
export const getPersonModifyRecords = ticketId =>
  axios.get(API_PSM + "/select/person-modify/records", {
    params: { ticketId }
  });

/**
 * 安全交底-增加受影响相关方
 */
export const addAffectedPerson = (ticketId, params) =>
  axios.post(API_PSM + `/add/app-work/affected-person/${ticketId}`, params);

/**
 * 安全交底-修改安全交底人
 */
export const addSafetyPerson = (ticketId, params) =>
  axios.post(API_PSM + `/add/app-work/safety-person/${ticketId}`, params);

/**
 * 安全交底-获取安全措施
 */
export const getSafetyMeasure = (ticketId, specialId) =>
  axios.get(API_PSM + `/list/special/safety-measure`, {
    params: {
      specialId,
      ticketId
    }
  });
/**
 * 安全交底-安全措施确认签名保存
 */
export const confirmSafetyMeasure = params =>
  axios.post(API_PSM + `/confirm/safety-measure`, params);
/**
 * 查询作业票是否都已经签名完成
 */
export const validateSigned = ticketId =>
  axios.get(API_PSM + `/validate/safety-measure`, {
    params: {
      ticketId
    }
  });

/**
 * 查询转审详情
 * @param {小票id} id
 */
export const transferApprove = (id, params) => {
  return axios.get(API_PSM + `/info/app-work/transfer-approve/${id}`, {
    params
  });
};

/**
 * 发起转审
 * @param {小票id} id
 */
export const transferApprovePost = (id, params) => {
  return axios.post(
    API_PSM + `/update/app-work/transfer-approve/${id}`,
    params
  );
};

/**
 * 审批退回
 * @param {*} id
 * @param {*} params
 */
export const workticketBack = (id, params) => {
  return axios.post(
    API_PSM + `/update/app-work/approve-rollback/${id}`,
    params
  );
};
/**
 * 整张流程图查询
 * @param {*} id
 * @param {*} params
 */
export const workticketGraph = ticketId => {
  return axios.get(API_PSM + `/list/app-work/operation-graph`, {
    params: {
      ticketId
    }
  });
};
/**
 * 整张流程图人员信息查询
 * @param {*} id
 * @param {*} params
 */
export const workticketWorker = (id, type) => {
  return axios.get(API_PSM + `/select/graph-worker/${type}/${id}`);
};
/**
 * 整张流程图审批中信息查询
 * @param {*} id
 * @param {*} params
 */
export const workticketApprove = id => {
  return axios.get(API_PSM + `/list/graph-approve/${id}`);
};
/*
 * 检测作业气体检测时间限制是否超时
 */
export const checkedGasExpires = specialId =>
  axios.get(`${API_PSM}/validate/gas-expires`, {
    params: {
      specialId
    }
  });
/*
 * 安全措施确认签字提交接口
 */
export const safetyMeasureSubmit = params =>
  axios.post(`${API_PSM}/submit/safety-measure`, params);
/*
 * 开始安全交底日志
 */
export const addStartSafetyLog = ticketId =>
  axios.post(`${API_PSM}/add/log`, {
    ticketId
  });

// 编辑-小票-用电人
export const getPagePersonsLicense = params => {
  params.accountStates = 20; // 20说明只查询启用的人
  return axios.get(`${API_AUTHORIZATION}/user/page/users-license`, {
    params
  });
};

/**
 * 获取承包商人员
 * @param {*} params
 */

export const getContractorsPersonByLicense = params => {
  return axios.get(`${API_PSM}/page/person/contractor-license`, { params });
};

/**
 * 关联作业查询，过滤已关联
 * @param {*} params
 */
export const getWorkTicketByFilterRelation = params => {
  return axios.get(`${API_PSM}/page/app-work/filter-relation`, { params });
};

/**
 * JSA分析列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getJsaByPage = params => {
  return axios.get(`${API_PSM}/page/jsas`, { params });
};

/**
 * JSA状态
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getJsaStatus = () => {
  return axios.get(`${API_PSM}/select/jsas/status`);
};

/**
 * 安全分析分页
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkJsaByPage = params => {
  return axios.get(`${API_PSM}/page/work/jsa`, { params });
};

/**
 * 安全分析详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkJsaInfo = id => {
  return axios.get(`${API_PSM}/info/work/jsa/${id}`);
};
// 关联作业票
export function getRelJsaList(params) {
  return axios.get(`${API_PSM}/page/app-work`, { params });
}

// 作业类型查询作业配置（jsa使用)
export const getWorkTypeList = params => {
  return axios.post(`${API_PSM}/list/special/work-type`, params);
};

// 新增作业jsa
export const addWorkJsa = params => {
  return axios.post(`${API_PSM}/add/work/jsa`, params);
};

// 编辑保存作业jsa
export const updateWorkJsa = params => {
  return axios.post(`${API_PSM}/update/work/jsa/${params.id}`, params);
};

// 提交作业jsa
export const commitWorkJsa = params => {
  return axios.post(`${API_PSM}/commit/work/jsa`, params);
};

// 编辑提交作业jsa
export const updateCommitWorkJsa = params => {
  return axios.post(`${API_PSM}/commit/work/jsa/${params.id}`, params);
};

// 作业前安全分析签名
export const signatureWorkJsa = params => {
  return axios.post(`${API_PSM}/signature/work/jsa`, params);
};

/**
 * 作业前安全分析状态下拉字典
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkJsaStatusDic = () => {
  return axios.get(`${API_PSM}/dic/status/work/jsa`);
};

// jsa待签字状态审批--通过
export const signedWorkJsa = params => {
  return axios.post(`${API_PSM}/signed/work/jsa`, params);
};

// 待反馈转待完成
export const feedBackWorkJsa = params => {
  return axios.post(`${API_PSM}/feed-back/work/jsa`, params);
};

/**
 * 作业票从待提交重新提交
 * @param ticketId
 * @returns {*}
 */
export const updateWorkTicketBaseInfo = params =>
  axios.post(API_PSM + `/update/work_tickets/commit/${params.id}`, params);

/**
 * 特殊要求下拉字典
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSpecialRequirement = () => {
  return axios.get(`${API_PSM}/list/special-requirement`);
};

/**
 * 高处作业等级下拉字典
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getElevationLevel = () => {
  return axios.get(`${API_PSM}/list/elevation_level`);
};

/**
 * 高处作业等级名称下拉字典
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getElevationRange = () => {
  return axios.get(`${API_PSM}/list/work/elevation/range`);
};

/**
 * 吊装等级下拉字典
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHoistingLevel = () => {
  return axios.get(`${API_PSM}/list/hoisting_level`);
};

/**
 * 列表查询个人防护用品
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProtectiveEquipment = params => {
  return axios.get(`${API_PSM}/list/protective-equipment`, { params });
};

/**
 * 个人防护用品--添加
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addProtectiveEquipment = params => {
  return axios.post(`${API_PSM}/protective-equipment`, params);
};

/**
 * 个人防护用品--删除
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProtectiveEquipment = id => {
  return axios.delete(`${API_PSM}/protective-equipment/${id}`);
};

/**
 * 根据workJsaId查询关联的作业票
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRelationListByJsaId = params => {
  return axios.get(`${API_PSM}/list/relation/ticket`, { params });
};

/**
 * 批量建票
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const batchAddWorkticket = params => {
  return axios.post(`${API_PSM}/add/work_tickets/batch`, params);
};

/**
 * 延期申请
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const delayWorkticket = (id, params) => {
  return axios.post(`${API_PSM}/update/work_tickets/delay/${id}`, params);
};

/**
 * 偏离
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deviateWorkticket = (id, params) => {
  return axios.post(`${API_PSM}/update/app-work/deviate/${id}`, params);
};

/**
 * 取消
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const cancelWorkticket = (id, params) => {
  return axios.post(`${API_PSM}/update/app-work/cancel/${id}`, params);
};

/**
 * 校验作业是否允许关闭
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkWorkticket = id => {
  return axios.post(`${API_PSM}/check/work/close/status/${id}`);
};

/**
 * 查询吊装方式下拉列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHoistingMethodSltData = () => {
  return axios.get(`${API_PSM}/select/hoisting/method`);
};

/**
 * 安全清单保存
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveSafetyRegulation = params => {
  return axios.post(`${API_PSM}/save/safety/regulation`, params);
};

/**
 * 查询安全清单
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSafetyRegulation = params => {
  return axios.get(`${API_PSM}/select/safety/regulation`, { params });
};

/**
 * 保存安全措施的图片附件
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveSafetyMeasureFile = params => {
  return axios.post(`${API_PSM}/save/safety/measure/file`, params);
};

/**
 * 未进入的作业人员列表/空间票的特种和普通作业人
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUsableWorkers = params => {
  return axios.get(`${API_PSM}/select/usable/workers`, { params });
};

/**
 * 已进入的作业人员列表/空间票的特种和普通作业人
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEnterWorkers = params => {
  return axios.get(`${API_PSM}/select/enter/workers`, { params });
};

/**
 * 添加人员或工器具记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addSpaceWorkRecord = params => {
  return axios.post(`${API_PSM}/add/space/work/record`, params);
};

/**
 * 高处作业 监护人继续作业 签名
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const continueHighWork = (ticketId, params) => {
  return axios.post(
    `${API_PSM}/update/app-work/high-work/continue-work/${ticketId}`,
    params
  );
};

/**
 * 工器具列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWorkToolByPage = params => {
  return axios.get(`${API_PSM}/page/work/tool/list`, { params });
};

/**
 * 已进入的工具列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEnterWorkTool = params => {
  return axios.get(`${API_PSM}/select/enter/tools`, { params });
};

/**
 * 全部进出记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSpaceWorkRecord = params => {
  return axios.get(`${API_PSM}/select/space/work/record`, { params });
};

/**
 * 验证作业人员和工具是否全部退出
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSpaceWorkRecordExit = params => {
  return axios.get(`${API_PSM}/validate/is-all/exit`, { params });
};
/**
 * 查询作业票详情页--安全帽列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWarningRecordVideoPage = params => {
  return axios.get(`${API_PSM}/page/ticket/video/warning/record`, { params });
};
/**
 * 查询作业票详情页--可燃物、工艺报警列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWarningRecordTicketPage = params => {
  return axios.get(`${API_PSM}/page/ticket/warning/record`, { params });
};
/**
 * 查询作业票详情页--可燃物、工艺报警列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWarningRecordTicketInfo = params => {
  return axios.get(`${API_PSM}/select/ticket/warning/info`, { params });
};

export function getPageCamera(params) {
  return axios.get(`${camera}/page/videos`, { params });
}

export function getPageMobileCamera(params) {
  return axios.get(`${camera}/page/mobile-video`, { params });
}

export function getInfoMobileCameraById(id) {
  return axios.get(`${camera}/detail/mobile-video/${id}`);
}

export function getInfoCameraById(id) {
  return axios.get(`${camera}/detail/videos/${id}`);
}

/**
 * 获取作业评价结果
 * @param ticketId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTicketEvaluate(ticketId) {
  return axios.get(`${API_PSM}/select/ticket-evaluate/score/result`, {
    params: { ticketId }
  });
}

/**
 * 保存作业评价结果
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveTicketEvaluate(params) {
  return axios.post(`${API_PSM}/save/ticket-evaluate/score/result`, params);
}

/**
 * 获取作业类别的强制措施内容
 * @param workTypeId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCoerciveMeasures(params) {
  return axios.get(`${API_PSM}/select/work-type-force-measure-content/list`, {
    params
  });
}

/**
 * 获取作业地点类型下拉列表
 * @param null
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getWorkSiteTypes() {
  return axios.get(`${API_PSM}/select/work/site/type`);
}

// 查询动火方式
export function getFireWay(params) {
  return axios.get(`${API_PSM}/list/fire_way`, { params });
}

// 查询受限空间
export function getSpaceType(params) {
  return axios.get(`${API_PSM}/list/space-type`, { params });
}

// 添加动火方式
export function addFireWay(params) {
  return axios.post(`${API_PSM}/add/fire-way`, params);
}

// 添加受限空间
export function addSpaceType(params) {
  return axios.post(`${API_PSM}/add/space-type`, params);
}

// 删除 动火方式/受限空间
export function dictionaryDelete(id) {
  return axios.post(`${API_PSM}/delete/dic/${id}`);
}

// 查询当前作业审批流节点
export function getCurrentWorkNode(specialId, params) {
  return axios.get(`${API_PSM}/current/work-node-instance/${specialId}`, {
    params
  });
}

// 查询作业审批流节点
export function getWorkNodes(specialId) {
  return axios.get(`${API_PSM}/list/work-node-instance/${specialId}`);
}

// 作业审批-同一账号会签
export function countersignApprove(params) {
  return axios.post(`${API_PSM}/update/app-work/approve/countersign`, params);
}

// 查询安全交底清单列表
export function getSafetyList(params) {
  return axios.get(`${API_PSM}/list/safety-disclose`, { params });
}

// 获取高处作业的暂停时间
export function getPauseTime(params) {
  return axios.get(`${API_PSM}/select/high-work/pause-time`, { params });
}

// 根据作业票查询作业单位
export function getConfirmationCardDept(params) {
  return axios.get(`${API_PSM}/select/ticket/dept`, { params });
}

// 查询作业应急措施库表（列表）
export const getEmergencyMeasures = params => {
  return axios.get(`${API_PSM}/list/work-emergency-measure-libraries`, {
    params
  });
};

// 根据作业类别获取安全培训库内容
export const getSafetyTraining = params => {
  return axios.get(`${API_PSM}/list/work-safety-train-content`, { params });
};

// 新增作业确认卡相关内容表
export const addConfirmationCard = params => {
  return axios.post(`${API_PSM}/add/work-confirm-cards`, params);
};

// 查询气体检测-位置信息 字典项
export const getGasMonitorPlace = params => {
  return axios.get(`${API_PSM}/list/gas/monitor/place`, { params });
};

// 判断设备设施是否关联重大危险源
export function judgeDangerfacilities(params) {
  return axios.get(`${basics}/judge/danger-facilities`, { params });
}

// 根据ID查询特殊作业类型
export function getWorkTypeInfo(params) {
  return axios.get(`${API_PSM}/info/work_type_record`, { params });
}

// 作业活动-审批通过
export function jsaApprovePass(id) {
  return axios.post(`${API_PSM}/pass/jsa/approve/${id}`);
}

// 作业活动-审批退回
export function jsaApproveRollback(id) {
  return axios.post(`${API_PSM}/roll-back/jsa/approve/${id}`);
}

// 作业活动-审批撤回
export function jsaApproveRecall(id) {
  return axios.post(`${API_PSM}/recall/jsa/approve/${id}`);
}

// 作业活动-查询导出模板类型
export function getWorkJsaTemplate(params) {
  return axios.get(`${API_PSM}/select/work/jsa/template`, { params });
}

// 查询作业类型字典（北元）
export const getCompanyWorkType = params => {
  return axios.get(`${API_PSM}/list/work/type/classify`, { params });
};

// 作业活动-项目类别字典
export function getProjectCategory(params) {
  return axios.get(`${API_PSM}/select/project/category`, { params });
}

// 作业活动-风险等级字典
export function getProjectRiskLevel(params) {
  return axios.get(`${API_PSM}/select/project/risk/level`, { params });
}

// 作业活动-项目来源字典
export function getProjectSource(params) {
  return axios.get(`${API_PSM}/select/project/source`, { params });
}

// 作业票-待开票撤回
export function ticketRecall(id) {
  return axios.get(`${API_PSM}/ticket/return/${id}`);
}

// 作业票-校验作业类型与时间是否匹配(若为休假时间不可添加)
export function validateWorkTypeVacation(params) {
  return axios.post(`${API_PSM}/validate/work-type-vacation`, params);
}

// 作业票-校验作业票是否涉及下班时间
export function validateWorkTime(params) {
  return axios.get(`${API_PSM}/validate/work-type-time`, { params });
}

// 作业票-获取作业升级批准人列表
export function getWorkUpgradeApproveUser(ticketId, params) {
  return axios.get(`${API_PSM}/info/work-upgrade-approve-user/${ticketId}`, {
    params
  });
}

// 作业票-保存作业升级批准人
export function updateWorkTicketUpgrader(ticketId, params) {
  return axios.post(
    `${API_PSM}/update/work-ticket-upgrader-user/${ticketId}`,
    params
  );
}

// 作业票-判断作业是否需要升级
export function validateWorkUpgradeStatus(ticketId) {
  return axios.get(`${API_PSM}/validate/work-upgrade-status/${ticketId}`);
}

// 作业票-判断当前登录人是否可以进行升级审批
export function getWorkUpgradeApproveStatus(ticketId) {
  return axios.get(
    `${API_PSM}/validate/work-upgrade-approve-status/${ticketId}`
  );
}

// 作业票-升级审批
export function updateWorkUpgradeApprove(ticketId, params) {
  return axios.post(
    `${API_PSM}/update/work-upgrade-approve/${ticketId}`,
    params
  );
}

// 作业票-开始工艺或检修安全分析(设备检修作业)
export function startCraftOverhaulAnalyse(id) {
  return axios.post(
    `${API_PSM}/update/app-work/start-craft-overhaul-analyse/${id}`
  );
}

// 作业票-完成工艺或检修安全分析(设备检修作业)
export function finishCraftOverhaulAnalyse(id) {
  return axios.post(
    `${API_PSM}/update/app-work/finish-craft-overhaul-analysis/${id}`
  );
}

// 作业票-完成工艺或检修安全交底(设备检修作业)
export const finishCraftOverhaulDisclosure = (ticketId, files) =>
  axios.post(
    `${API_PSM}/update/app-work/finish-craft-overhaul-disclosure/${ticketId}`,
    {
      files: [...files]
    }
  );

// 作业票-危害辨识字典(设备检修作业)
export function getWorkHazard_equipmentSafety(params) {
  return axios.get(`${API_PSM}/list/work-hazard`, { params });
}

// 详情-修改设备检修安全作业
export function editEquipmentSafety(id, params) {
  return api.post(`/update/special_works/equipment-safety/${id}`, params);
}
