export default [
  {
    path: "/announcementnotice",
    name: "AnnouncementList",
    meta: { cache: true, componentName: "AnnouncementList" },
    component: () =>
      import(
        /* webpackChunkName: "AnnouncementList" */ "@/views/Announcement/AnnouncementList"
      )
  },
  {
    path: "/announcementnotice/:id(\\d+)",
    name: "AnnouncementDetails",
    props: true,
    meta: { cache: true, componentName: "AnnouncementDetails" },
    component: () =>
      import(
        /* webpackChunkName: "AnnouncementDetails" */ "@/views/Announcement/AnnouncementDetails"
      )
  }
];
