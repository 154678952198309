import { getEnterpriseTag } from "@/utils/env";

const isZm = getEnterpriseTag() === "ZM";

const planList = () => {
  if (isZm) {
    return import(
      "@/views/plans/enterprise-customization/zhongmei/planList.vue"
    );
  }
  return import(/* webpackChunkName: "PlanList" */ "@/views/plans/planList");
};

const planDetail = () => {
  if (isZm) {
    return import("@/views/plans/enterprise-customization/zhongmei/planDetail");
  }
  return import(/* webpackChunkName: "PlanList" */ "@/views/plans/planDetail");
};

const inspectEdit = () => {
  if (isZm) {
    return import(
      /* webpackChunkName: "InspectEdit" */
      "@/views/plans/enterprise-customization/zhongmei/inspectDetail.vue"
    );
  }
  return import(
    /* webpackChunkName: "InspectEdit" */ "@/views/plans/inspectDetail"
  );
};

export default [
  {
    path: "/plans",
    name: "Plans",
    component: () =>
      // import(/* webpackChunkName: "layout" */ "@/components/Layout.vue"),
      import(/* webpackChunkName: "PlansIndex" */ "@/views/plans/index"),
    redirect: "/plans/list",
    meta: { cache: true, roles: ["plans"], componentName: "Plans" },
    children: [
      {
        path: "list",
        name: "PlanList",
        meta: { roles: ["plans"], cache: true, componentName: "PlanList" },
        component: planList
      },
      {
        path: ":id(\\d+)",
        name: "PlanDetail",
        props: true,
        meta: { roles: ["plans"], cache: true, componentName: "PlanDetail" },
        component: planDetail
      },
      {
        path: ":planId(\\d+)/inspect/add",
        name: "InspectAdd",
        meta: { roles: ["plans"], cache: true, componentName: "InspectAdd" },
        props: true,
        component: inspectEdit
      },
      {
        path: ":planId(\\d+)/inspect/:id(\\d+)",
        name: "InspectEdit",
        props: true,
        meta: { roles: ["plans"], cache: true, componentName: "InspectEdit" },
        component: inspectEdit
      },
      // 选择隐患
      {
        path: ":planId(\\d+)/inspect/select-trouble",
        name: "SelectTrouble",
        props: true,
        meta: { roles: ["plans"], cache: true, componentName: "SelectTrouble" },
        component: () =>
          import(
            /* webpackChunkName: "SelectTrouble" */ "@/views/plans/selectTrouble"
          )
      },
      {
        path: ":planId(\\d+)/inspect/create-trouble",
        name: "CreateTrouble",
        props: true,
        meta: { roles: ["plans"], cache: true, componentName: "CreateTrouble" },
        component: () =>
          import(
            /* webpackChunkName: "SelectTrouble" */ "@/views/plans/createTrouble"
          )
      }
    ]
  }
];
