import { getListMonitoringModuleOptionItems } from "@/api/globalPlatformConfig";

export default {
  state: {
    globalModuleFeatureConfigs: []
  },
  mutations: {
    setGlobalModuleFeatureConfigs(state, data) {
      state.globalModuleFeatureConfigs = data;
    }
  },
  actions: {
    getGlobalModuleFeatureConfigs({ commit }) {
      return getListMonitoringModuleOptionItems().then(data => {
        commit("setGlobalModuleFeatureConfigs", data);
      });
    }
  }
};
