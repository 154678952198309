export default {
  state: {
    punchCardSuccess: false
  },
  mutations: {
    setPunchCardSuccess(state, val) {
      state.punchCardSuccess = val;
    }
  }
};
