export default [
  {
    path: "/indicator",
    name: "IndicatorList",
    component: () => import("../../views/indicator/indicator"),
    meta: { cache: true, componentName: "IndicatorList", roles: ["sensor"] }
  },
  {
    path: "/indicator/:id",
    name: "IndicatorDetail",
    component: () => import("../../views/indicator/indicatorDetail"),
    meta: { cache: true, componentName: "IndicatorDetail", roles: ["sensor"] }
  }
];
