<template>
  <div class="has-nav-bar view-user-password-reset">
    <van-nav-bar
      :title="isActivatedAccount ? '设置密码' : '重置密码'"
      fixed
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form ref="form" class="reset-pwd-form" @submit="onSubmit">
      <van-field
        v-model="form.newPassword"
        name="newPassword"
        type="password"
        class="pwd-first"
        placeholder="至少6位英文、数字组合,区分大小写"
        @blur="checkNewPassword"
      />
      <pwd-strong class="pwd-strong" :pwd="form.newPassword" />
      <van-field
        v-model="form.confirmPassword"
        name="confirmPassword"
        :placeholder="'请再次输入' + newText + '密码'"
        type="password"
        @blur="checkConfirmPassword"
      />
      <div class="support-mobile">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :disabled="loading.status"
          :loading="loading.status"
        >
          确认
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Toast, NavBar, Cell, Button, Icon } from "vant";
import authApi from "../../api/authorization";
import { mapState } from "vuex";
import loginUtil from "../../utils/login";
import PwdStrong from "@/components/PwdStrong";

export default {
  name: "UserPasswordEdit",
  isActivatedAccount: false, // 是否是激活账号
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Field.name]: Field,
    [Toast.name]: Toast,
    PwdStrong
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    newText() {
      return this.isActivatedAccount ? "" : "新";
    }
  },
  data() {
    return {
      form: {
        tenantId: ""
      },
      loading: {
        status: false
      }
    };
  },
  created() {
    this.isActivatedAccount = this.$route.query.isactive;
    this.form.mobile = sessionStorage.getItem("mobile");
    this.form.verificationCode = sessionStorage.getItem("verificationCode");
    this.form.tenantId = this.$route.query.tenantId;
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    checkNewPassword() {
      if (!this.form.newPassword) {
        Toast("请输入密码");
        return false;
      } else if (!/^[0-9A-Za-z]{6,}$/.test(this.form.newPassword)) {
        Toast("密码格式错误");
        return false;
      }
      return true;
    },
    checkConfirmPassword() {
      if (!this.form.confirmPassword) {
        Toast("请再次输入密码");
        return false;
      } else if (this.form.confirmPassword !== this.form.newPassword) {
        Toast("两次输入的密码不一致");
        return false;
      }
      return true;
    },
    onSubmit(values) {
      console.log("submit", this.form, values);
      if (this.checkNewPassword() && this.checkConfirmPassword()) {
        this.loading.status = true;

        authApi
          .updateAuthorizationUpdateUsersPasswordMobile(this.form)
          .then(res => {
            if (this.isActivatedAccount) {
              Toast(`密码设置成功！重新登录`);
            } else {
              Toast(`密码重置成功！重新登录`);
            }
            loginUtil.removeToken();
            setTimeout(() => {
              if (window.APP.getPlatform().isAndroid) {
                window.android.logout();
              } else {
                location.href = "/";
              }
            }, 2000);
            sessionStorage.removeItem("mobile");
            sessionStorage.removeItem("verificationCode");
          })
          .catch(err => {
            console.log("onSubmit -> err", err);
          })
          .finally(() => {
            this.loading.status = false;
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.view-user-password-reset {
  .van-form {
    overflow: auto;
    padding: 20px;
    position: relative;
  }
  .pwd-first {
    margin-bottom: 20px;
  }
  .pwd-strong {
    position: absolute;
    top: 70px;
    left: 34px;
  }
  .support-mobile {
    margin: 6vh 0;
    .van-button {
      width: 100%;
      height: 46px;
      background: rgba(24, 144, 255, 1);
      box-shadow: 0px 5px 16px 0px rgba(24, 144, 255, 0.3);
      border-radius: 8px;
    }
  }
}
</style>
