import axios from "@/utils/axios";
import { objectKeysNumber2String } from "@/utils";
import apiPath from "../utils/apiPath";
const API_PSM = apiPath.psm;
const API_AUTHORIZATION = apiPath.authorization;
const PROMISE_API = API_PSM + "/promise";
const API_DICTIONARY = apiPath.dictionary;

/**
 * 获取危险有害因素
 */
export const getSelectRiskFactorTypes = () =>
  axios.get(API_PSM + "/select/jsa_analyses/hazard_factor_type");

/**
 * 获取作业类型
 */
export const getSelectWorkTypes = orgCode =>
  axios.get(API_PSM + "/list/work_type", { params: { orgCode } });

/**
 * 新增 控制风险基本信息
 * @param {*} data
 */
export const createControlRiskInfo = data =>
  axios.post(API_PSM + "/add/app/risk/controls", data);

/**
 * 根据区域ID获取设备设施类型
 * @param {*} riskAreaId
 */
export const getDeviceTypesByRiskAreaId = riskAreaId => {
  return axios
    .get(API_PSM + "/select/risk/areas/poi", { params: { riskAreaId } })
    .then(res => {
      return res.map(item => {
        return { label: item.poiName, value: item.poiId, origin: item };
      });
    });
};
// select/risk/areas/poi

/**
 * 获取风险等级
 */
export const getPromiseRiskGradeTypes = () =>
  axios.get(PROMISE_API + "/risk/grade");

/**
 * 分页查询承诺列表
 * @param {*} params
 */
export const getPagePromiseList = params =>
  axios.get(PROMISE_API + "/page/promise/info", { params: { ...params } });

/**
 * 查询承诺信息
 * @param {*} id
 */
export const getPromiseInfoById = id => {
  return axios.get(PROMISE_API + "/info/promise/info/" + id).then(res => {
    res = objectKeysNumber2String(res);
    return {
      ...res,
      ...res.equipmentSafeVO,
      ...res.equipmentSafeRunVO,
      ...res.workSafeVO,
      ...res.poiSafeRunVO,
      ...res.othersVO
    };
  });
};

/**
 * 查询关联责任部门数据
 * @param {*} id
 * @param templateId
 */
export const getRelatePromiseDeptList = id => {
  return axios
    .get(PROMISE_API + "/list/all/promise", {
      params: { id }
    })
    .then(res => {
      res.own = objectKeysNumber2String(res.own);
      const { own } = res;
      // 给默认值
      if (own) {
        res.own = {
          ...own,
          ...own.equipmentSafeVO,
          ...own.equipmentSafeRunVO,
          ...own.workSafeVO,
          ...own.poiSafeRunVO,
          ...own.othersVO
        };
      }
      return res;
    });
};

/**
 * 查询该条承诺信息是否承诺过
 * @param {*} id
 */
export const isPromiseDataPromised = id =>
  axios.get(PROMISE_API + "/check/sub/promised/" + id);

/**
 * 查询研判与承诺日历状态
 * @param {*} params
 * @returns
 */
export const getCalendarStatusList = params => {
  return axios.get(`${API_PSM}/promise/select/promise/complete/state`, {
    params
  });
};

/**
 * 更新承诺信息
 * @param {*} id
 */
export const updatePromiseInfoById = (id, data) =>
  axios.post(PROMISE_API + "/update/promise/info/" + id, data);

/**
 * 查询建议承诺信息
 * @param {*} id
 */
export const getPromiseSuggestById = id =>
  axios.get(PROMISE_API + "/query/suggest/value/" + id);

/**
 * 待办过来的信息，决定路由跳转
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPromiseBacklogById = id =>
  axios.get(PROMISE_API + "/info/task-status/" + id);

/*
 * 巡检管理 获取列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInspectionList = params => {
  return axios.get(API_PSM + "/page/inspection-info", { params });
};

/**
 * 巡检管理 获取详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInspectionInfo = id => {
  return axios.get(API_PSM + `/info/inspection-info/${id}`);
};

/**
 * 巡检管理 巡检内容列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInspectionEquipmentList = params => {
  return axios.get(API_PSM + `/page/inspection-equipment`, { params });
};

/**
 * 巡检管理 设备状态列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInspectionEquipmentSelect = () => {
  return axios.get(API_PSM + `/select/inspection-equipment-status`);
};

/**
 * 巡检管理 添加内容
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const addInspectionEquipment = data => {
  return axios.post(API_PSM + `/add/inspection-equipment`, data);
};

/**
 * 巡检管理 获取设备详情
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInspectionEquipment = id => {
  return axios.get(API_PSM + `/info/inspection-equipment/${id}`);
};

/**
 * 巡检管理 更新设备信息
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateInspectionEquipment = data => {
  return axios.post(API_PSM + `/update/inspection-equipment/${data.id}`, data);
};

/**
 * 巡检管理 删除设备信息
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const deleteInspectionEquipment = id => {
  return axios.post(API_PSM + `/delete/inspection-equipment/${id}`);
};

/**
 * 查询人员列表, 校验权限的, persons:不需要权限,person:需要权限
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPagePerson = (params, isNotAuth) => {
  params.accountStates = 20;
  let url = "/user/page/user"; // 校验权限
  if (isNotAuth) {
    url = "/user/page/users"; // 不校验权限
  }
  return axios.get(API_AUTHORIZATION + url, { params }).then(res => {
    // 拓展 label，value，便于组件使用
    res.list.map(x => {
      x.label = x.name;
      x.value = x.id;
      x.checked = false;
    });
    return res;
  });
};

/**
 * 动火方式
 * @returns {*}
 */
export const getFireWay = () => {
  return axios.get(API_PSM + `/list/fire_way`);
};

/**
 * 人员资质树形数据
 * @returns {*}
 */
export const getLicenseDict = params => {
  return axios.get(API_PSM + `/select/license-dict`, { params });
};

// 新增作业许可
export function addWorkTickets(params) {
  return axios.post(API_PSM + "/add/work_tickets", params);
}

/**
 * HSE扫码
 * @returns {*}
 */
export const hseScan = params => {
  return axios.get(API_PSM + `/scan/hse/user/point`, { params });
};

// 查询公告
export const getPageNotices = params => {
  return axios.get(API_PSM + `/page/notices`, { params });
};

export const getInfoNotices = id => {
  return axios.get(`${API_PSM}/info/notices/${id}`);
};

export const getNoticesBtnStatus = data => {
  return axios.post(API_PSM + `/check/notices_status`, data);
};

export const completeNotices = data => {
  return axios.post(API_PSM + `/update/notices_person`, data);
};

// 车辆类型下拉
export function getSelectVehicleTypes() {
  return axios.get(API_PSM + "/select/cars/vehicle/type");
}

// 车型下拉
export function getSelectCarsVehicleModel() {
  return axios.get(API_PSM + "/select/cars/vehicle/model");
}

export function getPageVehicle(params) {
  return axios.get(API_PSM + "/page/cars", { params });
}

export function getInfoVehicle(id) {
  return axios.get(API_PSM + `/info/cars/${id}`);
}
export function getGeoListByPoiId(id) {
  return axios.get(API_PSM + `/select/risk/area/geo?poiId=${id}`);
}

export function workIsInFenceByPoiInfo(
  riskAreaId,
  personPositionType,
  workTypeId
) {
  return axios.get(API_PSM + `/ticket/verify/location`, {
    params: { riskAreaId, personPositionType, workTypeId }
  });
}

export function getGeoInfoById(id) {
  return axios.get(`${API_PSM}/info/risk/areas/${id}`);
}

// 安全分析-管控层级下拉
export const getJsaAnalysesGovernanceLevel = () =>
  axios.get(`${API_DICTIONARY}/select/jsa/governance/level`);

// 安全分析-动作类别下拉
export const getSelectStepsClassification = () =>
  axios.get(`${API_PSM}/select/jsa_steps/classification`);

// 获取法律法规类型
export const getLawsType = () => {
  return axios.get(`${API_PSM}/select/laws/type`);
};

// 获取法律法规list
export const getLawsPage = params => {
  return axios.get(`${API_PSM}/page/laws`, { params });
};

// 获取法律法规info
export const getLawsInfo = id => {
  return axios.get(`${API_PSM}/info/laws/${id}`);
};

// 获取政策文件主题分类
export const getPolicyType = () => {
  return axios.get(`${API_PSM}/select/policy/theme`);
};

// 获取政策文件list
export const getPolicyPage = params => {
  return axios.get(`${API_PSM}/page/policy`, { params });
};

// 获取政策文件info
export const getPolicyInfo = id => {
  return axios.get(`${API_PSM}/info/policy/${id}`);
};

// 获取标准规范list
export const getPolicyStandardPage = params => {
  return axios.get(`${API_PSM}/page/norms`, { params });
};

// 获取标准规范info
export const getPolicyStandardInfo = id => {
  return axios.get(`${API_PSM}/info/norms/${id}`);
};

// 获取规章制度list
export const getRegulationsPage = params => {
  return axios.get(`${API_PSM}/page/regulations`, { params });
};

// 获取规章制度info
export const getRegulationsInfo = id => {
  return axios.get(`${API_PSM}/info/regulations/${id}`);
};

// 获取自定义模块菜单
export const getDefinedMenus = () => {
  return axios.get(`${API_PSM}/list/defined/menus`);
};

// 获取自定义菜单下的某个菜单的数据
export const getSafetyRecordPage = params => {
  return axios.get(`${API_PSM}/page/safety-records`, { params });
};

// 查询作业通用字典（列表）
export function getWorkDictCommons(params) {
  return axios.get(`${API_PSM}/list/work-dict-commons`, { params });
}

// 查询作业通用字典数据选择（列表）
export function getWorkDictOptions(params) {
  return axios.get(`${API_PSM}/list/work-dict-selects`, { params });
}

// 删除作业通用字典数据选择
export function deleteWorkDict(id) {
  return axios.post(`${API_PSM}/delete/work-dict-selects/${id}`);
}
