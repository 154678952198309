import axios from "@/utils/axios";

export default {
  getTaskType(params) {
    return axios.get("/accident/statistics/mobile/task/type", { params });
  },
  getTaskList(params) {
    return axios.get("/accident/list/mobile/task", { params });
  },
  //更新任务状态
  updateTaskTrace(params) {
    return axios.post("/accident/update/task/trace", params);
  },
  //更新已读状态
  updateTask(params) {
    return axios.post("/accident/update/task", params);
  },
  //事故类型字典
  getShelterTypes(params) {
    return axios.get(`/accident/dict/types`, { params });
  },
  getuserList(params) {
    return axios.post(`/basics/list/emergency-user`, params);
  },
  //获取预案详情
  getPlanDetail(id) {
    return axios.get(`/plan/plan/detail/${id}`);
  },
  queryUserName(id) {
    return axios.get(`/authorization/info/users/${id}`);
  }
};
