<template>
  <div class="view-user-todo-list-item" @click="clickHandle">
    <dl>
      <dt>
        <span v-if="data.messageType === 1" class="urge-tag">催办</span
        ><span style="vertical-align: middle;">{{ data.title }}</span>
      </dt>
      <dd>
        <i>{{ data.createTime }}</i>
      </dd>
    </dl>
  </div>
</template>

<script>
import { Icon, Toast } from "vant";
import { messageJumpWhiteListReg as whiteListReg } from "@/utils/constant";
import { checkWhiteAuth } from "@/views/user/auth";

export default {
  name: "UserTodoListItem",
  components: {
    [Icon.name]: Icon
  },
  props: {
    data: Object,
    activeName: String
  },
  data() {
    return {
      appName: window?.globalConfig?.VUE_APP_NAME
    };
  },
  methods: {
    clickHandle() {
      let { uri } = this.data;
      const isAllowJump = whiteListReg.test(uri) || checkWhiteAuth(uri);
      if (uri.includes("/equipment-workorder/")) {
        if (this.appName === "RT") {
          uri = `/equipment-workorder/detail/detail/${
            uri.split("/equipment-workorder/")[1]
          }`;
        } else {
          uri = `/workOrderRxList/workOrderRxEdit/detail/${
            uri.split("/equipment-workorder/")[1]
          }`;
        }
      } else if (uri.includes("/equipment-repair-list/")) {
        const uriArr = uri.split("?woid=");
        const workorderId = uriArr[1];
        const id = uriArr[0].split("/equipment-repair-list/")[1];
        uri = `/equipment-workorder/maintenancerecords/${workorderId}/${id}`;
      } else if (uri.includes("/contractor-evaluate-record/detail")) {
        const urlParams = new URLSearchParams(uri.split("?")[1]);
        uri = `/contractor-evaluate/${urlParams.get(
          "id"
        )}?taskId=${urlParams.get("taskId")}`;
      } else if (uri.includes("/upkeep-record")) {
        const id = uri.split("/")[2];
        if (this.activeName === "todo" || !this.activeName) {
          uri = `/serveDoing/data/${id}`;
        } else if (this.activeName === "done") {
          uri = `/serveDone/data/${id}`;
        }
      }
      if (!isAllowJump) {
        Toast({
          message: "该功能模块业务复杂，\n请在电脑上处理！",
          position: "top"
        });
      }
      isAllowJump && this.$router.push({ path: uri, query: { from: "todo" } });
    }
  }
};
</script>
<style lang="scss">
.view-user-todo-list-item {
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  // height: 84px;
  > i {
    width: 18px;
    font-size: 16px;
    color: $--color-primary;
  }
  > dl {
    flex: 1;
    width: 100%;
    dt {
      color: #3b4664;
      display: block;
      height: 48px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    dd {
      font-size: 14px;
      color: #aeb3c0;
      display: flex;
      justify-content: flex-end;
      padding: 0 7px;
    }
  }

  .urge-tag {
    color: #f3961c;
    background: #ffeed8;
    border: 1px solid #f3961c;
    padding: 2px 3px;
    border-radius: 2px;
    font-size: 12px;
    vertical-align: middle;
  }
}
</style>
