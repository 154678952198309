import axios from "axios";
import { Dialog, Toast } from "vant";
import loginUtil from "./login";
import { v4 as uuidV4 } from "uuid";
import { heartbeat, ToastNetworkError } from "@/utils/networkCheck";
import { encodeStrDefault, getUploadFileXd } from "./index";
// 会使用dialog弹窗的code
const DialogCode = [{ code: "30006" }, { code: "10001" }];
// 不适用任何提示的白名单
const notTipsWhiteList = ["patrol/gis/add"];

// loading 提示
const loading = {};
let timer = null;
// loading 接口不 loading 白名单
const notLoadingWhiteList = ["file/upload", "patrol/gis/add", "count/notices"];

const baseUrl = (axios.defaults.baseURL =
  window.globalConfig.VUE_APP_API_PATH || process.env.VUE_APP_API_PATH);

const setHeaderToken = () => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${loginUtil.getToken()}`;
};

function startLoading(ident) {
  if (!ident) return;
  const options = { message: "加载中...", forbidClick: true, duration: 0 };
  loading[ident] = Toast.loading(options);
}

function closeLoading(ident) {
  loading[ident]?.clear();
  delete loading[ident];
}

axios.interceptors.request.use(
  config => {
    const token = loginUtil.getToken();
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }

    const isPost = config.method.toUpperCase() === "POST";
    // 如果是上传文件, 需要对请求添加xd, 防止篡改
    if (isPost && config.url.startsWith(window.globalConfig.VUE_APP_FILE_URL)) {
      // 处理防止篡改
      const formData = config.data;
      let xd = encodeStrDefault();
      if (formData instanceof FormData) {
        const files = formData.get("files");
        if (files) {
          xd = getUploadFileXd(files);
        }
      }
      config.url = config.url + "?xd=" + xd;
    }
    config.__uuid__ = uuidV4();
    const { url, method, __uuid__ } = config;

    // 默认企业普通用户的所有 get 请求，添加 orgCode 参数
    let userInfo = loginUtil.getUserInfo();
    let isGet = method.toUpperCase() === "GET";
    let isOrdinaryUser = userInfo.isEnterprise && userInfo.userType === 0;
    let hasConfigUrlOrgCode = config.url.includes("orgCode=");
    if (isGet && isOrdinaryUser && !hasConfigUrlOrgCode) {
      config.params = config.params || {};
      config.params.orgCode = config.params?.orgCode || userInfo.orgCode;
    }

    const has = u => url.includes(u);
    const isLoading = notLoadingWhiteList.some(has);
    if (!isLoading) {
      if (isGet) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          startLoading(__uuid__);
        }, 300);
      } else {
        startLoading(__uuid__);
      }
    }
    // 处理5秒延时事项
    config._heartbeatTimer = setTimeout(() => {
      clearTimeout(config._heartbeatTimer);
      heartbeat(config);
    }, 5000);
    return config;
  },
  error => {
    clearTimeout(timer);
    closeLoading(error.config?.__uuid__);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    const { url, __uuid__ } = response.config;
    closeLoading(__uuid__);
    clearTimeout(timer);
    clearTimeout(response.config._heartbeatTimer); // 销毁心跳检测延时器
    if ("" + response.data.code === "20026") {
      const upgradeUrl = `${baseUrl}/upgrade?token=${loginUtil.getToken()}`;
      return void location.replace(upgradeUrl);
    }
    if (["20027", "20028"].includes(response.data.code)) {
      const expireUrl = `${baseUrl}/expire.html?code=${response.data.code}`;
      return void location.replace(expireUrl);
    }
    if ("" + response.data.code === "0") {
      // token
      const token = response.headers["x-token"];
      const expireTime =
        response.headers["x-token-expire"] || `${Date.now() + 2 * 3600 * 1000}`;
      if (token && expireTime) {
        loginUtil.setToken({ token, expireTime });
      }
      return response.data.data;
    } else if (
      "" + response.data.code === "20004" ||
      "" + response.data.code === "20005" ||
      "" + response.data.code === "20006"
    ) {
      const msg = `登录已过期，请重新登录！（${response.data.code}）`;
      window.sentry?.captureException(response.config);

      //token失效的情况
      loginUtil.removeToken();
      if (window.APP.getPlatform().isAndroid) {
        Dialog.confirm({
          message: msg
        }).then(() => {
          window.android.logout();
        });
      } else {
        location.reload();
      }
    } else {
      let isJson, msg;
      try {
        msg = JSON.parse(response.data.msg);
        isJson = true;
      } catch (e) {
        msg = response.data.msg;
        isJson = false;
      }
      const message = isJson ? msg[Object.keys(msg)[0]] : msg;
      const dialogObj = DialogCode.find(
        a => a.code === response.data.code + ""
      );
      const hideTips = notTipsWhiteList.some(whiteUrl =>
        url.includes(whiteUrl)
      );
      if (!hideTips) {
        if (dialogObj) {
          Dialog.alert({
            title: "",
            message,
            confirmButtonText: "知道了"
          }).then(() => {});
        } else {
          Toast(message);
        }
      }

      // 仅监听10001系统异常，暂时忽略其他自定义错误
      if (response.data.code === "10001") {
        window.sentry?.captureException(response.config);
      }

      return Promise.reject(response.data);
    }
  },
  function(error) {
    console.log("error", error);
    closeLoading(error.config.__uuid__);
    clearTimeout(timer);
    clearTimeout(error.config?._heartbeatTimer); // 销毁心跳检测延时器
    // Do something with response error
    if (!error.response) {
      ToastNetworkError();
      return Promise.reject(error);
    }
    let msg;
    switch (error.response.status) {
      case 401:
        loginUtil.removeToken();
        // location.reload();
        msg = "未授权";
        break;
      case 403:
        msg = "请求被屏蔽";
        break;
      case 404:
        msg = "请求未找到";
        break;
      case 405:
        msg = "请求不被允许";
        break;
      case 500:
        msg = "服务器错误";
        break;
      default:
        msg = "请求出错";
        break;
    }
    Toast(msg + `(${error.response.status})`);
    window.sentry?.captureException(error);
    return Promise.reject(error.response);
  }
);
export default axios;
export { setHeaderToken };
