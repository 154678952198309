import projectTextConfig from "@/utils/projectTextConfig";
export const appName = globalConfig?.VUE_APP_SERVICE_PROJECT?.toUpperCase();
//判断当前是否为瑞泰项目，若是，则展示菜单，使用symbol为了防止后期与后台配置的路由重复
// const special = process.env?.VUE_APP_NAME === "RT";

// 一天的毫秒数
export const ONE_DAY_MS = 86400000;

/**
 * 提交按钮 loading 状态控制
 */
export const loading = {
  status: false,
  text: "提交中..."
};

export const emojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;

/**
 * 消息跳转白名单正则
 */
export const messageJumpWhiteListReg = /(^\/trouble.+|^\/improve.+|^\/videoalarm.+|^\/workbeforesafetyanalyze.+|^\/alarm.+|^\/workticket.+|^\/inspect-record.+|^\/equipment-workorder.+|.*(promise).*|^\/switching.+|^\/contractor-evaluate.+|^\/onoffline-process.+|^\/onoffline.+|^\/equipment-repair-list.+|^\/upkeep-record.|^\/apply.|^\/health.|^\/point-check-task.+)/;

export const whetherTypes = [
  {
    label: "是",
    value: "1"
  },
  {
    value: "0",
    label: "否"
  }
];

// export const whetherDic = parseLabelToDic(whetherTypes)
export const whetherDic = {
  0: "否",
  1: "是"
};

export const fileUrl = `${globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=`;

export const REG_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;
export const REG_NUM = /[0-9]/gi;
export const REG_EN = /[a-zA-Z]/gi;
export const REG_UPPER_EN = /[A-Z]/g;
export const REG_LOWER_EN = /[a-z]/g;
export const REG_REPEAT = /([0-9a-zA-Z])\1{1}/g;
export const REG_REPEAT_NUM = /\d{2,}/g;
export const REG_REPEAT_NUM3 = /\d{3,}/g;
export const REG_REPEAT_EN = /[a-zA-Z]{2,}/g;
export const REG_REPEAT_EN3 = /[a-zA-Z]{3,}/g;

/**
 * 移动端与pc端映射，作用：消息跳转
 * @type {unknown[]}
 */
export const routeMapForBtn = {
  // 隐患整改	过程安全管理-隐患排查治理-隐患治理
  corrections: "trouble",
  "improve-corrections": "improve-trouble"
};

/**
 * 首页菜单
 * @type {Array<{route: string, auth?: string, name: string, icon: string, show: boolean}>}
 * name：首页菜单名称
 * icon：菜单图标
 * route：路由跳转地址（不是name）
 * auth：菜单权限，与后端返回的菜单做对应（涉及到权限一定要配置）
 * show：权重大于菜单权限，控制移动端该模块是否要展示
 */
export const homeMenus = [
  {
    name: "实时监测",
    icon: "home-indicator",
    route: "indicator",
    auth: "sensor",
    show: true
  },
  {
    name: "视频监控",
    icon: "home-video",
    route: "video",
    auth: "camera",
    show: true
  },
  {
    name: "报警记录",
    icon: "home-alarm",
    route: "alarm-records-dispatch",
    auth: ["alarm", "videoalarm"],
    show: true
  },
  {
    name: "隐患排查",
    icon: "home-trouble",
    route: "trouble-dispatch",
    auth: ["corrections"],
    show: true
  },
  {
    name: "改善管理",
    icon: "home-improve",
    route: "improve-trouble-dispatch",
    auth: "improve-corrections",
    show: true
  },
  {
    name: "作业许可",
    icon: "home-work",
    route: "workticket/home",
    auth: "workticket",
    show: true
  },
  // {
  //   name: "巡检管理",
  //   icon: "home-patrol",
  //   route: "inspection",
  //   auth: "inspection",
  //   show: false
  // },
  {
    name: "联锁管理",
    icon: "switching",
    route: "switching-dispatch",
    auth: "switching",
    show: true
  },
  {
    name: "巡检管理",
    icon: "home-patrol",
    route: "inspection-management-dispatch",
    auth: "inspect-record",
    show: true
  },
  {
    name: "设备点检",
    icon: "home-point-check",
    route: "point-check-task",
    auth: "inspect-record",
    show: true
  },
  {
    name: "统计分析",
    icon: "home-analyze",
    route: "analysis",
    auth: ["corrections", "workticket", "improve-corrections", "alarm"],
    show: true
  },
  {
    name: "通讯录",
    icon: "home-address-books",
    route: "user/address-books",
    show: true
  },
  {
    name: "研判与承诺",
    icon: "home-promise",
    route: "judgepromise/list",
    auth: "judgepromise",
    show: true
  },
  {
    name: projectTextConfig.TEXT_CONTROL_RISK_TITLE,
    icon: "home-risk-control",
    route: "riskpoint",
    auth: "riskpoint",
    show: true
  },
  // {
  //   name: "视频报警",
  //   icon: "home-video-alarm",
  //   route: "videoalarm",
  //   auth: "videoalarm",
  //   show: true
  // },
  {
    name: "工单管理",
    icon: "home-workorder",
    route: "workorder-dispatch",
    show: true,
    auth: "equipment-workorder"
  },
  {
    name: "故障管理",
    icon: "home-device-fault",
    route: "faultadmin",
    auth: "faultadmin",
    show: true
  },
  {
    name: "公告通知",
    icon: "home-notify",
    route: "announcementnotice",
    auth: "announcementnotice",
    show: true
  },
  {
    name: "承包商",
    icon: "home-contractor",
    route: "contractor",
    auth: "contractorstaff",
    show: true
  },
  {
    name: "车辆档案",
    icon: "home-vehicle",
    route: "vehicle",
    auth: "vehicle",
    show: true
  },
  {
    name: "保养登记",
    icon: "home-serve",
    route: "upkeep-record",
    auth: "upkeep-record",
    show: true
  },
  {
    name: "保养登记",
    icon: "home-serve",
    route: "deviceLubImplement",
    auth: "deviceLubImplement",
    show: true
  },
  {
    name: "事故上报",
    icon: "sgsb",
    route: "accidentReport",
    auth: "accidentReport",
    show: true
  },
  {
    name: "事故续报",
    icon: "sgxb",
    route: "accidentNextReport",
    auth: "accidentReport",
    show: true
  },
  {
    name: "救援任务",
    icon: "jyrw",
    route: "rescue",
    auth: "accidentReport",
    show: true
  },
  {
    name: "资源跟踪",
    icon: "zygz",
    route: "resource",
    auth: "accidentReport",
    show: true
  },
  {
    name: "职业健康",
    icon: "health",
    route: "occupational-health",
    auth: "occupational-health-conf",
    show: true
  },
  {
    name: "应急管理",
    icon: "yjgl",
    route: "urgent",
    auth: ["schemes"],
    show: true
  },
  {
    name: "上下线管理",
    icon: "home-two-line",
    route: "two-line",
    auth: "onoffline",
    show: true
  },
  // {
  //   name: "隐患排查",
  //   icon: "home-inspect",
  //   route: "plans",
  //   auth: "plans",
  //   show: true
  // }
  {
    name: "应急资源",
    icon: "yjzy",
    route: "resources",
    auth: ["expert", "rescue", "emergencyOrganization", "material"],
    show: true
  },
  // {
  //   name: "法律法规",
  //   icon: "home-laws",
  //   route: "laws",
  //   auth: "laws",
  //   show: true
  // },
  {
    name: "重大危险源",
    icon: "danger",
    route: "danger",
    auth: "danger",
    show: true
  },
  {
    name: "变更管理",
    icon: "home-apply",
    route: "apply",
    auth: "apply",
    show: true
  },
  {
    name: "自定义应用",
    icon: "home-define-menu",
    route: "independent-menu",
    businessAuth: "independent-menu",
    show: false
  },
  {
    name: "安全知识库",
    icon: "home-secure",
    route: "secure-repository-dispatch",
    auth: ["laws", "policydocument", "policystandard", "regulations"],
    show: true
  },
  {
    name: "异常管理",
    icon: "home-inspect-exception",
    route: "inspect-exception",
    auth: "inspect-exception",
    show: true
  }
];

export const dayjsFormatDateTemplate = "YYYY-MM-DD";
export const dayjsFormatDatetimeTemplate = "YYYY-MM-DD HH:mm:ss";
