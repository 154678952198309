export default [
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/views/resources/index.vue"),
    meta: { cache: false, componentName: "Resources" }
  },
  {
    path: "/materialsDetail/:orgCode",
    name: "MaterialsDetail",
    component: () => import("@/views/resources/materialsDetail.vue"),
    meta: { cache: false, componentName: "MaterialsDetail" }
  },
  {
    path: "/organDetail/:id",
    name: "OrganDetail",
    component: () => import("@/views/resources/OrganDetail.vue"),
    meta: { cache: false, componentName: "OrganDetail" }
  }
];
