export default [
  {
    path: "/independent-menu",
    name: "independent-menu",
    component: () => import("@/views/defineMenu/defineMenu.vue"),
    props: true
  },
  {
    path: "/independent-menu/detail/:id",
    name: "independent-menu-detail",
    component: () => import("@/views/defineMenu/defineMenuDetail.vue"),
    props: true,
    meta: { cache: true, componentName: "DefineMenuDetail" }
  },
  {
    path: "/independent-menu/detail2/:id",
    name: "independent-menu-detail2",
    component: () => import("@/views/defineMenu/defineMenuDetail2.vue"),
    props: true,
    meta: { cache: true, componentName: "DefineMenuDetail2" }
  }
];
