export default [
  {
    path: "/hseSuccess",
    name: "hseSuccess",
    component: () =>
      import(/* webpackChunkName: "hseSuccess" */ "@/views/hse/success.vue")
  },
  {
    path: "/hseError",
    name: "hseError",
    component: () =>
      import(/* webpackChunkName: "hseSuccess" */ "@/views/hse/error.vue")
  },
  {
    path: "/hseRepeat",
    name: "hseRepeat",
    component: () =>
      import(/* webpackChunkName: "hseSuccess" */ "@/views/hse/repeat.vue")
  }
];
