const Video = () => import("../../views/video/index");
const VideoDetail = () => import("../../views/video/videoDetail");

export default [
  {
    path: "/video",
    name: "VideoList",
    component: Video,
    meta: { cache: true, componentName: "VideoList", roles: ["camera"] }
  },
  {
    path: "/video/:id",
    name: "VideoDetail",
    component: VideoDetail,
    meta: { cache: true, componentName: "VideoDetail" }
  }
];
