export default [
  {
    // 对标后端的奇葩路由命名
    path: "/riskpoint",
    name: "ControlRisk",
    meta: { cache: true, componentName: "ControlRisk", roles: ["riskpoint"] },
    component: () =>
      import(
        /* webpackChunkName: "ControlRisk" */ "../../views/risk/components/riskList"
      )
  },
  {
    // 对标后端的奇葩路由命名
    path: "/risk-point",
    name: "RiskPoint",
    meta: { cache: true, componentName: "RiskPoint", roles: ["risk-point"] },
    component: () =>
      import(
        /* webpackChunkName: "RiskPoint" */ "../../views/risk/components/RiskPoint"
      )
  },
  {
    path: "/riskpoint/add",
    name: "RiskAdd",
    meta: { roles: ["riskpoint"] },
    component: () =>
      import(/* webpackChunkName: "riskadd" */ "../../views/risk/add.vue")
  },
  {
    path: "/risk-point/add",
    name: "RiskPointAdd",
    meta: { roles: ["risk-point"] },
    component: () =>
      import(
        /* webpackChunkName: "riskpointadd" */ "../../views/risk/pointAdd.vue"
      )
  },
  {
    path: "/risk-danger-list",
    name: "RiskDangerList",
    component: () =>
      import(
        /* webpackChunkName: "riskDangerList" */ "../../views/risk/components/dangerList.vue"
      )
  }
];
