import { stringify } from "qs";
// import { user } from "@ty-base";
import user from "@/utils/login";
import axios from "@/utils/axios";

export const isObject = o =>
  Object.prototype.toString.call(o) === "[object Object]";
const PSM_URL = window.globalConfig.VUE_APP_API_PSM_PATH;
const FILE_URL = window.globalConfig.VUE_APP_FILE_URL;
const FILE_SYSTEM_BASE_URL = `${FILE_URL}/file/download`;
export const FILE_UPLOAD_URL = `${FILE_URL}/file/upload`;

/**
 * 获取文件地址，下载或者预览地址
 * @param fileId {string}
 * @param isDownload {boolean}
 * @returns {string|TypeError}
 */
export const getFileUrlForFileSystem = (fileId, isDownload = true) => {
  if (!fileId) return new TypeError("fileId 文件ID 是必须的");
  return `${FILE_SYSTEM_BASE_URL}?downloadFlag=${!!isDownload}&fileName=${fileId}`;
};

/**
 * 可以下载的URL包括base64
 * @param url
 * @param downloadName 可以不加后缀名
 * @param cb 下载完之后的回调函数
 */
export const downloadByUrl = (url, downloadName = "", cb) => {
  const eleLink = document.createElement("a");
  eleLink.setAttribute("download", downloadName);
  eleLink.setAttribute("href", url);
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
  cb && cb();
};

/**
 * 文件服务器下载
 * @param fileId {string}
 * @param isToken {boolean}
 */
export const downloadFileForFileSystem = (fileId, isToken = false) => {
  let url = getFileUrlForFileSystem(fileId);
  if (isToken) {
    url = `${url}&token=${user.getToken()}`;
  }
  downloadByUrl(url);
};

/**
 * 后端自定义文件下载（模板导出等非文件服务器的文件下载）
 * @param url {string}
 * @param query {object}
 * @returns {TypeError|*}
 */
export const downloadFileForCustom = (url, query) => {
  const token = user.getToken();
  if (isObject(query)) {
    query = `${stringify(query)}&token=${token}`;
    url = `${url}?${query}`;
    return void downloadByUrl(url);
    // } else if (typeof query === "string") {
    //   const queryReg = /([^&=]+)=?([^&]*)/g;
    //   console.log(queryReg.test(query), query);
    //   if (!queryReg.test(query)) {
    //     return new TypeError(
    //       `参数 query 是不合法的，请按照 ${queryReg} 格式传递`
    //     );
    //   }
    //   query = `${stringify(parse(query))}&token=${token}`;
    //   url = `${url}?${query}`;
    //   return void downloadByUrl(url);
  }
  return new TypeError("参数 query 无法处理，请保证参数对象类型");
};

/**
 * psm 网关下载文件
 * @param url {string}
 * @param query {object}
 */
export const downloadFileForPsm = (url, query) => {
  if (!url.startsWith(PSM_URL)) {
    url = PSM_URL + url;
  }
  downloadFileForCustom(url, query);
};

/**
 * 自定义上传文件地址
 * @param url
 * @param query
 * @returns {string|TypeError}
 */
export const getUploadUrlForCustom = (url, query) => {
  if (isObject(query)) {
    query = `${stringify(query)}`;
    console.log(`${url}?${query}`, "`${url}?${query}`");
    return `${url}?${query}`;
  }
  return new TypeError("参数 query 无法处理，请保证参数对象类型");
};

/**
 * 自定义psm上传文件地址
 * @param url
 * @param query
 */
export const getUploadUrlForCustomPsm = (url, query) => {
  if (!url.startsWith(PSM_URL)) {
    url = PSM_URL + url;
  }
  return getUploadUrlForCustom(url, query);
};

/**
 * 打印dom
 * @param dom
 */
export const printPageByDom = (dom = null) => {
  if (!dom) return;
  const printFrameId = "print-frame";
  let printFrame = document.getElementById(printFrameId);
  if (printFrame) {
    document.body.removeChild(printFrame);
  }
  printFrame = document.createElement("iframe");
  printFrame.name = printFrameId;
  printFrame.setAttribute("id", printFrameId);
  printFrame.setAttribute("width", "100%");
  printFrame.setAttribute("height", "100%");
  printFrame.setAttribute(
    "style",
    "position:absolute;width:0px;height:0px"
    // "text-align: center"
  );
  printFrame.srcdoc = dom.outerHTML;
  document.body.appendChild(printFrame);
  printFrame.contentWindow.focus();
  printFrame.contentWindow.print();
};

/**
 * base64 转文件预览地址
 * @param base64
 * @param contentType 类型
 * @param includeHead 是否包含base64头
 * @returns {string}
 */
export function base64ToUrl(
  base64,
  contentType = "image/png",
  includeHead = false
) {
  if (includeHead) {
    // 如果包含 base64 头，要去掉
    base64 = base64.split(",")[1];
  }
  const bstr = window.atob(base64);
  let len = bstr.length;
  const uint8Arr = new Uint8Array(len);
  while (len--) {
    // 返回指定位置的字符的 Unicode 编码
    uint8Arr[len] = bstr.charCodeAt(len);
  }
  const blob = new Blob([uint8Arr], { type: contentType });
  return URL.createObjectURL(blob);
}

export function base64ToFile(dataurl, name) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name, { type: mime });
}

export function imageCompression(file, pq) {
  if (!pq) {
    pq = Number(window.globalConfig.VUE_APP_PICTURE_QUALITY);
    // pq为1则未设置/设置错误或设置为原图
    pq = isNaN(pq) ? 1 : pq || 1;
  }
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const base64 = canvas.toDataURL("image/jpeg", pq); //第二个参数为压缩的比例，越小越模糊。0-1
      const sFile = base64ToFile(base64, file.name);
      const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
      const fd = new FormData();
      fd.append("files", sFile);
      resolve(axios.post(filePath, fd));
    };
    img.onerror = reject;
  });
}
