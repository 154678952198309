export default [
  {
    path: "/work-inspect",
    name: "WorkInspect",
    component: () => import("@/views/WorkInspect/WorkInspect"),
    meta: {
      cache: true,
      componentName: "WorkInspect",
      roles: ["work-inspect"]
    }
  },
  {
    path: "/work-inspect/:id(\\d+)/add",
    name: "WorkInspectAdd",
    props: true,
    meta: { roles: ["work-inspect"] },
    component: () => import(`@/views/WorkInspect/WorkInspectAdd`)
  },
  {
    path: "/work-inspect/:id(\\d+)/record",
    name: "WorkInspectAddRecord",
    props: true,
    meta: { roles: ["work-inspect"] },
    component: () => import(`@/views/WorkInspect/WorkInspectAddRecord`)
  },
  // 作业检查记录
  {
    path: "/work-inspect-record",
    name: "WorkInspectRecord",
    meta: { roles: ["work-inspect"] },
    component: () => import(`@/views/WorkInspect/WorkInspectRecord`)
  },
  // 记录详情
  {
    path: "/work-inspect-record/:id(\\d+)/record",
    name: "WorkInspectRecordDetails",
    props: true,
    meta: { roles: ["work-inspect"] },
    component: () => import(`@/views/WorkInspect/WorkInspectRecordDetail`)
  },
  {
    path: "/work-inspect-record/:id(\\d+)/question",
    name: "WorkInspectQuestionDetails",
    props: true,
    component: () => import(`@/views/WorkInspect/WorkInspectQuestionDetail`)
  }
];
