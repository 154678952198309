export default {
  isLogin() {
    return !!(this.validateExpireTime() && this.getToken());
  },
  getToken() {
    return sessionStorage.getItem("Authorization");
  },
  setToken(data) {
    sessionStorage.setItem("Authorization", data.token);
    sessionStorage.setItem("expireTime", data.expireTime);
    // 如果过期了, 则同步android那边的token和过期时间
    if (window.android && window.android.setToken) {
      window.android.setToken(data.token, data.expireTime, data.refreshTime);
    }
  },
  removeToken() {
    sessionStorage.removeItem("Authorization");
    sessionStorage.removeItem("expireTime");
  },
  validateExpireTime() {
    const time = sessionStorage.getItem("expireTime");
    return time >= new Date().getTime();
  },
  setUserInfo(data) {
    sessionStorage.setItem("UserInfo", JSON.stringify(data));
  },
  getUserInfo() {
    const userInfo = sessionStorage.getItem("UserInfo");
    return userInfo ? JSON.parse(userInfo) : {};
  },
  getTenantId() {
    const userInfo = this.getUserInfo();
    return userInfo.tenantId;
  }
};
