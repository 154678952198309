<template>
  <div class="work-query">
    <div
      v-for="(item, idx) in configs.filter(x =>
        x.queryType.includes(queryType)
      )"
      :key="idx"
      class="work-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="work-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="work-query__item-icon"
      />
    </div>
    <cascade-department
      v-model="department"
      include-children
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      auth="org"
      title="作业区域"
      @close="facilityVisible = false"
      @confirm="onWorkAreaChange"
    />
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '80vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getSelectWorkTypes } from "@/api/psm";
import { getWorkStatus } from "@/api/base";
export default {
  name: "WorkQuery",
  components: { SelectPopup },
  props: {
    query: Object,
    queryType: {
      type: String,
      default: "job",
      validate(value) {
        // job 是作业许可，gas 是气体检测
        return ["job", "gas"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      queryInfo: {
        workType: "",
        workStatus: "",
        poiId: "", //作业区域
        department: "",
        subDepartment: true,
        //  检测结果
        result: ""
      },
      selectWorkType: [],
      selectWorkStatus: [],
      selectTestResult: [
        { label: "合格", value: "1" },
        { label: "不合格", value: "0" }
      ],
      configs: [
        {
          title: "所属部门",
          type: "department",
          queryType: ["job", "gas"]
        },
        {
          title: "作业区域",
          type: "poiId",
          queryType: ["job", "gas"]
        },
        {
          title: "作业类别",
          type: "workType",
          types: "selectWorkType",
          queryType: ["job", "gas"]
        },
        {
          title: "作业状态",
          type: "workStatus",
          types: "selectWorkStatus",
          queryType: ["job"]
        },
        {
          title: "检测结果",
          type: "result",
          types: "selectTestResult",
          queryType: ["gas"]
        }
      ],
      department: "",
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: "",
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      facilityVisible: false
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    async initTypes() {
      try {
        const ret = await Promise.all([getSelectWorkTypes(), getWorkStatus()]);
        console.log(ret, "ret");
        this.selectWorkType =
          ret[0] && ret[0].map(x => ({ label: x.name, value: x.id }));
        this.selectWorkStatus = ret[1];
        console.log(ret);
      } catch (e) {
        console.log(e);
      }
    },
    onWorkAreaChange(facilityObj) {
      this.queryInfo.poiId = facilityObj.value || "";
      this.refreshQuery();
    },
    refreshQuery(selectValue, includeInfo) {
      if (this.queryType === "gas") {
        delete this.queryInfo.workStatus;
      }
      if (this.queryType === "job") {
        delete this.queryInfo.result;
      }
      this.queryInfo.department = this.department;
      this.queryInfo.subDepartment = includeInfo?.include;
      this.$emit("refresh", this.queryInfo);
    },
    onSearchClick({ title, type, types }, idx) {
      console.log(title, type, types, idx);
      if (type === "department") {
        this.departmentVisible = true;
      } else if (type === "poiId") {
        this.facilityVisible = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss" scoped>
.work-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }
  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    &:nth-child(2),
    &:nth-child(3) {
      justify-content: center;
    }
    &:nth-child(4) {
      justify-content: flex-end;
    }
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
