const appName = globalConfig?.VUE_APP_SERVICE_PROJECT?.toUpperCase();
export default [
  {
    path: "/workorder-dispatch",
    name: "workorderDispatch",
    component: () =>
      import(
        `@/views/${
          appName === "RT" ? "workOrder/" : "workOrderRx/"
        }workorderDispatch.vue`
      )
  },

  {
    meta: { cache: true, componentName: "WorkOrderList" },
    path: "/equipment-workorder/list",
    name: "workorderList",
    component: () =>
      import(
        /* webpackChunkName: "trouble-list" */ "@/views/workOrder/workorderList.vue"
      )
  },
  {
    path: "/equipment-workorder/detail/:type/:id?",
    name: "workorderDetail",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/workorderDetail.vue"
      )
  },
  {
    path: "/equipment-workorder/linkdetail/:id",
    name: "workorderLinkDetail",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/workorderLinkDetail.vue"
      )
  },
  {
    path: "/equipment-workorder/approveprocess/:id",
    name: "approveProcess",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/approveProcess.vue"
      )
  },
  {
    path: "/equipment-workorder/maintenancerecords",
    name: "maintenanceRecords",
    meta: {
      cache: true,
      componentName: "MaintenanceRecords"
    },
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/maintenanceRecords.vue"
      )
  },
  {
    path: "/equipment-workorder/maintenancerecords/:workorderId/:id?",
    name: "maintenanceRecordsDetail",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/maintenanceRecordsDetail.vue"
      )
  },
  {
    path: "/equipment-workorder/maintenancerecords-approveprocess/:id",
    name: "maintenanceRecordsApproveProcess",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/maintenanceRecordsApproveProcess.vue"
      )
  },
  {
    path: "/equipment-workorder/todolist",
    name: "todoList",
    meta: {
      cache: true,
      componentName: "WorkOderTodoList"
    },
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrder/todoList.vue"
      )
  }
];
