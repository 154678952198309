import axios from "../../utils/axios";
import { setHeaderToken } from "@/utils/axios";
import loginUtil from "../../utils/login";
import open from "../../api/open";
import { resetRouter } from "@/router";
import { interval } from "../../utils/index";

export default {
  // namespaced: true,
  state: {
    debug: true,
    userInfo: null,
    token: null,
    isLogin: false,
    validPhoneInfo: {
      mobile: "",
      code: ""
    }
    // redirectURL: "",
  },
  mutations: {
    setUser(state, data) {
      state.userInfo = data;
      loginUtil.setUserInfo(data);
    },
    setLoginStatus(state, data) {
      state.isLogin = data;
    },
    clearValidPhoneInfo(state) {
      state.validPhoneInfo = {
        mobile: "",
        code: ""
      };
    },
    setValidPhoneInfo(state, data) {
      state.validPhoneInfo = data;
    }
    // setRedirectURL(state, url) {
    //   state.redirectURL = url;
    // }
  },
  actions: {
    login({ commit, dispatch }, frm) {
      return new Promise((resolve, reject) => {
        open
          .login(frm)
          .then(data => {
            //获取用户信息
            const token = data.token;
            open
              .getProfile(token)
              .then(userInfo => {
                const { userType, defPasswordFlag } = userInfo;
                if (!(userType === 0 && defPasswordFlag)) {
                  // 只有验证过的用户才能设置token
                  loginUtil.setToken(data);
                  setHeaderToken();
                  commit("setUser", userInfo);
                  loginUtil.setUserInfo(userInfo);
                }
                return resolve(userInfo);
              })
              .catch(e => {
                reject(e);
              });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    getProfile({ commit }) {
      return open.getProfile().then(data => {
        // orgType: 用户类型：【0:机构(非组织)，1:组织(根机构)，2:企业组织，3:园区组织】
        data.isMechanism = data.orgType === "0";
        data.isOrganization = data.orgType === "1";
        data.isEnterprise = data.orgType === "2";
        data.isPark = data.orgType === "3";
        commit("setUser", data);

        if (window.sentry) {
          window.sentry.configureScope(scope => {
            scope.setUser(data);
          });
        }
        return data;
      });
    },
    /**
     * 保持用户登录状态
     */
    async keepToken({ commit, dispatch }) {
      const second = 1000;
      const minute = 60 * second;
      // 时间间隔默认 5 分钟
      let timeInterval =
        window.globalConfig.VUE_APP_TIME_INTERVAL || 5 * minute;
      interval(() => {
        open.getProfile();
      }, timeInterval);
    },

    /**
     * 刷新token
     * @param {*} param0
     */
    async refreshToken({ commit, dispatch }) {
      let expireTime = sessionStorage.getItem("expireTime");
      if (!expireTime) {
        return;
      }
      let now = new Date().getTime();
      let dValue = Number(expireTime) - now;
      //15分钟之内就进行刷新token 15*60*1000 = 900000  15秒：15000
      if (dValue > 0 && dValue < 900000) {
        try {
          const data = await open.refresh();
          loginUtil.setToken(data);
          setHeaderToken();
        } catch (err) {
          console.log(err);
        }
      } else if (dValue > 0) {
        // console.log("token:", dValue / 1000 + "秒后过期");
      } else {
        dispatch("clearToken"); //token已过期
      }
    },
    //退出登录
    logout({ commit, dispatch }) {
      if (window.android) {
        return window.android.logout();
      }
      // 销毁token
      open.destroy().then(() => {
        // 重置路由
        resetRouter();
        dispatch("clearToken");
      });
    },
    clearToken({ commit }, reload = true) {
      sessionStorage.removeItem("Authorization");
      axios.defaults.headers.common["Authorization"] = "";
      reload && location.reload();
    }
  }
};
