const webpackContext = require.context("./modules", false, /\.js$/);
const requireAll = ctx => ctx.keys().map(ctx);
// const moduleRoutes = requireAll(webpackContext).filter(r => r.default).map(r => r.default);
const moduleRoutes = requireAll(webpackContext).map(r => r.default);
const routes = [];

moduleRoutes.forEach(moduleRoute => {
  // 考虑路由定义为对象的情况
  const moduleRoutes = Array.isArray(moduleRoute) ? moduleRoute : [moduleRoute];
  // moduleRoutes.map(item => {
  //   if (!item.meta || item.meta.autoRequire !== false) {
  //     routes.push(item);
  //   }
  // });
  routes.push(...moduleRoutes);
});

export default routes;
