import login from "./login";
import api from "../views/rescueTask/api";
import { Dialog } from "vant";
let socket = null;
export default {
  startSocket() {
    if ("WebSocket" in window) {
      // 打开一个 web socket
      if (globalConfig.VUE_APP_ACCIDENT_SOCKET) {
        socket = new WebSocket(
          `${globalConfig.VUE_APP_ACCIDENT_SOCKET}/websocket/${
            login.getUserInfo().id
          }`
        );
      }
      if (socket) {
        socket.onopen = function() {
          // Web Socket 连接成功时的回调
          console.log("socket连接成功！");
        };
      }

      if (socket) {
        socket.onmessage = function(evt) {
          let data = JSON.parse(evt.data);
          if (data.code == 200) {
            let obj = JSON.parse(data.message);
            let leader = "--";
            if (obj.terminationUser) {
              api.queryUserName(obj.terminationUser).then(res => {
                leader = res.nickName;
                Dialog.alert({
                  title: "应急终止",
                  message: `根据${leader}指示，${obj?.name}的应急状态变更为应急终止，相关工作人员可终止应急救援行动！`
                });
              });
            }
          }
        };
      }

      if (socket) {
        socket.onclose = function() {
          //关闭websocket,监听链接断开，重新连接
          if (globalConfig.VUE_APP_ACCIDENT_SOCKET) {
            socket = new WebSocket(
              `${globalConfig.VUE_APP_ACCIDENT_SOCKET}/websocket/${
                login.getUserInfo().id
              }`
            );
          }
        };
      }
    } else {
      // 浏览器不支持 WebSocket
      console.log("浏览器不支持WebSocket!!!");
    }
  }
};
