const UserIndex = () => import("../../views/user/UserIndex");
const UserPasswordEdit = () => import("../../views/user/UserPasswordEdit");
const UserTodoList = () => import("../../views/user/UserTodoList");
const UserMessage = () => import("../../views/user/UserMessage");
const UserAddressBooks = () => import("../../views/user/UserAddressBooks");
const UserAddressBooksItem = () =>
  import("../../views/user/UserAddressBooksItem");
const UserSetting = () => import("../../views/user/UserSetting");
const UserHelp = () => import("../../views/user/UserHelp");
const UserAbout = () => import("../../views/user/UserAbout");
const Layout = () => import("@/components/Layout.vue");
const UserDetail = () => import("@/views/user/user-book/UserDetail.vue");
const UserFaceComparison = () => import("@/views/user/UserFaceComparison.vue");

const routes = {
  path: "/user",
  name: "User",
  // component: User,
  component: Layout,
  redirect: "/user/index",
  children: [
    {
      path: "index",
      name: "userIndex",
      component: UserIndex
    },
    {
      path: "password/edit",
      name: "userPasswordEdit",
      component: UserPasswordEdit
    },
    {
      path: "todo",
      name: "userTodoList",
      component: UserTodoList
    },
    {
      path: "address-books",
      name: "userAddressBooks",
      component: UserAddressBooks
    },
    {
      path: "address-books/:id",
      name: "userAddressBooksItem",
      component: UserAddressBooksItem
    },
    {
      path: "message",
      name: "userMessage",
      component: UserMessage
    },
    {
      path: "setting",
      name: "userSetting",
      component: UserSetting
    },
    {
      path: "help",
      name: "userHelp",
      component: UserHelp
    },
    {
      path: "about",
      name: "userAbout",
      component: UserAbout
    },
    {
      path: "detail/:id",
      name: "userDetail",
      component: UserDetail
    },
    {
      path: "faceComparison",
      name: "userFaceComparison",
      component: UserFaceComparison
    }
  ]
};

export default routes;
