import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const psm = apiPath.psm;

// jsa详情
export const getJsaInfo = id => {
  return axios.get(`${psm}/info/jsas/${id}`);
};

// 查询最近选择人员
export const getRecentUsePerson = params => {
  return axios.get(`${psm}/list/recent/person`, { params });
};
