export default [
  {
    path: "/workOrderRx/workOrderRxList/:id?",
    name: "workOrderRxList",
    component: () => import("@/views/workOrderRx/workOrderRxList.vue")
  },
  {
    path: "/workOrderRxList/workOrderRxEdit/:type/:id?",
    name: "workOrderRxEdit",
    component: () => import("@/views/workOrderRx/workOrderRxEdit.vue")
  },

  {
    path: "/workOrderRxList/approveProcessRx/:id",
    name: "approveProcessRx",
    component: () => import("@/views/workOrderRx/approveProcessRx.vue")
  },
  {
    path: "/workOrderRxList/maintenanceRecordsRx",
    name: "maintenanceRecordsRx",
    meta: {
      cache: true,
      componentName: "maintenanceRecordsRx"
    },
    component: () => import("@/views/workOrderRx/maintenanceRecordsRx.vue")
  },
  {
    path: "/workOrderRxList/maintenanceRecordsRxDetail/:workorderId/:id?",
    name: "maintenanceRecordsRxDetail",
    component: () =>
      import("@/views/workOrderRx/maintenanceRecordsRxDetail.vue")
  },
  {
    path: "/workOrderRxList/maintenanceExternalRepairsAdd/:recordId?",
    name: "maintenanceExternalRepairsAdd",
    component: () =>
      import("@/views/workOrderRx/maintenanceExternalRepairsAdd.vue")
  },
  {
    path: "/workOrderRxList/maintenanceExternalRepairsEdit/:recordId?",
    name: "maintenanceExternalRepairsEdit",
    component: () =>
      import("@/views/workOrderRx/maintenanceExternalRepairsEdit.vue")
  },
  {
    path: "/workOrderRxList/maintenanceRecordsApproveProcess/:id",
    name: "maintenanceRecordsApproveProcessRx",
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrderRx/maintenanceRecordsApproveProcessRx.vue"
      )
  },
  {
    path: "/workOrderRxList/todoListRx",
    name: "todoListRx",
    meta: {
      cache: true,
      componentName: "todoListRx"
    },
    component: () =>
      import(
        /* webpackChunkName: "trouble-add" */ "@/views/workOrderRx/todoListRx.vue"
      )
  }
];
