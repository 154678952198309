import axios from "../utils/axios";
import apiPath from "../utils/apiPath";

console.log(apiPath, "apiPath");
const config = apiPath.config;
/**
 * 查询模块功能配置项（列表)
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListMonitoringModuleOptionItems = params => {
  return axios.get(config + `/monitoring/list/module-option-items`, { params });
};
