export default [
  {
    path: "/switching-dispatch",
    name: "switching-dispatch",
    component: () =>
      import(
        /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitching"
      )
  },
  {
    path: "/switching",
    name: "switching",
    component: { render: h => h("router-view") },
    meta: {
      roles: ["switching"]
    },
    redirect: "/switching/mine",
    children: [
      {
        path: "mine",
        name: "switching-mine",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingMine"
          )
      },
      {
        path: "query",
        name: "switching-query",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingQuery"
          )
      },
      {
        path: "add",
        name: "switching-add",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingAdd"
          )
      },
      {
        path: "edit/:id",
        name: "switching-edit",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingAdd"
          )
      },
      {
        path: "progress/:id",
        name: "switching-progress",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingProgress"
          )
      },
      {
        path: ":id",
        name: "switching-detail",
        meta: {
          roles: ["switching"]
        },
        component: () =>
          import(
            /* webpackChunkName: "interlockSwitching" */ "@/views/interlockSwitching/InterlockSwitchingDetail"
          )
      }
    ]
  }
];
