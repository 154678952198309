import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

export function getPageSelectWarehouses(params) {
  return axios.get(api + "/page/select/warehouses", { params });
}

/**
 *
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageSelectWarehousesForOrg(params) {
  return axios.get(api + "/page/select/org/warehouses", { params });
}
