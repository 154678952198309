import { getEnterpriseTag } from "@/utils/env";

/* 企业定制白名单 */
const todoWhiteList = [
  {
    path: "/plans",
    tag: "ZM"
  }
];
export const checkWhiteAuth = uri => {
  const envTag = getEnterpriseTag();
  const white = todoWhiteList.find(
    v => uri.startsWith(v.path) && v.tag.includes(envTag)
  );
  return !!white;
};
