<template>
  <div class="set-newpwd-wrapper">
    <van-nav-bar left-arrow :border="false" @click-left="onClickLeft">
    </van-nav-bar>
    <div class="valid-main">
      <div class="title">请设置新密码</div>
      <div class="newpwd-input-box">
        <div
          class="newpwd-box"
          :class="{ red: newPassword && !isNewPasswordFormatRight }"
        >
          <input
            v-model="newPassword"
            class="newpwd-input"
            :type="isShowPwd1 ? 'text' : 'password'"
            placeholder="输入新密码"
            @blur="pwd1Blur"
          />
          <van-icon
            class="right-icon"
            :name="isShowPwd1 ? 'eye-o' : 'closed-eye'"
            @click="isShowPwd1 = !isShowPwd1"
          />
          <span class="tip">8-20位字符，必须由大小写字母和数字组成</span>
          <pwd-strong :pwd="newPassword" class="pwd-strong" />
        </div>
        <div
          class="newpwd-box"
          :class="{ red: newPassword !== confirmPassword }"
        >
          <input
            v-model="confirmPassword"
            class="newpwd-input"
            :type="isShowPwd2 ? 'text' : 'password'"
            placeholder="再次输入新密码"
            @blur="pwd2Blur"
          />
          <van-icon
            class="right-icon"
            :name="isShowPwd2 ? 'eye-o' : 'closed-eye'"
            @click="isShowPwd2 = !isShowPwd2"
          />
          <span class="tip">{{ tip2 }}</span>
        </div>
        <van-button
          class="save-btn"
          :disabled="!(newPassword && confirmPassword)"
          round
          block
          type="info"
          @click="save"
          >保 存</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { REG_PASSWORD } from "@/utils/constant";
import { mapMutations, mapState } from "vuex";
import { Toast } from "vant";
import auth from "@/api/authorization";
import PwdStrong from "@/components/PwdStrong";
export default {
  components: {
    PwdStrong
  },
  data() {
    return {
      mobile: "",
      verificationCode: "",
      newPassword: "",
      confirmPassword: "",
      isNewPasswordFormatRight: true,
      isShowPwd1: false,
      isShowPwd2: false,
      tip2: ""
    };
  },
  computed: {
    ...mapState({
      vcodeInfo: state => state.login.validPhoneInfo
    })
  },
  created() {},
  methods: {
    ...mapMutations({
      clearValidPhoneInfo: "clearValidPhoneInfo"
    }),
    onClickLeft() {
      history.go(-1);
    },
    pwd1Blur() {
      if (!REG_PASSWORD.test(this.newPassword)) {
        // 格式不符合要求, 则需要变红
        this.isNewPasswordFormatRight = this.newPassword ? false : true;
        return;
      }
      this.isNewPasswordFormatRight = true;
      if (this.confirmPassword) {
        this.pwd2Blur();
      }
    },
    pwd2Blur() {
      if (this.newPassword !== this.confirmPassword) {
        this.tip2 = "两次密码输入不一致";
        return;
      }
      this.tip2 = "";
    },
    getForm() {
      const form = {
        mobile: this.vcodeInfo.mobile,
        verificationCode: this.vcodeInfo.code,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
      };
      return form;
    },
    save() {
      // 如果格式不对则返回
      if (!this.isNewPasswordFormatRight) {
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        Toast("两次密码输入不一致");
        console.log(window.APP.getPlatform());
        return;
      }
      // 格式正确并且两次密码一致的情况那就执行保存
      auth
        .updateAuthorizationUpdateUsersPasswordMobile(this.getForm())
        .then(res => {
          this.clearValidPhoneInfo();
          Toast({
            message: "设置新密码成功，请重新登录",
            icon: "passed",
            duration: 2000,
            className: "set-newpwd-toast"
          });

          setTimeout(() => {
            // 跳转到登录
            if (window.APP.getPlatform().isAndroid) {
              window.android.logout();
            } else {
              this.$router.replace("/login");
            }
          }, 2000);
        })
        .catch(e => {
          Toast("修改失败");
        });
    }
  }
};
</script>

<style lang="scss">
.set-newpwd-wrapper {
  .van-nav-bar {
    background-color: #fff;
    .van-icon {
      color: #3b4664;
    }
  }
  .valid-main {
    padding: 0 24px;
    .title {
      font-size: 24px;
      color: #3b4664;
      margin-top: 12px;
    }
    .newpwd-input-box {
      margin-top: 78px;
      .newpwd-box {
        position: relative;
        display: flex;
        height: 30px;
        border-bottom: 1px solid #d8d8d8;
        &.red {
          .tip {
            color: #f13030;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: -18px;
          font-size: 12px;
          color: #8c8f97;
        }
        .pwd-strong {
          position: absolute;
          left: 0;
          bottom: -32px;
        }
        .newpwd-input {
          flex: 1;
          border: none;
          font-size: 14px;
          color: #3b4664;
        }
        .right-icon {
          color: #d4dbe3;
          font-size: 16px;
          width: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
      .save-btn {
        margin-top: 62px;
        font-size: 16px;
      }
    }
  }
}
.set-newpwd-toast {
  padding: 14px 18px;
  max-width: none;
  width: auto;
  .van-toast__text {
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>
