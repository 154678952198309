let apiPathMap = {};

function getApiMap() {
  const map = {};
  const processEnv = process.env;
  const regExp = /^VUE_APP_API_([A-Z]+)_PATH$/;
  Object.keys(processEnv).forEach(key => {
    if (key.startsWith("VUE_APP_API_")) {
      const k = key.replace(regExp, "$1").toLocaleLowerCase();
      map[k] = processEnv[key];
    }
  });
  return map;
}

apiPathMap = getApiMap();

function updateApiPath() {
  const conf = window.globalConfig;
  const regExp = /^VUE_APP_API_([A-Z]+)_PATH$/;
  Object.keys(conf).forEach(key => {
    if (key.startsWith("VUE_APP_API_")) {
      const k = key.replace(regExp, "$1").toLocaleLowerCase();
      apiPathMap[k] = conf[key];
    }
  });
}

export { updateApiPath };

export default apiPathMap;
