import { getTenantId, isProd, localEnv, syncFetch } from "./helper";

const defaultConf = {
  VUE_APP_VIDEO_LIVE_FLOW_TYPE: "internetHls",
  VUE_APP_VIDEO_API_PATH: "/proxy_video",
  VUE_APP_PICTURE_QUALITY: "0.8"
};

function getTenantConf() {
  let config = window.globalConfig;
  const tenantId = getTenantId();
  const configPath = `${config.VUE_APP_CONFIG_PATH}/${tenantId}`;
  syncFetch(configPath, {
    success(res) {
      try {
        const data = JSON.parse(res?.data || "{}");
        const appConf = data?.appConf || {};
        if (isProd) {
          config = { ...defaultConf, ...config, ...appConf };
        } else {
          config = { ...defaultConf, ...appConf, ...config };
        }
        window.globalConfig = config;
        if (isProd) {
          window.globalConfig.VUE_APP_NAME = data.VUE_APP_NAME;
        }
      } catch (e) {
        console.log(e);
        window.globalConfig = localEnv;
      }
    }
  });
}

getTenantConf();
