//润滑保养
export default [
  {
    path: "/deviceLubImplement",
    name: "deviceLubImplement",
    component: () =>
      import("@/views/deviceLubImplement/deviceLubImplement.vue"),
    meta: {
      cache: true,
      componentName: "deviceLubImplement",
      roles: ["deviceLubImplement"]
    }
  },
  {
    path: "/deviceLubImplement/deviceLubImplementEdit/:type/:id?",
    name: "deviceLubImplementEdit",
    component: () =>
      import("@/views/deviceLubImplement/deviceLubImplementEdit.vue"),
    props: true,
    meta: {
      componentName: "deviceLubImplementEdit",
      roles: ["deviceLubImplement"]
    }
  },
  {
    path: "/deviceLubImplement/lubProjectEdit",
    name: "lubProjectEdit",
    component: () => import("@/views/deviceLubImplement/lubProjectEdit.vue"),
    props: true,
    meta: {
      componentName: "lubProjectEdit",
      roles: ["deviceLubImplement"]
    }
  },
  {
    path: "/deviceLubImplement/materialsEdit",
    name: "materialsEdit",
    component: () => import("@/views/deviceLubImplement/materialsEdit.vue"),
    props: true,
    meta: {
      componentName: "materialsEdit",
      roles: ["deviceLubImplement"]
    }
  },
  {
    path: "/deviceLubImplement/toolsEdit",
    name: "toolsEdit",
    component: () => import("@/views/deviceLubImplement/toolsEdit.vue"),
    props: true,
    meta: {
      componentName: "toolsEdit",
      roles: ["deviceLubImplement"]
    }
  }
];
