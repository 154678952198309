export default [
  //应急管理
  {
    path: "/urgent",
    name: "Urgent",
    component: () => import("@/views/urgentManage/index.vue"),
    meta: { componentName: "Urgent" }
  },
  // 应急通讯
  {
    path: "/communication",
    name: "Communication",
    component: () => import("@/views/urgentManage/communication/index.vue"),
    meta: { componentName: "Communication" }
  },
  // 应急通讯
  {
    path: "/communication/:sys",
    name: "CommunicationSys",
    component: () => import("@/views/urgentManage/communication/system.vue"),
    meta: { componentName: "CommunicationSys" }
  },
  // 应急预案
  {
    path: "/plan",
    name: "Plan",
    component: () => import("@/views/urgentManage/plan/index.vue"),
    meta: { componentName: "Plan" }
  },
  // 应急预案
  {
    path: "/plan/:id",
    name: "PlanDetails",
    component: () => import("@/views/urgentManage/plan/detail.vue"),
    meta: { componentName: "PlanDetails" }
  },
  // 应急预案
  {
    path: "/plan/:id",
    name: "Staff",
    component: () => import("@/views/urgentManage/plan/staff.vue"),
    meta: { componentName: "Staff" }
  }
];
