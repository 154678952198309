const improveInspectEdit = () =>
  import(
    /* webpackChunkName: "ImproveInspectEdit" */ "@/views/improveTrouble/plans/inspectDetail"
  );

export default [
  {
    path: "/improve-corrections/:id(\\d+)",
    redirect: { name: "improve-trouble-details" }
  },
  {
    path: "/improve-trouble-dispatch",
    name: "ImproveTroubleDispatch",
    component: () =>
      import(
        /* webpackChunkName: "ImproveTroubleDispatch" */ "@/views/improveTrouble/trouble/TroubleDispatch"
      )
  },
  {
    path: "/improve-trouble",
    name: "ImproveTrouble",
    component: () =>
      import(
        /* webpackChunkName: "ImproveTroubleFixIndex" */ "@/views/improveTrouble/trouble/trouble-fix/index"
      ),
    redirect: "/improve-trouble/list",
    meta: { roles: ["improve-corrections"] },
    children: [
      {
        path: "list",
        name: "improve-trouble-list",
        component: () =>
          import(
            /* webpackChunkName: "improve-trouble-list" */ "@/views/improveTrouble/trouble/trouble-fix/troubleFixList.vue"
          )
      },
      {
        path: "add",
        name: "improve-trouble-add",
        meta: { type: "add" },
        component: () =>
          import(
            /* webpackChunkName: "improve-trouble-add" */ "@/views/improveTrouble/trouble/trouble-fix/troubleAdd.vue"
          )
      },
      {
        path: "flow",
        name: "improve-trouble-flow",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "improve-trouble-flow" */ "@/views/improveTrouble/trouble/trouble-fix/troubleFlow.vue"
          )
      },
      // 隐患整改详情相关
      {
        path: ":id(\\d+)",
        name: "improve-trouble-details",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "improve-trouble-details" */ "@/views/improveTrouble/trouble/trouble-fix/troubleScheduling.vue"
          ),
        children: [
          // 隐患审批
          {
            path: "approval",
            name: "improve-trouble-approval",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-approval" */ "@/views/improveTrouble/trouble/trouble-fix/troubleApproval.vue"
              )
          },
          // 隐患接收
          {
            path: "receive",
            name: "improve-trouble-receive",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-receive" */ "@/views/improveTrouble/trouble/trouble-fix/troubleReceive.vue"
              )
          },
          // 隐患整改两个阶段
          {
            path: "modify",
            name: "improve-trouble-modify",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-modify" */ "@/views/improveTrouble/trouble/trouble-fix/troubleModify.vue"
              )
          },
          // 待延期审批
          {
            path: "delay-approval",
            name: "improve-trouble-delay-approval",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-delay-approval" */ "@/views/improveTrouble/trouble/trouble-fix/troubleDelayApproval.vue"
              )
          },
          // 整改验收
          {
            path: "check",
            name: "improve-trouble-check",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-check" */ "@/views/improveTrouble/trouble/trouble-fix/troubleCheck.vue"
              )
          },
          {
            path: "info",
            name: "improve-trouble-info",
            meta: { type: "info" },
            component: () =>
              import(
                /* webpackChunkName: "improve-trouble-info" */ "@/views/improveTrouble/trouble/trouble-fix/troubleInfo.vue"
              )
          }
        ]
      }
    ]
  },
  // 排查计划
  {
    path: "/improve-plans",
    name: "ImprovePlans",
    component: () =>
      import(
        /* webpackChunkName: "ImprovePlansIndex" */ "@/views/improveTrouble/plans/index"
      ),
    redirect: "/improve-plans/list",
    meta: { roles: ["improve-plans"] },
    children: [
      {
        path: "list",
        name: "ImprovePlanList",
        component: () =>
          import(
            /* webpackChunkName: "ImprovePlanList" */ "@/views/improveTrouble/plans/planList"
          )
      },
      {
        path: ":id(\\d+)",
        name: "ImprovePlanDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ImprovePlanDetail" */ "@/views/improveTrouble/plans/planDetail"
          )
      },
      {
        path: ":planId(\\d+)/inspect/add",
        name: "ImproveInspectAdd",
        props: true,
        component: improveInspectEdit
      },
      {
        path: ":planId(\\d+)/inspect/:id(\\d+)",
        name: "ImproveInspectEdit",
        props: true,
        component: improveInspectEdit
      },
      // 选择隐患
      {
        path: ":planId(\\d+)/inspect/select-trouble",
        name: "ImproveSelectTrouble",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ImproveSelectTrouble" */ "@/views/improveTrouble/plans/selectTrouble"
          )
      },
      {
        path: ":planId(\\d+)/inspect/create-trouble",
        name: "ImproveCreateTrouble",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ImproveSelectTrouble" */ "@/views/improveTrouble/plans/createTrouble"
          )
      }
    ]
  }
];
