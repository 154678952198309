export default [
  {
    path: "/contractor",
    name: "Contractor",
    component: () => import("@/views/contractor/contractor"),
    props: true
  },

  {
    path: "/contractorstaff/train/:id(\\d+)",
    name: "ContractorTrain",
    component: () => import("@/views/contractor/contractorTrain"),
    props: true
  },

  {
    path: "/contractorstaff",
    name: "ContractorPersonList",
    component: () => import("@/views/contractor/contractorPersonList"),
    props: true,
    meta: {
      cache: true,
      componentName: "ContractorPersonList"
    }
  },
  // 承包商评估
  {
    path: "/contractor-evaluate/:id",
    name: "ContractorEvaluateDetail",
    meta: {
      roles: ["contractorstaff"]
    },
    component: () =>
      import(
        /* webpackChunkName: "contractor" */ "@/views/contractor/contractorEvaluate/ContractorEvaluateDetail.vue"
      )
  },
  {
    path: "/contractor-evaluate",
    name: "ContractorEvaluate",
    meta: {
      roles: ["contractorstaff"]
    },
    component: () =>
      import(
        /* webpackChunkName: "contractor" */ "@/views/contractor/contractorEvaluate/ContractorEvaluate.vue"
      )
  },
  // 承包商人员详情
  {
    path: "/contractorstaff/:id(\\d+)",
    name: "ContractorPerson",
    component: () => import("@/views/contractor/contractorPersonDetails"),
    meta: { roles: ["contractorstaff"] },
    props: true
  },
  {
    path: "/dailySupervision",
    name: "DailySupervision",
    component: () => import("@/views/contractor/dailySupervision"),
    props: true,
    meta: {
      // cache: true,
      componentName: "DailySupervision"
    }
  },
  // 承包商人员详情
  {
    path: "/dailySupervision/:id",
    name: "DailySupervisionDetails",
    component: () => import("@/views/contractor/dailySupervisionDetails"),
    // meta: { roles: ["dailySupervision"] },
    props: true
  }
];
