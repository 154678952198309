export default {
  state: {
    // 排查项
    checkItems: [],
    // 已关联过的所有隐患ID
    selectedTroubleIds: [],
    editable: false
  },
  mutations: {
    setCheckItems(state, checkItems = []) {
      state.checkItems = checkItems;
    },
    // 在待选和已选种挣扎
    setTroubleIds(state, troubleIds) {
      state.selectedTroubleIds = troubleIds;
      console.log(state.selectedTroubleIds, "state.selectedTroubleIds");
    },
    setEditable(state, editable) {
      state.editable = editable;
    }
  },
  actions: {
    // 更新排查项
    updateCheckItems({ commit, dispatch }, checkItems) {
      console.log(
        `%cPer%c更新排查项`,
        "background: #606060; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;",
        "background: #1475b2; color: white; padding: 1px 10px; border-top-right-radius: 3px;border-bottom-right-radius: 3px;",
        checkItems
      );
      commit("setCheckItems", checkItems);
      // 同步选中隐患ID
      dispatch("asyncTroubleIds");
    },
    clearCheckItems({ commit }) {
      console.log(
        `%cPer%c清空排查项`,
        "background: #f55f5f; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;",
        "background: #1475b2; color: white; padding: 1px 10px; border-top-right-radius: 3px;border-bottom-right-radius: 3px;"
      );
      commit("setCheckItems", []);
      commit("setTroubleIds", []);
    },
    asyncTroubleIds({ commit }) {
      const checkItems = this.state.plans.checkItems;
      console.log(checkItems, "asyncTroubleIds -> checkItems");
      const set = new Set();
      checkItems.forEach(item => {
        if (item.troubleIdArr) {
          item.troubleIdArr.forEach(x => set.add(x + ""));
        }
        if (item.troubleId) {
          item.troubleId.forEach(x => set.add(x + ""));
        }
      });
      console.log(set, "asyncTroubleIds");
      commit("setTroubleIds", [...set]);
    }
  }
};
