export default [
  {
    path: "/apply",
    name: "apply",
    component: () => import("@/views/apply/ApplyList.vue"),
    meta: {
      cache: true,
      componentName: "ApplyList",
      roles: ["apply"]
    }
  },
  {
    path: "/apply/:id",
    name: "applyDetail",
    props: true,
    component: () => import("@/views/apply/ApplyDetail.vue")
  }
];
