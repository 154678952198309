import { mapState } from "vuex";

export default {
  data() {
    return {
      projectName: window?.globalConfig?.VUE_APP_NAME,
      customMade: window.globalConfig?.VUE_APP_CUSTOM_MADE
    };
  },
  computed: {
    ...mapState({
      parentMenus: state => state.menu.parentMenus
    }),
    isYn() {
      return this.projectName === "YN";
    },
    isRt() {
      return this.projectName === "RT";
    },
    isYn_Rt() {
      return ["YN", "RT"].includes(this.projectName);
    },
    curRouteName() {
      return this.parentMenus?.slice(-1)[0]?.name || "";
    },
    isBy() {
      return this.customMade === "BY"; // 北元
    },
    isZx() {
      return this.customMade === "ZX"; // 新乡中新化工
    },
    isRh() {
      return this.customMade === "RH"; // 瑞恒
    }
  }
};
