export default [
  {
    path: "/inspection-management-dispatch",
    name: "InspectionManagementDispatch",
    component: () => import("@/views/inspectionManagement/index.vue")
  },
  /*待我执行*/
  {
    path: "/inspect-record",
    name: "inspectionNew",
    component: () => import("@/views/inspectionNew/inspection"),
    props: () => ({
      curTab: 0
    }),
    meta: {
      cache: false,
      componentName: "InspectionNew",
      roles: ["inspect-record"]
    }
  },
  // 已执行
  {
    path: "/inspect-record-done",
    name: "inspectionRecordDone",
    component: () => import("@/views/inspectionNew/inspection"),
    props: () => ({
      curTab: 1
    }),
    meta: {
      cache: false,
      componentName: "inspectionRecordDone",
      roles: ["inspect-record"]
    }
  },
  // 全部的
  {
    path: "/inspect-record-all",
    name: "inspectionAll",
    component: () => import("@/views/inspectionNew/inspectionAll"),
    props: () => ({
      curTab: 0
    }),
    meta: {
      cache: false,
      componentName: "inspectionAll",
      roles: ["inspect-record"]
    }
  },
  {
    path: "/inspect-record/:id(\\d+)",
    name: "inspectionDetailNew",
    component: () => import("@/views/inspectionNew/inspectionDetail"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path: "/inspect-record/:id(\\d+)/replenish",
    name: "InspectionReplenish",
    props: false,
    component: () => import("@/views/inspectionNew/InspectionReplenish"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path:
      "/inspect-record/task-project/:recordId(\\d+)/:pointId(\\d+)/:status(\\d+)/:signType(\\d+)",
    name: "taskProject",
    component: () => import("@/views/inspectionNew/taskProject"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path: "/inspect-record/not-content-save/:recordId(\\d+)/:pointId(\\d+)",
    name: "InspectNotContent",
    props: true,
    component: () => import("@/views/inspectionNew/InspectNotContent"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path: "/inspect-record/task-project/history/:id(\\d+)",
    name: "taskProjectHistory",
    component: () => import("@/views/inspectionNew/taskProjectHistory"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  // 待补录
  {
    path: "/inspect-record-transcribe",
    name: "inspectionRecordTranscribe",
    component: () =>
      import("@/views/inspectionManagement/inspectionTranscribe/index.vue"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  // 补录详情页
  {
    path: "/inspect-record-transcribe/:id(\\d+)",
    name: "inspectionTranscribeDetail",
    component: () =>
      import(
        "@/views/inspectionManagement/inspectionTranscribe/inspectionTranscribeDetail.vue"
      ),
    meta: {
      roles: ["inspect-record"]
    }
  },
  // 补录漏检原因
  {
    path: "/inspect-record-transcribe/:id(\\d+)/leak-reason",
    name: "inspectionTranscribeLeakReason",
    component: () =>
      import(
        "@/views/inspectionManagement/inspectionTranscribe/LeakReason.vue"
      ),
    meta: {
      roles: ["inspect-record"]
    }
  },
  // 巡检记录
  {
    path: "/inspect-record-list",
    name: "inspectRecordList",
    component: () =>
      import("@/views/inspectionManagement/inspectionRecord/index.vue"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path: "/inspect-exception",
    name: "InspectionException",
    component: () => import("@/views/InspectionException/InspectionException"),
    meta: {
      cache: true,
      componentName: "InspectionException",
      roles: ["inspect-exception"]
    }
  },
  {
    path: "/inspect-exception/:id",
    name: "InspectionExceptionDetail",
    props: true,
    component: () =>
      import("@/views/InspectionException/InspectionExceptionDetail")
  },
  {
    path: "/inspect-exception/:id/trouble",
    name: "InspectionExceptionTrouble",
    props: true,
    component: () => import("@/views/InspectionException/InspectionTrouble")
  }
];
