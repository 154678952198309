import { updateApiPath } from "@/utils/apiPath";
import { syncFetch, localEnv, isProd } from "./helper";

function processSystemConf(conf) {
  if (!Object.keys(conf).length) return {};
  const gateway = [
    "basics",
    "psm",
    "warning",
    "message",
    "workflow",
    "open",
    "openGis",
    "authorization",
    "dictionary",
    "oauth",
    "platform",
    "asm",
    "audit",
    "clpm",
    "improve",
    "tisa",
    "sso",
    "config",
    "equipment",
    "contractor",
    "inspection",
    "vacation",
    "shift",
    "performance",
    "apparatus",
    "uploader",
    "camera"
  ];

  const o = {};

  // 服务 service 相关
  o[`VUE_APP_SERVICE_MODE`] = conf["commons.libs.deploy.mode"];
  // 部署的服务主线
  o[`VUE_APP_SERVICE_PROJECT`] = conf[`commons.libs.deploy.project`];
  // 部署的服务省份
  o[`VUE_APP_SERVICE_PROVINCE`] = conf[`commons.libs.deploy.province`];

  const baseGateway = conf.gateway;
  // 网关和请求业务服务 API
  gateway.forEach(v => {
    const path = conf[v] ? `${conf[v]}` : `${baseGateway}/${v}`;
    o[v] = o[`VUE_APP_API_${v.toUpperCase()}_PATH`] = path;
  });
  o[`VUE_APP_API_PATH`] = `${baseGateway}`;
  o[`VUE_APP_SOCKET_PATH`] = `${baseGateway}`;
  o[`VUE_APP_CONFIG_PATH`] = `${baseGateway}/config/public/projects-configs`;
  o[`VUE_APP_API_IDS_PATH`] = `${conf.ids}`;
  o[`VUE_APP_API_NEIXUNBAO_PATH`] = `${conf.nxb}`;
  o[`VUE_APP_FILE_URL`] = `${conf.storage}`;
  o[`VUE_APP_WORKFLOW_PATH`] = `${conf.workflow}`;
  o[`VUE_APP_VIDEO_API_PATH`] = `${conf.video}`;
  return o;
}

function fillGlobalConf(data) {
  if (!data?.length) {
    return (window.globalConfig = {});
  }
  const obj = {};
  const appIdentify = `commons.libs.address.app`;
  data.forEach(v => {
    if (v.name.includes(appIdentify)) {
      v.name = v.name.replace(appIdentify + ".", "");
    }
    obj[v.name] = v.value;
  });
  let conf = processSystemConf(obj);
  if (!isProd) {
    conf = { ...conf, ...localEnv };
  }
  window.globalConfig = conf;
  updateApiPath();
}

function getSystemConf() {
  let url;
  if (!isProd) {
    url = `${localEnv.VUE_APP_LOCATION_PATH}/config`;
  } else {
    url = `/config`;
  }
  syncFetch(url, {
    success(res) {
      const data = res.code === "0" ? res.data : [];
      fillGlobalConf(data);
    },
    error() {
      fillGlobalConf();
    }
  });
}

getSystemConf();
