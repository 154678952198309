export default [
  //资源跟踪
  {
    path: "/resource",
    name: "Resource",
    component: () => import("@/views/resource/index.vue"),
    meta: { cache: false, componentName: "Resource" }
  },
  //资源跟踪详情
  {
    path: "/resourceDetail/:formData",
    name: "ResourceDetail",
    component: () => import("@/views/resource/detail.vue"),
    meta: { cache: false, componentName: "ResourceDetail" }
  }
];
