import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

export function getPageSelectTankAreas(params) {
  return axios.get(api + "/page/select/tankAreas", { params });
}

export function getPageSelectTankAreasForOrg(params) {
  return axios.get(api + "/page/select/org/tankAreas", { params });
}
