import loginUtil from "../utils/login";
import axios from "../utils/axios";

import apiPath from "../utils/apiPath";
const API_OPEN = apiPath.open;

export default {
  async login(form) {
    return axios.post(API_OPEN + "/auth/authorize", form);
  },
  destroy() {
    return axios.post(API_OPEN + "/auth/destroy");
  },
  refresh() {
    return axios.post(API_OPEN + "/auth/refresh");
  },
  getProfile(token) {
    if (!token) {
      token = `Bearer ${loginUtil.getToken()}`;
    } else {
      token = `Bearer ${token}`;
    }
    return axios.get(API_OPEN + "/auth/profile", {
      headers: { Authorization: token }
    });
  }
};
