import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

export function getPageSelectTanks(params) {
  return axios.get(api + "/page/select/tanks", { params });
}

export function getPageSelectTanksForOrg(params) {
  return axios.get(api + "/page/select/org/tanks", { params });
}
