import Tree from "@/components/DepartmentTree/department-tree";

const webpackContext = require.context("../components", false, /\.vue$/);

const requireAll = ctx => ctx.keys().map(ctx);

const dealName = name => (name ? name.replace(/\w/, v => v.toUpperCase()) : "");

export default Vue => {
  requireAll(webpackContext).forEach(componentModule => {
    const { default: component } = componentModule;
    const { __file: file } = component;
    let name = component.name;
    if (!name) {
      if (file) {
        name = dealName(file.slice(file.lastIndexOf("/") + 1, -4));
      }
    }
    if (name) {
      Vue.component(name, component);
    }
  });
  Vue.component(Tree.name, Tree);
};
