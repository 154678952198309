export default [
  {
    path: "/secure-repository-dispatch",
    name: "SecureRepositoryDispatch",
    component: () => import("@/views/secureRepository/index.vue")
  },
  {
    path: "/laws",
    name: "Laws",
    component: () => import("@/views/secureRepository/lawView/lawView.vue")
  },
  {
    path: "/laws/detail/:id",
    name: "laws-detail",
    component: () =>
      import("@/views/secureRepository/lawView/lawViewDetail.vue"),
    props: true
  },
  // 政策文件
  {
    path: "/policydocument",
    name: "policydocument",
    component: () =>
      import("@/views/secureRepository/policyDocument/PolicyDocument.vue")
  },
  {
    path: "/policydocument/detail/:id",
    name: "policydocument-detail",
    component: () =>
      import(
        "@/views/secureRepository/policyDocument/PolicyDocumentDetail.vue"
      ),
    props: true
  },
  // 标准规范
  {
    path: "/policystandard",
    name: "policystandard",
    component: () =>
      import("@/views/secureRepository/policystandard/PolicyStandard.vue")
  },
  {
    path: "/policystandard/detail/:id",
    name: "policystandard-detail",
    component: () =>
      import(
        "@/views/secureRepository/policystandard/PolicyStandardDetail.vue"
      ),
    props: true
  },
  // 规章制度
  {
    path: "/regulations",
    name: "regulations",
    component: () =>
      import("@/views/secureRepository/regulations/Regulations.vue")
  },
  {
    path: "/regulations/detail/:id",
    name: "regulations-detail",
    component: () =>
      import("@/views/secureRepository/regulations/RegulationsDetail.vue"),
    props: true
  }
];
