export default [
  {
    path: "/trouble-dispatch",
    name: "TroubleDispatch",
    component: () =>
      import(
        /* webpackChunkName: "TroubleDispatch" */ "@/views/trouble/TroubleDispatch"
      )
  },
  {
    path: "/trouble",
    name: "Trouble",
    component: () =>
      import(
        /* webpackChunkName: "TroubleFixIndex" */ "@/views/trouble/trouble-fix/index"
      ),
    meta: { roles: ["corrections"] },
    redirect: "/trouble/list",
    children: [
      {
        path: "list",
        name: "trouble-list",
        component: () =>
          import(
            /* webpackChunkName: "trouble-list" */ "@/views/trouble/trouble-fix/troubleFixList.vue"
          )
      },
      {
        path: "add",
        name: "trouble-add",
        meta: { type: "add" },
        component: () =>
          import(
            /* webpackChunkName: "trouble-add" */ "@/views/trouble/trouble-fix/troubleAdd.vue"
          )
      },
      {
        path: "flow",
        name: "trouble-flow",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "trouble-flow" */ "@/views/trouble/trouble-fix/troubleFlow.vue"
          )
      },
      // 隐患整改详情相关
      {
        path: ":id(\\d+)",
        name: "trouble-details",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "trouble-details" */ "@/views/trouble/trouble-fix/troubleScheduling.vue"
          ),
        children: [
          // 隐患审批
          {
            path: "approval",
            name: "trouble-approval",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "trouble-approval" */ "@/views/trouble/trouble-fix/troubleApproval.vue"
              )
          },
          // 隐患接收
          {
            path: "receive",
            name: "trouble-receive",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "trouble-receive" */ "@/views/trouble/trouble-fix/troubleReceive.vue"
              )
          },
          // 隐患整改两个阶段
          {
            path: "modify",
            name: "trouble-modify",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "trouble-modify" */ "@/views/trouble/trouble-fix/troubleModify.vue"
              )
          },
          // 待延期审批
          {
            path: "delay-approval",
            name: "trouble-delay-approval",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "trouble-delay-approval" */ "@/views/trouble/trouble-fix/troubleDelayApproval.vue"
              )
          },
          // 整改验收
          {
            path: "check",
            name: "trouble-check",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "trouble-check" */ "@/views/trouble/trouble-fix/troubleCheck.vue"
              )
          },
          {
            path: "info",
            name: "trouble-info",
            meta: { type: "info" },
            component: () =>
              import(
                /* webpackChunkName: "trouble-info" */ "@/views/trouble/trouble-fix/troubleInfo.vue"
              )
          }
        ]
      }
    ]
  }
];
