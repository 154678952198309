import authorizationApi from "../../api/authorization";
export default {
  state: {
    addressBooks: [],
    addressBooksMaps: {}
  },
  mutations: {
    setAddressBooks(state, data) {
      state.addressBooks = data;
    },
    setAddressBooksMaps(state, data) {
      state.addressBooksMaps = data;
    }
  },
  actions: {
    getAddressBooks({ commit, dispatch }) {
      return authorizationApi.getAuthorizationAddressBooks().then(res => {
        commit("setAddressBooks", res);
        const maps = {};
        res.map(item => {
          maps[item.id] = item;
        });

        commit("setAddressBooksMaps", maps);
        return res;
      });
    }
  }
};
