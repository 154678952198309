import Vue from "vue";
import Sentry from "@sentry/vue";
if (window.globalConfig.VUE_APP_SENTRY === "true") {
  const Sentry = require("@sentry/vue");
  const { Integrations } = require("@sentry/tracing");
  Sentry.init({
    Vue,
    dsn: window.globalConfig.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: window.globalConfig.VUE_APP_SENTRY_ENVIRONMENT,
    tracingOptions: {
      trackComponents: true
    }
  });

  window.sentry = Sentry;

  window.sentry.setExtra("custom_data", {
    isAndroid: !!window.APP?.getPlatform()?.isAndroid
  });
}
