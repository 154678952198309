import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.basics;
const equipment = apiPath.equipment;

export function getPageSelectDevices(params) {
  return axios.get(api + "/page/select/devices", { params });
}

export function getPageSelectDevicesForOrg(params) {
  return axios.get(api + "/page/select/org/devices", { params });
}

/**
 * @description 关联设备下拉数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLinkDeviceSltData = params => {
  return axios.get(equipment + "/list/equipment/manager", { params });
};
