// import AlarmFeedback from "../../views/alarm/alarmFeedback";

export default [
  {
    path: "/alarm-records-dispatch",
    name: "AlarmRecordsDispatch",
    component: () =>
      import(
        /* webpackChunkName: "AlarmRecordsDispatch" */ "@/views/AlarmRecords/AlarmRecordsDispatch.vue"
      )
  },
  {
    path: "/position-alarm",
    name: "PositionAlarms",
    component: () =>
      import(
        /* webpackChunkName: "PositionAlarms" */ "@/views/AlarmRecords/PositionAlarm/PositionAlarmList.vue"
      )
  },
  {
    path: "/position-alarm/:id",
    name: "PositionAlarmDetail",
    component: () =>
      import(
        /* webpackChunkName: "PositionAlarmDetail" */ "@/views/AlarmRecords/PositionAlarm/PositionAlarmDetail.vue"
      )
  },
  {
    path: "/alarm",
    name: "Alarm",
    component: () => import("../../views/alarm/alarm"),
    meta: { cache: true, componentName: "Alarm", roles: ["alarm"] }
  },
  {
    path: "/alarm/:id",
    name: "AlarmDetail",
    meta: { roles: ["alarm"] },
    component: () => import("../../views/alarm/alarmDetail")
  },
  {
    path: "/poison-alarm",
    name: "PoisonAlarm",
    component: () => import("../../views/AlarmRecords/poisonAlarm/poisonAlarm"),
    meta: { cache: true, componentName: "Alarm", roles: ["alarm"] }
  },
  {
    path: "/poison-alarm/:id",
    name: "PoisonAlarmDetail",
    meta: { roles: ["alarm"] },
    component: () => import("../../views/alarm/alarmDetail")
  },
  // {
  //   path: "/alarmFeedBack/:id",
  //   name: "AlarmFeedBack",
  //   meta: { roles: ["alarm"] },
  //   component: AlarmFeedback
  // }
  {
    path: "/alarmdisposallog/:id",
    name: "AlarmDisposalLog",
    meta: { roles: ["alarm"] },
    component: () => import("../../views/alarm/alarmDisposalLog")
  }
];
