export default [
  {
    path: "/analysis",
    name: "analysis",
    component: () =>
      import(/* webpackChunkName: "analysis" */ "@/views/analysis/analysis.vue")
  },
  {
    path: "/analysis/alarm",
    name: "AlarmAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "AlarmAnalysis" */ "@/views/analysis/AlarmAnalysis.vue"
      )
  },
  {
    path: "/analysis/trouble",
    name: "TroubleAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "TroubleAnalysis" */ "@/views/analysis/TroubleAnalysis.vue"
      )
  },
  {
    path: "/analysis/improve",
    name: "ImproveAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "ImproveAnalysis" */ "@/views/analysis/ImproveAnalysis.vue"
      )
  }
];
