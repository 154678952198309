export default [
  {
    path: "/point-check-task",
    name: "pointCheckTask",
    component: () => import("@/views/pointCheckManagement/pointCheckTask"),
    meta: {
      cache: true,
      componentName: "PointCheckTask",
      roles: ["inspect-record"]
    }
  },
  {
    path: "/point-check-task/:id(\\d+)",
    name: "pointCheckTaskDetail",
    component: () =>
      import("@/views/pointCheckManagement/pointCheckTaskDetail"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path:
      "/point-check-task/task-project/:recordId(\\d+)/:pointId(\\d+)/:status(\\d+)/:signType(\\d+)",
    name: "pointCheckTaskProject",
    component: () =>
      import("@/views/pointCheckManagement/pointCheckTaskProject"),
    meta: {
      roles: ["inspect-record"]
    }
  },
  {
    path: "/point-check-task/task-project/history/:id(\\d+)",
    name: "pointCheckTaskProjectHistory",
    component: () =>
      import("@/views/pointCheckManagement/pointCheckTaskProjectHistory"),
    meta: {
      roles: ["inspect-record"]
    }
  }
];
