import axios from "@/utils/axios";

// import {
//   psm as PSM_API,
//   authorization as AUTH_API,
//   basics as API_BASICS,
//   dictionary as DICTIONARY_API,
//   warning as WARNING_API,
//   contractor
// } from "../utils/axios";

import apiPath from "../utils/apiPath";
const PSM_API = apiPath.psm;
const WARNING_API = apiPath.warning;
const AUTH_API = apiPath.authorization;
const API_BASICS = apiPath.basics;
const DICTIONARY_API = apiPath.dictionary;
const contractor = apiPath.contractor;
const camera = apiPath.camera;

/**
 * 获取所有企业字典
 * @param {*} params 不需要参数
 */
export const getAllEnterpriseList = params =>
  axios.get(API_BASICS + "/list/select/org", { params: { ...params } });

/**
 * 组织列表（下拉，查询）
 */
export const querySelectOrgList = () =>
  axios.get(API_BASICS + "/query/select/org");

/**
 * 获取承包商下拉
 * 可传递参数：orgCodeInList: 数组json串
 */
export const getSelectContractorsNames = params =>
  axios.get(contractor + "/select/contractors", { params: { ...params } });

/**
 * 获取承包商下拉-包括简称
 * 可传递参数：orgCodeInList: 数组json串
 */
export const getSltContractorsShortName = params =>
  axios.get(contractor + "/select/contractors", {
    params: { ...params }
  });

/**
 * 查询下拉人员
 * @param {*} params
 */
export const getSelectPersonTypes = params =>
  axios.get(API_BASICS + "/person/list/person/name/select", {
    params: { ...params }
  });

/**
 * 查询下拉人员
 * @param {*} params
 */
// export const getPersonTypes = params =>
//   axios.get(API + "/person/name/select", { params: { ...params } });
export const getPersonTypes = code =>
  axios.get(AUTH_API + "/open/select/users/" + code);

/**
 * 控制风险 等级 下拉字典
 */
export const getControlRiskLevelTypes = () =>
  axios.get(PSM_API + "/dict/select/risk/controls");

/**
 * 控制风险 状态 字典下拉
 */
export const getControlRiskStatusTypes = () =>
  axios.get(PSM_API + "/dict/select/risk/controls/status");

/**
 * 控制风险风险点类型字典表 下拉字典
 */
export const getControlRiskPointTypes = () =>
  axios.get(PSM_API + "/dict/select/risk/point/type");

/**
 * 查询 控制风险基本信息【分页】
 * @param {*} params
 */
export const getControlRiskList = params =>
  axios.get(PSM_API + "/page/risk/controls", { params: { ...params } });

/**
 * 获取可能导致的事故
 */
export const getMaybeAccidentTypes = () =>
  axios.get(PSM_API + "/dict/select/risk/accident");

/**
 * 查询 所属区域 可选下拉
 */
export const getRiskAreaTypes = () =>
  axios.get(PSM_API + "/select/risk/areas/name");

/**
 * 查询 控制风险基本信息表【根据ID】
 * @param {*} id
 */
export const getControlRiskInfoById = id =>
  axios.get(PSM_API + "/info/risk/controls/" + id);

/**
 * L*E*C 表格
 */
export const getRiskTableData_LEC_L = () =>
  axios.get(PSM_API + "/info/risk/controls/table/lec/l");
export const getRiskTableData_LEC_E = () =>
  axios.get(PSM_API + "/info/risk/controls/table/lec/e");
export const getRiskTableData_LEC_C = () =>
  axios.get(PSM_API + "/info/risk/controls/table/lec/c");

/**
 * L*S 表格
 */
export const getRiskTableData_LS_L = () =>
  axios.get(PSM_API + "/info/risk/controls/table/ls/l");
export const getRiskTableData_LS_S = () =>
  axios.get(PSM_API + "/info/risk/controls/table/ls/s");

/**
 * 分页查询设备设施类型
 * @param {*} params
 */
export const getPageSelectEquipment = params =>
  axios.get(API_BASICS + "/page/select/equipment", { params: { ...params } });

/**
 * 作业区域查询
 * @param key 区域的key：
 * tanks=储罐;
 * tankAreas=罐区;
 * warehouse-areas=库区;
 * warehouses=仓库;
 * devices=生产单元;
 * pois=其他设施;
 * @param params
 */
export const getWorkArea = (key, params) =>
  axios.get(API_BASICS + `/page/select/${key}`, { params: { ...params } });

/**
 * 获取作业状态
 */
export const getWorkStatus = () =>
  axios.get(PSM_API + "/list/operation_status");

/**
 * 危险源下拉
 * @param {*} params
 */
export const getDangerSelect = params =>
  axios.get(API_BASICS + "/select/dangers");

/**
 * 获取监测项下拉字典
 * @param {*} params
 */
export const getIndicatorDic = params =>
  axios.get(DICTIONARY_API + "/select/indicator/type/dict");
/**
 * 查询点位列表
 * @param {*} params
 */
export const getIndicatorList = params =>
  axios.get(WARNING_API + "/list/app-sensor", { params });

/**
 * 获取点位详情
 * @param {*}} params
 */
export const getIndicatorDetail = id =>
  axios.get(WARNING_API + `/info/app-sensor/${id}`);

/**
 * 获取监测点位图表
 * @param {*} params
 */
export const getIndicatorEcharts = params =>
  axios.get(`${WARNING_API}/list/indicator/records`, { params });
/**
 * 获取视频列表
 * @param {*} params
 */
export const getVideoList = params =>
  axios.get(`${camera}/app/page/videos`, { params });
/**
 * 获取视频详情
 * @param {*} params
 */
export const getVideoDetail = id => axios.get(`${camera}/app/info/video/${id}`);

/**
 * 上传base64图片
 * @param base64Img
 */
export const uploadBase64Image = base64Img =>
  axios.post(window.globalConfig.VUE_APP_FILE_URL + "/file/upload/image", {
    image: base64Img.replace("data:image/png;base64,", "")
  });

/**
 * 查询关注部门列表
 * @param {*} params
 */
export const getAttentionDeptList = params => {
  return axios.get(`${API_BASICS}/tag-relation/list/tag-dept`, { params });
};
/**
 * 更新关注部门列表
 * @param {*} params
 */
export const updateAttentionDeptList = params => {
  return axios.post(`${API_BASICS}/tag-relation/add`, params);
};

/**
 * 生产装置ID查部门
 * @param facilityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDepartmentByFacilityId = facilityId => {
  return axios.get(
    `${API_BASICS}/info/facilities/dept-code?facilitiesId=${facilityId}`
  );
};
