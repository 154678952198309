// 有些不经过 api 数据，存到内存中，直接取，API 请求的暂时未处理
export default {
  state: {
    // 排查任务详情第四个tab页签，列表数据单个缓存
    inspectDetailsTab4Item: {},
    // 是否刷新列表页面
    refreshList: true
  },
  mutations: {
    // 没有异步操作
    SET_INSPECTDETAILSTAB4ITEM(state, data) {
      state.inspectDetailsTab4Item = data;
    },
    setRefreshList(state, refreshList) {
      state.refreshList = refreshList;
    }
  }
};
