<template>
  <div :label="labelName" :rules="{ required: isRequired }" style="width:100%">
    <div style="width:100%;height:100%;">
      <gis-map
        ref="refTyMap"
        v-bind="$attrs"
        :poi-geo-point="innerPoiGeoPoint"
        :ty-map-init-w-k-t="innerValue"
        :ty-map-edit="editable"
        v-on="$listeners"
        @ty-map-click="mapClick"
      ></gis-map>
    </div>
  </div>
</template>

<script>
import componentsForm from "@/mixins/componentsForm";

export default {
  name: "TyMap",
  mixins: [componentsForm],
  components: {
    GisMap: () => import("@ty-map")
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default: () => {}
    },
    change: {
      type: Function,
      default: function() {}
    },
    label: {
      type: String,
      default: "地图拾点"
    },
    poiGeoPoint: {
      type: String,
      default: ""
    }
  },
  computed: {},
  data: () => ({
    innerValue: "",
    innerPoiGeoPoint: ""
  }),
  methods: {
    mapClick(data) {
      this.innerValue = data.geo;
      this.$emit("input", data.geo);
    },
    clearFeature() {
      this.$refs.refTyMap.clearFeature();
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
    poiGeoPoint(val) {
      this.innerPoiGeoPoint = val;
    }
  },
  created() {
    this.innerValue = this.value;
    this.innerPoiGeoPoint = this.poiGeoPoint;
  }
};
</script>

<style lang="scss">
.el-form-item__label {
  color: #fff;
}
.el-form-item__content {
  // width: 100%;
  height: 86%;
}
</style>
