import axios from "../utils/axios";
// import { authorization as API_AUTHORIZATION } from "../utils/axios";
import apiPath from "../utils/apiPath";
const API_AUTHORIZATION = apiPath.authorization;
const config = apiPath.config;

// 公共部分  部门
/**
 * 参数必传
 * @param {*} orgCode
 */
export function getSelectTreeDept(orgCode) {
  return axios.get(API_AUTHORIZATION + "/select/tree/dept?orgCode=" + orgCode);
}

/**
 * 根据deptCode获取部门级联名称
 * @param {*} deptCode
 */
export function getDeptNameCascadeByDeptCode(deptCode) {
  return axios.get(API_AUTHORIZATION + "/list/parent/name", {
    params: { code: deptCode }
  });
}

export default {
  getSelectTreeDept,
  getUserMenus(params) {
    params.category = "admin";
    return axios.get(API_AUTHORIZATION + "/user/menus", params);
  },
  getPageUsers(params) {
    return axios.get(API_AUTHORIZATION + "/page/users", params);
  },
  // 禁用 用户【更新】
  getUpdateUsersDisableApi(params) {
    return axios.post(
      API_AUTHORIZATION + `/update/users/${params.id}/disable`,
      params
    );
  },
  // 启用 用户【更新】
  getUpdateUsersEnableApi(params) {
    return axios.post(
      API_AUTHORIZATION + `/update/users/${params.id}/enable`,
      params
    );
  },
  // 查询 用户 日志
  getUsersLogApi(params) {
    return axios.get(API_AUTHORIZATION + `/list/users/${params}/log`);
  },
  // 查询 用户状态 下拉
  getSelectUsersStatusApi() {
    return axios.get(API_AUTHORIZATION + "/open/select/users/status");
  },
  getSelectUsersTypes(params) {
    return axios.get(API_AUTHORIZATION + "/open/select/users", {
      params: { ...params }
    });
  },
  getListRoleCurrent(params) {
    return axios.get(API_AUTHORIZATION + "/list/role/current", params);
  },
  getSelectDataRoles(params) {
    return axios.get(API_AUTHORIZATION + "/select/data/roles", params);
  },
  getSelectUserOrgAll(params) {
    return axios.get(API_AUTHORIZATION + "/select/user/org/all", params);
  },
  getInfoUsers(query) {
    return axios.get(API_AUTHORIZATION + "/info/users/" + query);
  },
  getValidateUsers(params) {
    return axios.get(API_AUTHORIZATION + "/validate/users", params);
  },
  getUserTreeDept(params) {
    return axios.get(API_AUTHORIZATION + "/user/tree/dept", params);
  },
  deleteOrg(query) {
    return axios.post(API_AUTHORIZATION + "/delete/org/" + query);
  },
  getUserTreeOrg() {
    return axios.get(API_AUTHORIZATION + "/user/tree/org");
  },
  getInfoSystemsTop() {
    return axios.get(API_AUTHORIZATION + "/info/systems/top");
  },
  getListSystems() {
    return axios.get(API_AUTHORIZATION + "/list/systems");
  },
  addOrg(query) {
    return axios.post(API_AUTHORIZATION + "/add/org", query);
  },
  getPageRoles(params) {
    return axios.get(API_AUTHORIZATION + "/page/roles", params);
  },
  getValidOrganizationName(params) {
    return axios.get(API_AUTHORIZATION + "/validate/org/name", params);
  },
  getPageDataRoles(params) {
    return axios.get(API_AUTHORIZATION + "/page/data/roles", params);
  },
  getInfoRoles(query) {
    return axios.get(API_AUTHORIZATION + "/info/roles/" + query);
  },
  getListDataRolesPrivileges(params) {
    return axios.get(API_AUTHORIZATION + "/list/data/roles/privileges", params);
  },
  getListRoleMenu(params) {
    return axios.get(API_AUTHORIZATION + "/list/role/menu", params);
  },
  getInfoDataRoles(query) {
    return axios.get(API_AUTHORIZATION + "/info/data/roles/" + query);
  },
  getSelectUserOrganization(params) {
    return axios.get(API_AUTHORIZATION + "/select/user/organization", params);
  },
  getAdminMenus() {
    return axios.get(API_AUTHORIZATION + "/admin/menus");
  },
  getAdminTreePrivileges() {
    return axios.get(API_AUTHORIZATION + "/admin/tree/privileges");
  },
  deleteRoles(query) {
    return axios.post(API_AUTHORIZATION + "/delete/roles/" + query);
  },
  getListRolesName(params) {
    return axios.get(API_AUTHORIZATION + "/list/roles/name", params);
  },
  deleteDataRoles(query) {
    return axios.post(API_AUTHORIZATION + "/delete/data/roles/" + query);
  },
  updateSystems(form) {
    return axios.post(API_AUTHORIZATION + `/update/systems/${form.id}`, form);
  },
  addSystems(params) {
    return axios.post(API_AUTHORIZATION + "/add/systems", params);
  },
  deleteSystems(form) {
    return axios.post(API_AUTHORIZATION + `/delete/systems/${form.id}`, form);
  },
  addUserPrivileges(params) {
    return axios.post(API_AUTHORIZATION + "/add/user/privileges", params);
  },
  addUsers(params) {
    return axios.post(API_AUTHORIZATION + "/add/users", params);
  },
  updateUsers(id, params) {
    return axios.post(API_AUTHORIZATION + "/update/users/" + id, params);
  },
  addDataRoles(params) {
    return axios.post(API_AUTHORIZATION + "/add/data/roles", params);
  },
  updateDataRoles(editId, params) {
    return axios.post(
      API_AUTHORIZATION + "/update/data/roles/" + editId,
      params
    );
  },
  addRoles(params) {
    return axios.post(API_AUTHORIZATION + "/add/roles", params);
  },
  updateRoles(editId, params) {
    return axios.post(API_AUTHORIZATION + "/update/roles/" + editId, params);
  },
  updateUsersPasswordDefault(resetId, params) {
    return axios.post(
      API_AUTHORIZATION + "/update/users/password/default/" + resetId,
      params
    );
  },
  addRelationUserRole(params) {
    return axios.post(API_AUTHORIZATION + "/add/relation/user/role", params);
  },
  addDept(params) {
    return axios.post(API_AUTHORIZATION + "/add/dept", params);
  },
  updateOrg(id, params) {
    return axios.post(API_AUTHORIZATION + "/update/org/" + id, params);
  },
  // 获取责任部门
  userTreeDeptSimple() {
    return axios.get(API_AUTHORIZATION + "/user/tree/dept/simple");
  },
  // 获取手机验证码
  getAuthorizationInfoTelVerificationCode(mobile, tenantId) {
    return axios.get(API_AUTHORIZATION + "/public/tel/verification/code", {
      params: {
        mobile,
        tenantId
      }
    });
  },
  // 修改 用户密码【根据手机号与验证码】
  updateAuthorizationUpdateUsersPasswordMobile(form) {
    return axios.post(
      API_AUTHORIZATION + "/public/update/users/password/mobile",
      form
    );
  },
  // 校验 验证码 是否正确【true:正确。false:错误】
  getAuthorizationIValidateTelVerificationCode(params) {
    return axios.get(
      API_AUTHORIZATION + "/public/validate/tel/verification/code",
      {
        params
      }
    );
  },
  // 修改 用户密码【根据登录名和旧密码】
  updateAuthorizationUpdateUsersPasswordName(form) {
    return axios.post(API_AUTHORIZATION + "/update/users/password", form);
  },

  // 查询通讯录
  getAuthorizationAddressBooks(params) {
    return axios.get(API_AUTHORIZATION + "/address-books", {
      params
    });
  },
  /**
   * 查询 某部门普通用户列表【不含子部门用户，无权限过滤】
   * @param {*} deptCode
   */
  getListUsers(deptCode) {
    return axios.get(API_AUTHORIZATION + `/open/list/users/${deptCode}`);
  },
  getUsersByPos(params) {
    return axios.get(API_AUTHORIZATION + `/select/users`, { params });
  },
  // 根据手机号获取用户状态
  getUserStatusByMobile(params) {
    return axios.get(`${API_AUTHORIZATION}/public/query/users/status`, {
      params
    });
  },
  // 根据部门获取子部门
  getSubDepartmentByDepartment(code) {
    return axios.get(API_AUTHORIZATION + `/list/sub/code`, {
      params: { code }
    });
  },
  getTenantList(keyWord) {
    return axios.get(`${API_AUTHORIZATION}/public/list/users/tenant`, {
      params: { keyWord }
    });
  },
  getListProjectByIds(tenantIds) {
    return axios.get(`${config}/public/select/projects-info`, {
      params: { tenantIds }
    });
  },
  getUserFaceStatus(userId) {
    return axios.get(`${API_AUTHORIZATION}/info/user-faces/user-id`, {
      params: { userId }
    });
  }
};
