import success from "@/views/hse/success";
import apiPath from "../../utils/apiPath";
import loginUtil from "@/utils/login";

const has = Object.prototype.hasOwnProperty;
const combine = (a, b) => [].concat(a, b);
function parseSearch(str) {
  const obj = {};
  if (!str) return obj;
  const cleanStr = str.replace(/^\?/, "");
  const parts = cleanStr.split("&");
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i].split("=");
    const key = part[0];
    const val = part[1];
    if (has.call(obj, key)) {
      obj[key] = combine(obj[key], val);
    } else {
      obj[key] = val;
    }
  }
  return obj;
}

function isJSON(str) {
  if (typeof str != "string") return false;
  try {
    const obj = JSON.parse(str);
    return !!(typeof obj == "object" && obj);
  } catch (e) {
    return false;
  }
}

export function syncFetch(url, { success, error, params = {} }) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, false);
  if (params.headers) {
    Object.keys(params.headers).map(key => {
      xhr.setRequestHeader(key, params.headers[key]);
    });
  }
  xhr.onload = function(e) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        let data = {};
        try {
          data = isJSON(xhr.responseText) ? JSON.parse(xhr.responseText) : {};
        } catch (e) {
          console.error(e);
        }
        success(data, xhr);
      } else {
        error(xhr.statusText);
      }
    }
  };

  xhr.onerror = function(e) {
    error(xhr.statusText);
  };

  xhr.send();
}

export function getTenantId() {
  if (loginUtil.isLogin()) {
    return loginUtil.getTenantId();
  }

  // 安卓通过app在URL上通过androidTenantId参数获取，h5通过URL上tenantId参数获取
  const searchObj = parseSearch(location.search);
  const urlTenantId = searchObj?.androidTenantId || searchObj?.tenantId;
  if (urlTenantId) {
    return urlTenantId;
  }

  // 极端情况，兼容APP 未更新增加androidTenantId参数版本，由于APP必登录状态，通过profile接口额外补充tenantId
  if (window.APP?.getPlatform()?.isAndroid) {
    let tenantId = "";
    syncFetch(apiPath.open + "/auth/profile", {
      params: {
        headers: {
          Authorization: `Bearer ${loginUtil.getToken()}`
        }
      },
      success(data) {
        tenantId = data.data.tenantId;
      }
    });
    return tenantId;
  }
  // h5如果没有在URL上拼接tenantId参数，默认是0
  else {
    return "0";
  }
}

export const localEnv = process.env;
export const isProd = localEnv.NODE_ENV === "production";

// const obj = parseSearch("?androidTenantId=0&a=b&c=d&a=2&tenantId=0");
// console.log(obj, "obj");
