export default [
  {
    // 技术参数
    path: "/equipmentRxLedger/equipmentTechParams/:id?",
    name: "equipmentTechParams",
    component: () => import("@/views/equipmentRx/equipmentTechParams.vue"),
    meta: {
      cache: true,
      componentName: "equipmentTechParams"
    }
  },
  {
    // 设备文档
    path: "/equipmentRxLedger/equipmentDoc/:id?",
    name: "equipmentDoc",
    component: () => import("@/views/equipmentRx/equipmentDoc.vue"),
    meta: {
      cache: true,
      componentName: "equipmentDoc"
    }
  },
  {
    // 设备文档详情
    path: "/equipmentRxLedger/equipmentDocDetail",
    name: "equipmentDocDetail",
    component: () => import("@/views/equipmentRx/equipmentDocDetail.vue"),
    meta: {
      cache: true,
      componentName: "equipmentDocDetail"
    }
  },
  {
    // 安全附件
    path: "/equipmentRxLedger/equipmentSafetyAffix/:id?",
    name: "equipmentSafetyAffix",
    component: () => import("@/views/equipmentRx/equipmentSafetyAffix.vue"),
    meta: {
      cache: true,
      componentName: "equipmentSafetyAffix"
    }
  },
  {
    // 关联辅件
    path: "/equipmentRxLedger/equipmentLinkAccessory/:id?",
    name: "equipmentLinkAccessory",
    component: () => import("@/views/equipmentRx/equipmentLinkAccessory.vue"),
    meta: {
      cache: true,
      componentName: "equipmentLinkAccessory"
    }
  },
  {
    // 备品备件
    path: "/equipmentRxLedger/equipmentParts/:id?",
    name: "equipmentParts",
    component: () => import("@/views/equipmentRx/equipmentParts.vue"),
    meta: {
      cache: true,
      componentName: "equipmentParts"
    }
  },
  {
    // 检验周期
    path: "/equipmentRxLedger/equipmentOverhaulCycle/:id?",
    name: "equipmentOverhaulCycle",
    component: () => import("@/views/equipmentRx/equipmentOverhaulCycle.vue"),
    meta: {
      cache: true,
      componentName: "equipmentOverhaulCycle"
    }
  },
  {
    // 检验周期详情
    path: "/equipmentRxLedger/equipmentOverhaulCycleDetail",
    name: "equipmentOverhaulCycleDetail",
    component: () =>
      import("@/views/equipmentRx/equipmentOverhaulCycleDetail.vue"),
    meta: {
      cache: true,
      componentName: "equipmentOverhaulCycleDetail"
    }
  },
  {
    // 润滑保养周期
    path: "/equipmentRxLedger/equipmentMaintainPlan/:id?",
    name: "equipmentMaintainPlan",
    component: () => import("@/views/equipmentRx/equipmentMaintainPlan.vue"),
    meta: {
      cache: true,
      componentName: "equipmentMaintainPlan"
    }
  },
  {
    // 润滑保养周期详情
    path: "/equipmentRxLedger/equipmentMaintainPlanDetail",
    name: "equipmentMaintainPlanDetail",
    component: () =>
      import("@/views/equipmentRx/equipmentMaintainPlanDetail.vue"),
    meta: {
      cache: true,
      componentName: "equipmentMaintainPlanDetail"
    }
  },
  {
    // 检定周期
    path: "/equipmentRxLedger/equipmentVerificationPlan/:id?",
    name: "equipmentVerificationPlan",
    component: () =>
      import("@/views/equipmentRx/equipmentVerificationPlan.vue"),
    meta: {
      cache: true,
      componentName: "equipmentVerificationPlan"
    }
  },
  {
    // 检定周期详情
    path: "/equipmentRxLedger/equipmentVerificationPlanDetail",
    name: "equipmentVerificationPlanDetail",
    component: () =>
      import("@/views/equipmentRx/equipmentVerificationPlanDetail.vue"),
    meta: {
      cache: true,
      componentName: "equipmentVerificationPlanDetail"
    }
  },
  {
    // 巡检检测周期
    path: "/equipmentRxLedger/equipmentInspectionPlan/:id?",
    name: "equipmentInspectionPlan",
    component: () => import("@/views/equipmentRx/equipmentInspectionPlan.vue"),
    meta: {
      cache: true,
      componentName: "equipmentInspectionPlan"
    }
  },
  {
    // 巡检检测周期详情
    path: "/equipmentRxLedger/equipmentInspectionPlanDetail",
    name: "equipmentInspectionPlanDetail",
    component: () =>
      import("@/views/equipmentRx/equipmentInspectionPlanDetail.vue"),
    meta: {
      cache: true,
      componentName: "equipmentInspectionPlanDetail"
    }
  }
];
