import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

export function getPageSelectPois(params) {
  return axios.get(api + "/page/select/pois", { params });
}

export function getPageSelectPoisForOrg(params) {
  return axios.get(api + "/page/select/org/pois", { params });
}
