<template>
  <div class="view-home">
    <van-nav-bar :title="title">
      <template #left>
        <van-icon
          class="home-user-icon"
          class-prefix="iconfont"
          name="yonghu"
          @click="userHandle"
        />
      </template>
      <template #right>
        <van-icon
          v-if="isAndroid"
          class="home-message-icon"
          class-prefix="iconfont"
          name="saoyisao"
          @click="onScanQrCode"
        />
        <van-icon
          class="home-message-icon"
          class-prefix="iconfont"
          name="ico-mess"
          :badge="messageCount"
          @click="messageHandle"
        />
      </template>
    </van-nav-bar>
    <van-notice-bar
      v-if="anList.length"
      :scrollable="false"
      mode="link"
      class="home-notice-bar"
    >
      <template #left-icon>
        <i
          style="color: #FFAE13; margin-right: 8px"
          class="iconfont iconfont-tongzhi"
        />
      </template>
      <template #right-icon>
        <i style="color: #D8D8D8" class="iconfont iconfont-gengduo1" />
      </template>
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="item in anList"
          :key="item.id"
          class="text-overflow home-notice-bar-text"
          @click="onNotionClick(item)"
          >【{{ item.utime.slice(5, 10) }}】{{ item.name }}</van-swipe-item
        >
      </van-swipe>
    </van-notice-bar>
    <div class="home-container">
      <van-swipe :loop="false" class="home-swipe">
        <van-swipe-item v-for="(swipeItem, idx) in entriesList" :key="idx">
          <div class="view-home-btns">
            <div
              v-for="(item, index) in swipeItem"
              :key="index"
              class="view-home-btn"
              @click="entryHandle(item)"
            >
              <span
                class="view-home-btn__bg--image"
                :style="{
                  'background-image': `url(${require(`../assets/images/${item.icon}.png`)})`
                }"
              ></span>
              <span class="view-home-btn__title">{{ item.name }}</span>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <van-row
        type="flex"
        justify="space-between"
        class="block-space-left-right"
        style="margin-bottom: 10px;"
      >
        <h2 class="view-home-list-title">
          <span>我的待办</span>
          <i v-if="taskData.total">({{ taskData.total }})</i>
        </h2>
        <div>
          <van-icon
            class-prefix="iconfont"
            name="guolv"
            class="filter-btn"
            @click="filterHandle"
          >
          </van-icon>
          <router-link class="link-text" :to="{ name: 'userTodoList' }"
            >全部</router-link
          >
        </div>
      </van-row>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        class="block-space-left-right user-todo-list__list"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <user-todo-list-item
          v-for="item in taskData.list"
          :key="item.id"
          :data="item"
        />
      </van-list>
    </div>
    <div class="home-add-btn" @click.stop="addTroubleHandle">
      <van-icon name="plus" />
    </div>
    <van-popup
      v-model="filterPopupStatus"
      position="bottom"
      class="filter-popup"
    >
      <div class="popup-content">
        <div>重要程度</div>
        <van-radio-group v-model="filter.degree" direction="horizontal">
          <van-radio
            v-for="item in degreeList"
            :key="item.value"
            :name="item.value"
            style="width:26%"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
        <div>待办类型</div>
        <van-radio-group v-model="filter.type" direction="horizontal">
          <van-radio
            v-for="item in typeList"
            :key="item.value"
            :name="item.value"
            style="width:26%"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
        <div>创建时间</div>
        <div>
          <date-time v-model="filterDate" type="date"></date-time>
        </div>
        <div class="not-sticky">业务类型</div>
        <van-radio-group v-model="filter.moduleId" direction="horizontal">
          <van-radio
            v-for="item in moduleList"
            :key="item.value"
            :name="item.value"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
      </div>
      <div class="popup-footer">
        <van-button type="danger" class="reset-btn" @click="resetHandle">
          重置
        </van-button>
        <van-button type="info" class="handle-btn" @click="submitHandle">
          确定
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import messageApi from "../api/message";
import UserTodoListItem from "./user/UserTodoListItem";
import { mapActions, mapState } from "vuex";
import { homeMenus } from "@/utils/constant";
import { arrayGroupBySize } from "@/utils";
import { Dialog } from "vant";
import dayjs from "dayjs";
import { getPageNotices, hseScan } from "@/api/psm";

export default {
  name: "Home",
  components: {
    UserTodoListItem
  },
  data() {
    return {
      homeMenus,
      loading: false,
      finished: false,
      loadError: false,
      taskData: {
        list: [],
        total: 0
      },
      page: 1,
      size: 10,
      filterPopupStatus: false,
      filter: {},
      filterDate: {
        start: "",
        end: ""
      },
      degreeList: [],
      typeList: [],
      moduleList: [],

      anQuery: {
        size: 3,
        page: 1,
        status: 1,
        publishScope: "",
        orgCode: "",
        needPermission: "1"
      },
      anList: []
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles,
      unreadCount: state => state.message.unreadCount,
      definedMenus: state => state.menu.definedMenus
    }),
    entriesList() {
      // 全量有权限菜单
      let arr = JSON.parse(JSON.stringify(this.authRoutes));
      const filterMenus = homeMenus.filter(item => {
        // 瑞泰定制
        // let isSpecial = item.special;

        // 配置了菜单权限
        let hasAuth = Array.isArray(item.auth)
          ? arr.some(v => item.auth.includes(v))
          : arr.includes(item.auth);

        // 不受菜单权限控制
        let notControlledByPermission = !item.auth;

        // 展示ICON入口
        let showIconEntry = item.show;

        // 判断是否需要业务权限控制 businessAuth
        // 如果是自定义菜单
        if (item.businessAuth === "independent-menu") {
          // 判断是否取到了菜单, 如果有菜单, 则显示入口
          showIconEntry = this.definedMenus.length > 0;
        }
        return showIconEntry && (notControlledByPermission || hasAuth);
      });
      console.log(
        arrayGroupBySize(filterMenus, 8),
        "arrayGroupBySize(filterMenus, 8)"
      );
      return arrayGroupBySize(filterMenus, 8);
    },
    messageCount() {
      const count = this?.unreadCount || "";
      return count > 99 ? "99+" : count;
    },
    isAndroid() {
      return window.APP.getPlatform().isAndroid;
    },
    title() {
      return document.title;
    }
  },
  methods: {
    ...mapActions(["getMessageCountNoticesAction"]),
    // ...mapMutations({
    //   setRedirectURL: "setRedirectURL"
    // }),
    filterHandle() {
      this.filterPopupStatus = true;
    },
    resetHandle() {
      this.filter = {};
      this.filterDate = {};
    },
    submitHandle() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.getTasks();
      this.filterPopupStatus = false;
    },
    initDegrees() {
      messageApi.getMessageDegrees().then(res => {
        this.degreeList = res;
      });
    },
    initTypeList() {
      messageApi.getMessageTaskTypes().then(res => {
        this.typeList = res;
      });
    },
    initModuleList() {
      const params = {
        type: 1
      };
      messageApi.getMessageModulesByType(params).then(res => {
        this.moduleList = res;
      });
    },
    async getAnList() {
      const { department, orgCode } = this.userInfo;
      this.anQuery.publishScope = department;
      this.anQuery.orgCode = orgCode;
      const an = await getPageNotices(this.anQuery);
      this.anList = an?.list || [];
    },
    onNotionClick({ id }) {
      this.$router.push({ name: "AnnouncementDetails", params: { id } });
    },
    processQrCodeForOther(data) {},
    processQrCodeForContractor({ data: id }) {
      this.$router.push({ name: "ContractorPerson", params: { id } });
    },
    processQrCode(data) {
      // 1 承包商人员, 2 巡检, 3 设备台账, 4 HSE积分
      if (+data.type === 1) {
        this.processQrCodeForContractor(data);
      } else if (data.type == 3) {
        // 说明扫描的是设备二维码
        this.$router.push({
          name: "equipmentledger",
          params: {
            id: data.data
          }
        });
      } else if (data.type == 4) {
        // 说明扫描的是HSE积分二维码
        let parm = {
          pointId: data.data,
          userId: this.userInfo.id
        };
        hseScan(parm).then(res => {
          if (res) {
            if (res.type == 1) {
              // 积分成功
              this.$router.push({
                name: "hseSuccess",
                params: {
                  data: res
                }
              });
            } else if (res.type == 3) {
              // 重复扫码
              this.$router.push({
                name: "hseRepeat",
                params: {
                  data: res
                }
              });
            } else {
              // 不在积分范围内
              this.$router.push({
                name: "hseError",
                params: {
                  data: res
                }
              });
            }
          }
        });
      } else {
        // 其他类型二维码做相应处理
        this.processQrCodeForOther(data);
      }
    },
    onScanQrCode() {
      window.androidH5.scanQrCode("QRCodeCallback", "0");
      window.QRCodeCallback = code => {
        try {
          const ret = JSON.parse(code);
          const data = typeof ret !== "number" ? ret : null;
          if (!data) {
            return Dialog.alert({
              message: "暂不支持该二维码的识别"
            }).then(() => {});
          }
          const expireAt = data.expireAt;
          if (
            expireAt === null ||
            (expireAt &&
              dayjs(expireAt).isValid() &&
              dayjs().isBefore(expireAt))
          ) {
            this.processQrCode(data);
          } else {
            Dialog.alert({ message: "此二维码已过期" }).then(() => {});
          }
        } catch (e) {
          console.log(e);
          Dialog.alert({ message: "暂不支持该二维码的识别" }).then(() => {});
        }
      };
    },
    onLoad() {
      this.getTasks();
    },
    entryHandle(item) {
      this.$router.push({ path: item.route });
    },
    messageHandle() {
      this.$router.push({ path: "/user/message" });
    },
    userHandle() {
      this.$router.push({ path: "/user/index" });
    },
    getTasks() {
      messageApi
        .getMessagePageTasks({
          page: this.page,
          size: this.size,
          taskStatus: 0,
          sort: "createTime",
          ...this.filter,
          startDate: this.filterDate.start,
          endDate: this.filterDate.end
        })
        .then(res => {
          if (this.page === 1) {
            this.taskData.list = [];
            this.taskData.total = 0;
          }
          this.loading = false;
          this.taskData.total = res.total;
          if (res.list && res.list.length) {
            res.list = this.dateSwitch(res.list);
            this.taskData.list =
              this.page === 1 ? res.list : this.taskData.list.concat(res.list);
            this.page++;
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = false;
          this.loadError = true;
        });
    },
    dateSwitch(data) {
      return (data || []).map(i => {
        if (i.moduleName === "PSI管理") {
          i.title = `${i.title}请登录电脑端处理。`;
        }
        return i;
      });
    },
    async addTroubleHandle() {
      await import(`@/views/trouble/utils/constant`);
      await this.$router.push({ path: "/trouble/add", query: { home: true } });
    }
  },
  created() {
    // this.setRedirectURL("");
    // const redirectURL = window.sessionStorage.getItem("redirectURL");
    // this.$router.push(redirectURL);
    // window.sessionStorage.removeItem("redirectURL");
    this.getMessageCountNoticesAction();
    this.initDegrees();
    this.initTypeList();
    this.initModuleList();
    this.getAnList();
  }
};
</script>
<style lang="scss">
.view-home {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .notice-swipe {
    height: 26px;
  }
  .home-notice-bar {
    margin: 0 20px;
    background: rgba(255, 174, 19, 0.1);
    border-radius: 15px;
    height: 26px;
    &-text {
      font-size: 12px;
      color: #3b4664;
      line-height: 26px;
    }
  }
  .home-container {
    margin-top: 20px;
    flex: 1;
    overflow-y: auto;
    .home-swipe {
      .van-swipe__indicators {
        .van-swipe__indicator {
          width: 20px;
          height: 2px;
          border-radius: 2px;
          &:not(:last-child) {
            margin-right: 2px;
          }
        }
      }
    }
  }
  .home-user-icon {
    font-size: 28px;
  }
  .home-message-icon {
    position: relative;
    height: auto;
    line-height: 1;
    font-size: 20px;
    color: #3394ff;
    margin-left: 8px;
    .van-info {
      right: 2px;
      top: 1px;
      background-color: $--badge-background-color;
      font-size: $--badge-font-size;
    }
  }
  .van-nav-bar {
    background: #fff;
    .van-nav-bar__left,
    .van-nav-bar__right {
      color: $--color-icon;
      .iconfont-ico-mess {
        opacity: 0.9;
      }
    }
    .van-nav-bar__title {
      color: #1b1b4e;
    }
  }
  .view-home-entries {
    > div {
      width: 76px;
      height: 76px;
      box-shadow: 0px 5px 16px 0px rgba(27, 27, 78, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      /*justify-content: space-evenly;*/
      align-items: center;
      flex-direction: column;
      margin-bottom: 14px;
      font-size: 14px;
      color: #fff;
      &:nth-child(1) {
        background-color: #4789e9;
        box-shadow: 0px 5px 16px 0px rgba(71, 137, 233, 0.2);
        i {
          font-size: 30px;
        }
      }
      &:nth-child(2),
      &:nth-child(5) {
        background-color: #7d70e1;
        box-shadow: 0px 5px 16px 0px rgba(125, 112, 225, 0.2);
        i {
          font-size: 28px;
        }
      }
      &:nth-child(3) {
        background-color: #6a7def;
        box-shadow: 0px 5px 16px 0px rgba(106, 125, 239, 0.2);
        i {
          font-size: 26px;
        }
      }
      &:nth-child(4) {
        background-color: #2f95e3;
        box-shadow: 0px 5px 16px 0px rgba(47, 149, 227, 0.2);
        i {
          font-size: 26px;
        }
      }
      &:nth-child(5) {
        background-color: #2f95e3;
        box-shadow: 0px 5px 16px 0px rgba(47, 149, 227, 0.2);
        i {
          font-size: 26px;
        }
      }
    }
    i {
      margin-bottom: 12px;
    }
  }
  .view-home-list-title {
    color: #aeb3c0;
  }
  .filter-btn {
    margin: 0 10px 0 0;
    color: $--color-primary;
    font-size: 16px;
  }
  .link-text {
    color: $--color-link;
  }
  .home-add-btn {
    position: absolute;
    width: 68px;
    height: 68px;
    bottom: 40px;
    left: 50%;
    transform: translateX(-34px);
    background: #1e78ff;
    color: #fff;
    border-radius: 100%;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 8px #ccc;
  }
  .user-todo-list__list {
    height: calc(100vh - 58px - 60px - 72px - 72px - 40px);
    overflow: auto;
  }
  // 首页按钮样式
  &-btns {
    // 完美解决不局中问题
    margin: 0 10px;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-btn {
    width: 25%;
    // width: 60px;
    height: 72px;
    // margin-right: 25px;
    margin-top: 20px;
    text-align: center;
    font-size: 0;
    span {
      display: inline-block;
      text-align: center;
    }
    &__bg--image {
      margin: auto;
      width: 52px;
      height: 52px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 8px;
    }
    &__title {
      width: 60px;
      font-size: 12px;
      color: #3b4664;
      line-height: 12px;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}
</style>
