import { Toast } from "vant";
Toast.allowMultiple();

export const ToastNetworkError = err => {
  if (err) {
    console.error(err);
  }
  Toast({
    message: "网络异常，请检查网络后重试！",
    position: "top"
  });
};

window.addEventListener("offline", function(e) {
  ToastNetworkError(e);
});

// 安卓端应用拉起事件 监测心跳检测接口是否联通
window.onResume = () => {
  heartbeat();
};

// 用来处理5秒延迟 axios全局配置了Authorization 会导致axios请求跨域 故使用fetch
const heartbeatFetch = (url, data = {}, timeout = 5000) => {
  let timeoutAction = null;
  const timerPromise = new Promise((resolve, reject) => {
    timeoutAction = () => {
      const msg = "请求超时";
      reject(msg);
      window.sentry?.captureException({
        url: data.url,
        data: data.data,
        params: data.params,
        timeout,
        msg
      });
    };
  });
  setTimeout(() => {
    timeoutAction();
  }, timeout);
  return Promise.race([fetch(url), timerPromise]);
};

/**
 * 心跳检测
 */
export const heartbeat = config => {
  const url =
    (window.globalConfig.VUE_APP_LOCATION_PATH || window.location.origin) +
    "/heartbeat";
  heartbeatFetch(url, config).catch(() => {
    ToastNetworkError();
  });
};
