export default {
  state: {
    routerHistory: [],
    cacheView: []
  },
  mutations: {
    addRouterHistory(state, data) {
      state.routerHistory.push(data);
      if (data.meta && data.meta.cache) {
        const has = state.cacheView.find(a => a === data.componentName);
        if (!has) {
          state.cacheView.push(data.meta.componentName);
        }
      }
    },
    clearRouterHistory(state, data) {
      state.routerHistory = [];
      // 清空缓存路由
      state.cacheView = [];
    },
    removeRouterHistory(state, index) {
      const route = state.routerHistory[index];
      if (route) {
        // 移除缓存路由
        const matchView = state.cacheView.filter(
          a => a !== route.meta.componentName
        );
        state.cacheView = matchView;
        state.routerHistory.splice(index, 1);
      }
    },
    addCacheView(state, data) {
      const has = state.cacheView.find(a => a === data.componentName);
      if (!has) {
        state.cacheView.push(data.componentName);
      }
    },
    removeCacheView(state, data) {
      const matchView = state.cacheView.filter(a => a !== data.componentName);
      state.cacheView = matchView;
    },
    clearCacheView(state) {
      state.cacheView = [];
    }
  }
};
