import authorization from "../../api/authorization";
import { treeForEach } from "@/utils";
import { routeMapForBtn } from "@/utils/constant";
import { asyncRoutes, constantRoutes } from "@/router/index";
import { filterModuleRoutes } from "@/utils";
import { getDefinedMenus } from "@/api/psm";

function sliceRoute(path) {
  const indexOf = path.indexOf("/");
  const indexOfForTwo = path.indexOf("/", 1);
  const indexOfForQuery = path.indexOf("?");
  let lastIndex;
  if (indexOfForTwo === -1) {
    if (indexOfForQuery !== -1) {
      lastIndex = indexOfForQuery;
    } else {
      lastIndex = path.length + 1;
    }
  } else {
    lastIndex = indexOfForTwo;
  }
  return path.slice(indexOf + 1, lastIndex);
}

export default {
  state: {
    debug: true,
    menus: null,
    secondMenu: [],
    parentMenus: [],
    roles: [],
    routes: [],
    dynamicRoutes: [],
    btnAuthMap: {},
    btnAuth: {},
    definedMenus: []
  },
  mutations: {
    setDefinedMenus(state, list) {
      state.definedMenus = list;
    },
    setMenus(state, list) {
      state.menus = list;
    },
    setSecondMenu(state, data) {
      state.secondMenu = data;
    },
    setParentMenus(state, data) {
      state.parentMenus = data;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setBtnMap(state, btnAuthMap) {
      state.btnAuthMap = btnAuthMap;
    },
    setRoutes(state, routes) {
      state.routes = constantRoutes.concat(routes);
      state.dynamicRoutes = routes;
    },
    setBtnAuth(state, path) {
      const routePath = sliceRoute(path);
      state.btnAuth = state.btnAuthMap[routePath] || {};
      // console.log(
      //   `%c Set %c ${routePath}`,
      //   "background: #606060; color: white; padding: 1px 8px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;",
      //   "background: #1475b2; color: white; padding: 1px 8px; border-top-right-radius: 3px;border-bottom-right-radius: 3px;",
      //   JSON.stringify(state.btnAuth)
      // );
    }
  },
  actions: {
    getMenus({ commit }) {
      return authorization
        .getUserMenus({
          params: {
            // type: 2
          }
        })
        .then(data => {
          commit("setMenus", data);
          const roles = new Set();
          const btnAuthMap = {};
          treeForEach(data, menu => {
            if (menu.router) {
              roles.add(menu.router);
              const alias = routeMapForBtn[menu.router];
              if (alias) {
                btnAuthMap[alias] = menu.buttons || {};
              }
              btnAuthMap[menu.router] = menu.buttons || {};
            }
          });
          commit("setRoles", Array.from(roles));
          commit("setBtnMap", btnAuthMap);
        });
    },
    // 获取自定义菜单
    getDefinedMenus({ commit }) {
      return getDefinedMenus().then(res => {
        if (res) {
          // 只需要独立菜单模块
          const list = res.filter(a => a.dataType === 0);
          commit("setDefinedMenus", list);
        }
      });
    },
    /**
     * 登录成功注册路由
     * @param {*} param0
     * @param {String} toPath：要跳转的页面路由地址}
     */
    async registryRoute({ dispatch }, toPath) {
      //获取项目上下文属性对象
      // let context = this.state.context;
      //获取路由实例
      // let $router = context.app.$router;
      //获取静态路由
      // let staticRoute = context.staticRoute || [];
      //定义动态路由
      // let dynamicRoute;
      //初始化菜单
      await dispatch("getMenus");
      //解析动态路由（依据菜单信息，生成有效路由）
      // dynamicRoute = context.dynamicRoute
      //   ? [getAppRoute(state.menus, context.dynamicRoute)]
      //   : [];
      //添加路由
      // $router.addRoutes([...staticRoute, ...dynamicRoute, ...route404]);
      if (toPath) {
        //设置页面具有的操作权限
        dispatch("setOperate", toPath);
        //跳转目标路由
        // $router.push(toPath);
      }
    },
    generateRoutes({ commit }, roles) {
      const accessedRoutes = filterModuleRoutes(asyncRoutes, roles);
      commit("setRoutes", accessedRoutes);
    }
  }
};
