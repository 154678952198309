import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.needRefreshList,
      globalModuleFeatureConfigs: state =>
        state.globalPlatformConfig.globalModuleFeatureConfigs
    }),
    _orgCode() {
      return this.userInfo.orgCode;
    }
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    _setNeedRefreshList(status) {
      this.setNeedRefreshList(status);
    },
    _getIdGlobalModuleFeatureConfig(id) {
      let obj = this.globalModuleFeatureConfigs.find(v => v.id === id) || {};
      return obj?.itemValue || "";
    }
  }
};
