export default {
  state: {
    needRefreshList: false
  },
  mutations: {
    setNeedRefreshList(state, data) {
      state.needRefreshList = data.value;
    }
  }
};
