export default [
  {
    path: "/videoalarm",
    name: "VideoAlarmList",
    component: () => import("@/views/VideoAlarm/VideoAlarmList"),
    meta: {
      cache: true,
      componentName: "VideoAlarmList",
      roles: ["videoalarm"]
    }
  },
  {
    path: "/videoalarm/:id(\\d+)",
    name: "VideoAlarmDetail",
    props: true,
    component: () => import("@/views/VideoAlarm/VideoAlarmDetail")
    // meta: { cache: true, componentName: "VideoAlarmDetail" }
  }
];
