export default [
  {
    path: "/workticket/home",
    name: "WorkticketHome",
    component: () => import("@/views/workticket/WorkticketHome"),
    meta: {
      cache: true,
      componentName: "WorkticketHome",
      roles: ["workticket"]
    }
  },
  {
    //作业许可查询
    path: "/workticket",
    name: "Workticket",
    component: () => import("@/views/workticket/Workticket"),
    meta: { cache: true, componentName: "Workticket" }
  },
  {
    //我的作业许可
    path: "/workticket/mine",
    name: "workticketMine",
    component: () => import("@/views/workticket/WorkticketMine")
  },
  {
    // 新增作业许可
    path: "/workticket/add/:id(\\d+)?",
    name: "workticketAdd",
    component: () => import("@/views/workticket/WorkticketAdd"),
    meta: { cache: true, componentName: "WorkticketAdd" }
  },
  {
    //作业流程
    path: "/workticket/process/:id(\\d+)",
    name: "workticketProcess",
    component: () => import("@/views/workticket/WorkticketProcess")
  },
  {
    //作业详情
    path: "/workticket/:id(\\d+)",
    name: "worktickeDetail",
    component: () => import("@/views/workticket/WorkticketDetail"),
    props: true
  },
  {
    //气体检测列表
    path: "/workticket/checkgas",
    name: "WorkticketCheckGas",
    component: () => import("@/views/workticket/WorkticketCheckGas"),
    meta: { cache: true, componentName: "WorkticketCheckGas" }
  },
  {
    //气体检测详情
    path: "/workticket/checkgas/:id(\\d+)",
    name: "workticketCheckGasDetail",
    component: () => import("@/views/workticket/WorkticketCheckGasDetail")
  },
  {
    //添加气体检测
    path: "/workticket/checkgasAdd/",
    name: "workticketCheckGasAdd",
    component: () => import("@/views/workticket/WorkticketCheckGasAdd")
  },
  {
    //气体检测记录
    path: "/workticket/checkgasRecord/:id(\\d+)",
    name: "workticketCheckGasRecord",
    component: () => import("@/views/workticket/WorkticketCheckGasRecord")
  },
  {
    //统计分析不做权限处理
    path: "/workticket/analysis",
    name: "WorkTicketAnalysis",
    component: () => import("@/views/workticket/WorkticketAnalysis")
  },
  {
    //人员变更
    path: "/workticket/person-change/:id(\\d+)",
    name: "workticketPersonChange",
    component: () => import("@/views/workticket/components/PersonChange")
  },
  {
    // 安全交底措施签字
    path: "/workticket/disclosure-sign/:id(\\d+)",
    name: "workticketDisclosureSign",
    component: () => import("@/views/workticket/WorkticketDisclosureSign")
  },
  {
    path: "/workbeforesafetyanalyze",
    name: "workBeforeSafetyAnalyze",
    meta: { cache: true, componentName: "WorkBeforeSafetyAnalyze" },
    component: () =>
      import(
        "@/views/workticket/workBeforeSafetyAnalyze/workBeforeSafetyAnalyze"
      )
  },
  {
    path: "/workbeforesafetyanalyze/:type/:id?",
    name: "workBeforeSafetyAnalyzeEdit",
    meta: { cache: false, componentName: "WorkBeforeSafetyAnalyzeEdit" },
    component: () =>
      import(
        "@/views/workticket/workBeforeSafetyAnalyze/workBeforeSafetyAnalyzeEdit"
      )
  },
  {
    path: "/workTempSelect",
    name: "workTempSelect",
    component: () =>
      import("@/views/workticket/workBeforeSafetyAnalyze/workTempSelect")
  },
  {
    path: "/WBSATempSelect",
    name: "WBSATempSelect",
    component: () => import("@/views/workticket/WBSATempSelect")
  },
  {
    // 作业前安全分析记录
    path: "/workBeforeProcess/:id(\\d+)",
    name: "workBeforeProcess",
    component: () =>
      import("@/views/workticket/workBeforeSafetyAnalyze/workBeforeProcess")
  },
  {
    // 受限空间票，工器具人员进出页面
    path:
      "/workticket/discrepancyRecord/:match(\\d+)/:ticketId(\\d+)/:specialTicketId(\\d+)",
    name: "discrepancyRecord",
    component: () => import("@/views/workticket/components/discrepancyRecord")
  },
  {
    // 作业前安全分析记录
    path: "/workUnusualWarning/:id(\\d+)",
    name: "workUnusualWarning",
    component: () => import("@/views/workticket/unusualWarning"),
    meta: {
      // cache: true,
      componentName: "workUnusualWarning"
    }
  }
];
