export default [
  {
    path: "/judgepromise/list",
    name: "PromiseList",
    meta: {
      roles: ["judgepromise"],
      cache: true,
      componentName: "PromiseList"
    },
    component: () =>
      import(
        /* webpackChunkName: "PromiseList" */ "@/views/promise/promiseList.vue"
      )
  },
  {
    path: "/judgepromise/:id(\\d+)",
    name: "PromiseDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PromiseDetails" */ "@/views/promise/promiseDetails"
      )
  },
  {
    path: "/judgepromise/:id(\\d+)/add",
    name: "PromiseFill",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PromiseFill" */ "@/views/promise/promiseFill"
      )
  },
  {
    path: `/judgepromise/temp`,
    name: "JudgePromise",
    props: route => ({ id: route.query.id }),
    component: () =>
      import(
        /* webpackChunkName: "JudgePromise" */ "@/views/promise/PromiseTemp"
      )
  }
];
