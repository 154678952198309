import router from "../../router";
import messageApi from "../../api/message";
export default {
  state: {
    unreadCount: 0
  },
  mutations: {
    setUnreadCount(state, data) {
      state.unreadCount = data;
    }
  },
  actions: {
    getMessageCountNoticesAction({ commit, dispatch, rootState }) {
      return messageApi
        .getMessageCountNotices({
          status: 0,
          platform: 1,
          receiver: rootState.login.userInfo.id
        })
        .then(res => {
          // 设置角标
          window.androidH5.resetBadgeCount();
          commit("setUnreadCount", res);
        });
    },
    redirectMessageRouteAction({ commit }, data) {
      // 跳详情页 id是唯一主键，type：0是消息，1是代办事项
      if (data.type === "0") {
        return messageApi.getMessageNoticesById(data.id).then(res => {
          router.push({ path: res.uri });
        });
      }
      if (data.type === "1") {
        return messageApi.getMessageTasksById(data.id).then(res => {
          router.push({ path: res.uri + "?messageId=" + data.id });
        });
      }
    }
  }
};
