import {
  Button,
  NavBar,
  Cell,
  CellGroup,
  List,
  Tab,
  Tabs,
  Search,
  DropdownMenu,
  DropdownItem,
  Dialog,
  ActionSheet,
  Popup,
  Icon,
  Empty,
  Step,
  Steps,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  Field,
  DatetimePicker,
  Checkbox,
  CheckboxGroup,
  Uploader,
  Loading,
  Skeleton,
  Overlay,
  SwipeCell,
  Form,
  Image,
  PullRefresh,
  Tag,
  Stepper,
  Row,
  Col,
  Swipe,
  SwipeItem,
  Pagination,
  Toast,
  Divider,
  Popover,
  TreeSelect,
  NoticeBar,
  Calendar,
  ImagePreview,
  CountDown,
  Switch,
  Cascader
} from "vant";

export default Vue => {
  Vue.prototype.$toast = Toast;
  Vue.use(Button)
    .use(Field)
    .use(Radio)
    .use(RadioGroup)
    .use(Uploader)
    .use(NavBar)
    .use(Icon)
    .use(Popup)
    .use(DatetimePicker)
    .use(Image)
    .use(Steps)
    .use(Step)
    .use(CellGroup)
    .use(Cell)
    .use(Search)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(PullRefresh)
    .use(List)
    .use(Tag)
    .use(Loading)
    .use(CheckboxGroup)
    .use(Stepper)
    .use(Checkbox)
    .use(Tab)
    .use(Tabs)
    .use(ActionSheet)
    .use(Empty)
    .use(Collapse)
    .use(CollapseItem)
    .use(Skeleton)
    .use(Overlay)
    .use(SwipeCell)
    .use(Form)
    .use(Dialog)
    .use(Row)
    .use(Col)
    .use(Swipe)
    .use(SwipeItem)
    .use(Pagination)
    .use(Divider)
    .use(Popover)
    .use(TreeSelect)
    .use(NoticeBar)
    .use(ImagePreview)
    .use(Calendar)
    .use(CountDown)
    .use(Switch)
    .use(Cascader);
};
