import Vue from "vue";
import Vuex from "vuex";
import globalPlatformConfig from "./globalPlatformConfig";
import modules from "./requireAll";

modules.globalPlatformConfig = globalPlatformConfig;

function improveLogger() {
  const isIncludedImprove = str => str.includes("improve");
  return function(store) {
    store.subscribe(mutation => {
      if (isIncludedImprove(mutation.type)) {
        console.log("%c 改善 mutation", "color: #03A9F4; ", mutation);
      }
    });
    store.subscribeAction(action => {
      if (isIncludedImprove(action.type)) {
        console.log("%c 改善 action", "color: #02b5d0;", action);
      }
    });
  };
}

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [improveLogger()],
  state: {
    needRefreshList: false
  },
  mutations: {
    setNeedRefreshList(state, data) {
      state.needRefreshList = data;
    }
  },
  actions: {},
  modules
});
