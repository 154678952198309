// import { Toast } from "vant";

const execAndroidMethod = (name, params = []) => {
  console.log("name", name);
  if (window.android && window.android[name]) {
    window.android[name](...params);
  } else {
    // console.log("请在手机上调用");
    // Toast("请在手机上调用");
  }
};

const androidObj = {
  // 退出
  logout() {
    execAndroidMethod("logout");
  },
  downLoadFile(url, filename) {
    execAndroidMethod("downLoadFile", [url, filename]);
  },
  getCacheSize(callback) {
    execAndroidMethod("getCacheSize", [callback]);
  },
  clearCache(callback) {
    execAndroidMethod("clearCache", [callback]);
  },
  getVersion(callback) {
    execAndroidMethod("getVersion", [callback]);
  },
  playMedia(type, path) {
    execAndroidMethod("playMedia", [type, path]);
  },
  setToken(token, expireTime, refreshTime) {
    execAndroidMethod("setToken", [token, expireTime, refreshTime]);
  },
  scanQrCode(callback, type) {
    execAndroidMethod("scanQrCode", [callback, type]);
  },
  getLocation(callback) {
    execAndroidMethod("getLocation", [callback]);
  },
  getNFCData(callback, type) {
    execAndroidMethod("getNFCData", [callback, type]);
  },
  sersorRunning(callback) {
    execAndroidMethod("sersorRunning", [callback]);
  },
  startSensor(callback, selectSensor, unit) {
    execAndroidMethod("startSensor", [callback, selectSensor, unit]);
  },
  searchSendSensorList(callback1, callback2) {
    execAndroidMethod("searchSendSensorList", [callback1, callback2]);
  },
  endSensor(callback, showTip) {
    execAndroidMethod("endSensor", [callback, showTip]);
  },
  takePhoto(callback, type) {
    execAndroidMethod("takePhoto", [callback, type]);
  },
  setBadgeCount(num) {
    execAndroidMethod("setBadge", [num]);
  },
  subtractBadge(num) {
    execAndroidMethod("subtractBadge", [num]);
  },
  resetBadgeCount() {
    execAndroidMethod("resetBadgeCount");
  },
  takePhoto2(callback, uploadFileUrl) {
    execAndroidMethod("takePhoto2", [callback, uploadFileUrl]);
  },
  takePhotoFace(callback, otherParams) {
    execAndroidMethod("takePhotoFace", [callback, otherParams]);
  }
};
export default androidObj;
