const twoLineList = () => import("@/views/TwoLine/TwoLineList");
export default [
  {
    path: "/two-line",
    name: "TwoLine",
    component: () => import("@/views/TwoLine/TwoLine")
  },
  {
    path: "/onoffline",
    name: "OnOffLine",
    meta: {
      // cache: true,
      // componentName: "OnOffLine",
      type: 0
    },
    component: twoLineList
  },
  {
    path: "/onoffline/:id",
    name: "OnOffLineDetail",
    props: true,
    meta: { type: 0 },
    component: () => import("@/views/TwoLine/TwoLineDetail")
  },
  {
    path: "/onoffline/:id/approve",
    name: "TwoLineApprove",
    props: true,
    meta: { type: 0 },
    component: () => import("@/views/TwoLine/TwoLineApprove")
  },

  {
    path: "/onoffline-process",
    name: "OnOffLine2",
    component: twoLineList,
    meta: {
      // cache: true,
      // componentName: "OnOffLine2",
      type: 1
    }
  },
  {
    path: "/onoffline-process/:id",
    name: "OnOffLineDetail2",
    props: true,
    meta: { type: 1 },
    component: () => import("@/views/TwoLine/TwoLineDetail")
  },
  {
    path: "/onoffline-process/:id/approve",
    name: "TwoLineApprove2",
    props: true,
    meta: { type: 1 },
    component: () => import("@/views/TwoLine/TwoLineApprove")
  }
];
