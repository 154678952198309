<template>
  <div class="view-login">
    <van-form ref="form" :show-error-message="false" @submit="onSubmit">
      <van-field
        v-model="form.loginName"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="form.password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
// @ is an alias to /src
import { Form, Field, Button, Toast } from "vant";
import { mapState } from "vuex";
export default {
  name: "Login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button
  },
  data() {
    return {
      form: {
        loginName: "",
        password: "",
        device: "1"
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
      // redirectURL: state => state.login.redirectURL
    })
  },
  methods: {
    // ...mapActions(["login"]),
    onSubmit() {
      this.$refs.form.validate().then(() => {
        if (this.$route.query.tenantId) {
          this.form.tenantId = this.$route.query.tenantId;
        }
        this.$store.dispatch("login", this.form).then(r => {
          const { userType, defPasswordFlag, mobile } = r;
          if (userType === 0 && defPasswordFlag) {
            location.href = "/user/validphone?mobile=" + mobile;
          } else {
            Toast("登录成功");
            // alert(window.sessionStorage.getItem("redirectURL"))
            // location.href = window.sessionStorage.getItem("redirectURL") || "/";
            location.href = "/";
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
.view-login {
  height: 100%;
  .van-form {
    top: 20%;
    position: relative;
  }
  /*padding-top: 20vh;*/
  /*box-sizing: border-box;*/
}
</style>
