import axios from "../utils/axios";
import { messageJumpWhiteListReg as whiteListReg } from "@/utils/constant";

import apiPath from "../utils/apiPath";
const API_MESSAGE = apiPath.message;

export default {
  // 查询消息
  getBasePageNotices(params) {
    return axios
      .get(API_MESSAGE + "/page/notices", {
        params: {
          ...params,
          sort: "createTime",
          platform: 1
        }
      })
      .then(res => {
        if (res?.list?.length) {
          // 移动端目前根据消息跳转模块只有报警和隐患模块，其他都不跳转
          res?.list.map(item => {
            const isAllowJump = whiteListReg.test(item.uri);
            item.isAllowJump = isAllowJump;
            const content = item.content;
            if (!isAllowJump) {
              item.content = content.replace(/<a href=".*">(.+)<\/a>/, "$1");
            } else {
              const replacer = (match, p1, p2) => p1 + "javascript:;" + p2;
              item.content = content.replace(/(.+href=").+(".+)/, replacer);
              // item.content = content.replace(
              //   new RegExp('(?<=href=").*?(?=")'),
              //   "javascript:;"
              // );
            }
          });
          return res;
        }
        return res;
      });
  },
  getMessageNoticesById(id) {
    return axios.get(API_MESSAGE + `/notices/${id}`);
  },
  // 读取消息
  updateBasePageNotices(data) {
    return axios.post(API_MESSAGE + "/read/notices", null, {
      params: data
    });
  },
  // 删除消息
  deleteBasePageNotices(data) {
    return axios.post(API_MESSAGE + "/delete/notices", null, {
      params: data
    });
  },
  // 查询代办事项
  getMessagePageTasks(params) {
    return axios
      .get(API_MESSAGE + "/page/tasks", {
        params: {
          ...params,
          platform: 1
        }
      })
      .then(res => {
        // 设置角标
        window.androidH5.resetBadgeCount();
        return res;
      });
  },

  // 查询代办事项 通过ID
  getMessageTasksById(id) {
    return axios.get(API_MESSAGE + `/tasks/${id}`);
  },

  getMessageCountNotices(params) {
    return axios
      .get(API_MESSAGE + "/count/notices", {
        params
      })
      .then(res => {
        return res;
      });
  },
  // 查询重要程度
  getMessageDegrees(params) {
    return axios.get(API_MESSAGE + "/degrees", {
      params
    });
  },
  // 查询代办类型
  getMessageTaskTypes(params) {
    return axios.get(API_MESSAGE + "/task/types", {
      params
    });
  },
  // 查询模块
  getMessageModules(params) {
    return axios.get(API_MESSAGE + "/modules", {
      params
    });
  },
  // 模块类型分类
  getMessageModulesByType(params) {
    return axios.get(API_MESSAGE + "/select/modules/permission", {
      params
    });
  }
};
