<template>
  <div id="app">
    <color-icon />
    <transition name="route-switch" mode="out-in">
      <keep-alive :include="router.cacheView" :max="10">
        <router-view v-if="isRouterAlive" />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "UserTodoList",
  components: {},
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      routerObj: {},
      cacheComponents: ["IndicatorList", "VideoList"],
      isRouterAlive: true
    };
  },
  methods: {
    ...mapActions(["getMessageCountNoticesAction"]),
    ...mapMutations([
      "addCacheView",
      "removeCacheView",
      "clearCacheView",
      "addRouterHistory",
      "removeRouterHistory",
      "clearRouterHistory"
    ]),
    //刷新当前路由
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  },
  computed: {
    ...mapState({
      router: state => state.router
    })
  },
  watch: {
    $route(to, from) {
      // console.log("from", from);
      // 1. 判断当前路由历史里面是否有to
      // 2. 如果没有, 则说明是push, 如果有则说明是back
      // 3. 如果没有则新增路由历史记录, 如果有, 则删除
      if (to.name === "Home") {
        this.clearRouterHistory();
        return;
      }
      const toRouterIndex = this.router.routerHistory.findIndex(
        a => a.path === to.path
      );
      const fromRouterIndex = this.router.routerHistory.findIndex(
        a => a.path === from.path
      );

      // 如果 to 和 from都没有
      if (toRouterIndex === -1 && fromRouterIndex === -1) {
        this.addRouterHistory(to);
      } else if (toRouterIndex !== -1 && fromRouterIndex === -1) {
        // to 有, from 没有
        this.removeRouterHistory(toRouterIndex);
      } else if (toRouterIndex === -1 && fromRouterIndex !== -1) {
        if (to.params.isRouterReplace) {
          this.removeRouterHistory(fromRouterIndex);
          return;
        }
        this.addRouterHistory(to);
      } else if (toRouterIndex !== -1 && fromRouterIndex !== -1) {
        this.removeRouterHistory(fromRouterIndex);
      }
    }
  },
  created() {
    if (this.$store.state.login.isLogin) {
      this.getMessageCountNoticesAction();
    }
    document.title = globalConfig?.VUE_APP_TITLE || "安全生产智能管控平台";
  }
};
</script>
<style lang="scss">
#app {
  font-family: PingFangSC, serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
  height: 100%;
}
</style>
