const appName = globalConfig?.VUE_APP_SERVICE_PROJECT?.toUpperCase();
const equipment = () =>
  import(
    /* webpackChunkName: "equipment" */ `@/views/${
      appName === "RT" ? "equipment" : "equipmentRx"
    }/equipment.vue`
  );
const equipmentInfo = () =>
  import(
    /* webpackChunkName: "equipmentInfo" */ `@/views/${
      appName === "RT" ? "equipment" : "equipmentRx"
    }/equipmentInfo.vue`
  );
const equipmentOverhaul = () =>
  import(
    /* webpackChunkName: "equipmentOverhaul" */ "@/views/equipment/equipmentOverhaul.vue"
  );
const equipmentMaintain = () =>
  import(
    /* webpackChunkName: "equipmentMaintain" */ "@/views/equipment/equipmentMaintain.vue"
  );
const equipmentVerification = () =>
  import(
    /* webpackChunkName: "equipmentVerification" */ "@/views/equipment/equipmentVerification.vue"
  );
const equipmentFault = () =>
  import(
    /* webpackChunkName: "equipmentFault" */ "@/views/equipment/equipmentFault.vue"
  );
const equipmentSubsidiary = () =>
  import(
    /* webpackChunkName: "equipmentSubsidiary" */ "@/views/equipment/equipmentSubsidiary.vue"
  );
const equipmentDocument = () =>
  import(
    /* webpackChunkName: "equipmentDocument" */ "@/views/equipment/equipmentDocument.vue"
  );
const equipmentDocumentDetail = () =>
  import(
    /* webpackChunkName: "equipmentDocumentDetail" */ "@/views/equipment/equipmentDocumentDetail.vue"
  );
const equipmentFaultAdd = () =>
  import(
    /* webpackChunkName: "equipmentFaultAdd" */ `@/views/${
      appName === "RT" ? "equipment" : "equipmentRx"
    }/equipmentFaultAdd.vue`
  );
const equipmentAffix = () =>
  import(
    /* webpackChunkName: "equipmentAffix" */ "@/views/equipment/equipmentAffix.vue"
  );
const equipmentWorkOrder = () =>
  import(
    /* webpackChunkName: "equipmentWorkOrder" */ "@/views/equipment/equipmentWorkOrder.vue"
  );
const equipmentWorkOrderDetail = () =>
  import(
    /* webpackChunkName: "equipmentWorkOrderDetail" */ "@/views/equipment/equipmentWorkOrderDetail.vue"
  );
export default [
  {
    path: "/equipmentledger/:id",
    name: "equipmentledger",
    props: true,
    component: equipment
  },
  {
    path: "/equipmentledger/info/:id",
    name: "equipmentledgerInfo",
    component: equipmentInfo,
    props: true
  },
  {
    path: "/equipmentledger/overhaul/:id",
    name: "equipmentOverhaul",
    component: equipmentOverhaul,
    props: true
  },
  {
    path: "/equipmentledger/maintain/:id",
    name: "equipmentledgerMaintain",
    component: equipmentMaintain,
    props: true
  },
  {
    // 检定计划
    path: "/equipmentledger/verification/:id",
    name: "equipmentVerification",
    component: equipmentVerification,
    props: true
  },
  {
    // 故障记录
    path: "/equipmentledger/fault/:id",
    name: "equipmentFault",
    component: equipmentFault,
    props: true
  },
  {
    // 关联辅件
    path: "/equipmentledger/subsidiary/:id",
    name: "equipmentSubsidiary",
    component: equipmentSubsidiary,
    props: true
  },
  {
    // 设备文档
    path: "/equipmentledger/document/:id",
    name: "equipmentDocument",
    component: equipmentDocument,
    props: true
  },
  {
    // 安全附件
    path: "/equipmentledger/affix/:id",
    name: "equipmentAffix",
    component: equipmentAffix,
    props: true
  },
  {
    // 工单管理
    path: "/equipmentledger/workorder/:id",
    name: "equipmentWorkOrder",
    component: equipmentWorkOrder,
    props: true
  },
  {
    // 工单管理
    path: "/equipmentledger/workorderdetail/:id",
    name: "equipmentWorkOrderDetail",
    component: equipmentWorkOrderDetail,
    props: true
  },
  {
    // 设备文档详情
    path: "/equipmentledger/documentdetail",
    name: "equipmentDocumentDetail",
    component: equipmentDocumentDetail,
    props: true
  },
  {
    // 报故障
    path: "/equipmentledger/equipmentfault/:id",
    name: "equipmentFaultAdd",
    component: equipmentFaultAdd,
    props: true
  }
];
