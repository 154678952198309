import { emojiReg } from "./constant";
import { Dialog, Toast } from "vant";
import dayjs from "dayjs";

/**
 * setTimeout 模拟 setInterval
 * @param callback
 * @param intervalTime
 */
export const interval = (callback, intervalTime) => {
  function repeat() {
    callback();
    setTimeout(repeat, intervalTime);
  }
  setTimeout(repeat, intervalTime);
};

/**
 * 生成组件
 * @param {*} components
 */
export const generatorComponents = components => {
  const result = {};
  components.forEach(item => {
    result[item.name] = item;
  });
  return result;
};

/**
 * 时间戳字符串，获取时间戳
 * @param dateStr
 * @returns {string|number}
 */
export const getTimeParseDate = dateStr => {
  if (!dateStr) {
    return "";
  }

  let time = new Date(dateStr).getTime();
  if (Number.isNaN(time)) {
    time = "";
  }
  return time;
};

/**
 * 时间格式化
 * @param {*} time
 * @param {*} cFormat
 */
export const parseTime = (time, cFormat) => {
  if (time === undefined) return null;
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return timeStr;
};

/**
 * 根据树id查询树的名字
 * @param {*} tree
 * @param {*} orgCode
 */
export const getOrgNameByOrgCode = (tree, orgCode) => {
  let orgName = "";
  function deepQuery(tree, orgCode) {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (+node.id === +orgCode) {
        orgName = node.label;
        break;
      }
      if (node.children) deepQuery(node.children, orgCode);
    }
  }
  deepQuery(tree, orgCode);
  return orgName;
};

/**
 * 把对象空熟悉填充默认值
 * @param {*} o
 */
export const parseEmptyObj = o => {
  if (!Object.keys(o).length) return o;
  Object.keys(o).forEach(key => {
    if (o[key] === "" || o[key] === null || o[key] === undefined) {
      o[key] = "--";
    }
  });
  return o;
};

/**
 * 调用原生的播放功能
 * @param {*} type 视频video/*, 音频 audio/*
 * @param {*} path 可原生app地址，可正式http地址
 */
export const playMedia = (type, path) => {
  window.android.playMedia(type, path);
};

/**
 * 过滤用户输入的表情符号
 * @param {*} str
 */
export const filterEmoji = str => str.trim().replace(emojiReg, "");

export const operateDialog = (
  options = {
    title: "操作提示",
    message: "确定关闭此任务？",
    messageAlign: "center"
  }
) => {
  return new Promise((resolve, reject) => {
    Dialog.confirm({ closeOnPopstate: true, ...options })
      .then(resolve)
      .catch(reject);
  });
};

/**
 * 删除、编辑、新增之后的操作提示
 * @param {boolean} assertVal 操作接口返回的状态
 * @param {*} message 操作展示的文案
 */
export const operateMessage = (assertVal = true, message = "删除") => {
  if (assertVal) {
    Toast.success(`${message}成功`);
  } else {
    Toast.fail(`${message}失败`);
  }
};
/**
 * 深度冻结对象
 * @param {*} o
 */
export const deepFreeze = o => {
  const propNames = Object.getOwnPropertyNames(o);
  propNames.forEach(name => {
    const prop = o[name];
    if (typeof prop === "object" && prop !== null) {
      deepFreeze(prop);
    }
  });
  return Object.freeze(o);
};

/**
 * 下拉框label数据转字典
 * @param {*} labelData
 */
export const parseLabelToDic = labelData => {
  if (!Array.isArray(labelData)) return {};
  const result = {};
  labelData.forEach(item => {
    result[item.value] = item.label;
  });
  return result;
};

export const getRandomNumber = (min, max) => {
  return Math.round(Math.random() * (max - min)) + min;
};

export const formatTimeWithHour = time => {
  return dayjs(time).format("YYYY-MM-DD");
};
export const getRecentDays = days => {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * days);
  return { start: formatTimeWithHour(start), end: formatTimeWithHour(end) };
};

/**
 * 判断对象是不是一个对象
 * @param o
 * @returns {boolean}
 */
export const isObject = o =>
  Object.prototype.toString.call(o) === "[object Object]";

/**
 * 对象数字转换字符串
 * @param obj
 * @returns {*}
 */
export const objectKeysNumber2String = obj => {
  if (!isObject(obj)) return obj;
  Object.entries(obj).forEach(([key, value]) => {
    if (isObject(value)) {
      return objectKeysNumber2String(value);
    }
    if (typeof value === "number") {
      obj[key] = String(value);
    }
  });
  return obj;
};

/**
 * 树遍历
 * @param {*} tree
 * @param {*} func
 */
export const treeForEach = (tree, func) => {
  tree.forEach(data => {
    func(data);
    data.children && treeForEach(data.children, func);
  });
};

/**
 * 校验有没有路由权限
 * @param {*} roles
 * @param {*} route
 */
export const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
};

/**
 * 过滤路由
 * @param routes
 * @param roles
 * @returns {[]}
 */
export const filterModuleRoutes = (routes, roles) => {
  const res = [];
  routes.forEach(route => {
    const r = { ...route };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterModuleRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

/**
 * 数组按长度分组
 * @param arr
 * @param size
 * @returns {[]}
 */
export const arrayGroupBySize = (arr, size = 2) => {
  const result = [];
  for (let i = 0, len = arr.length; i < len; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};

/**
 * 根据长度对字符串后显示省略号(执行toString后存在，否则返回)
 * startIdx和endIdx遵循字符串slice方法规则
 * @author zgx
 * @param str String
 * @param startIdx Number
 * @param endIdx Number
 * @returns String
 */
export function strShowEllipsis(str, startIdx, endIdx) {
  if (!str || (startIdx !== 0 && !startIdx) || (endIdx !== 0 && !endIdx)) {
    return str;
  }
  str = str.toString && str.toString();
  if (typeof str !== "string") return str;
  if (str.length <= endIdx) return str;
  return `${str.slice(startIdx, endIdx)}...`;
}

/**
 * 克隆对象 (只接受除null外对象类型，其他类型返回原值)
 * @author zgx
 * @param  obj Object
 * @returns Object
 */
export function deepClone(obj) {
  if (!obj || typeof obj !== "object") return obj;
  let _o = Array.isArray(obj) ? [] : {};
  for (let k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k)) {
      _o[k] = typeof obj[k] !== "object" ? obj[k] : deepClone(obj[k]);
    }
  }
  return _o;
}

// 获取巡检采集方式映射
export function taskCollectTypeStr(v) {
  const obj = {
    1: "手动",
    2: "自动"
  };
  return obj[v] || obj[1];
}

// 生成随机汉字
export function getRandomText() {
  const randomChineseUnicode = `%u${(
    Math.round(Math.random() * 20901) + 19968
  ).toString(16)}`;
  return unescape(randomChineseUnicode);
}
// 生成随机汉字
export function randomString(n) {
  let s = "";
  for (let i = 0; i < n; i++) {
    s += getRandomText();
  }
  return s;
}

/**
 * 获取级连部门数据的所有id（数据格式内必须有children和id字段）
 * @author zgx
 * @param treeData Array
 * @returns Array
 */
export function getTreeDataAllId(treeData, idList) {
  if (!treeData || !Array.isArray(treeData) || !treeData.length) return [];
  const arr = idList || [];
  treeData.forEach(elem => {
    arr.push(elem.id);
    const children = elem.children;
    // if (children && children.length) getTreeDataAllId(children, arr);
    getTreeDataAllId(children, arr);
  });
  return arr;
}

export function encodeStrDefault() {
  return encodeStr(`${Date.now()}png`);
}

export function encodeStr(str) {
  let encode = encodeURI(str);
  return btoa(encode);
}

export function getUploadFileXd(files) {
  const fileName = files.name;
  const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
  const xd = encodeStr(`${Date.now()}${fileExt}`);
  return xd;
}
