export default {
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    dynamicRule: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    label: String
  },
  computed: {
    labelName() {
      return this.hideLabel ? "" : this.label ? this.label + ":" : "";
    },
    isRequired() {
      if (!this.editable) {
        return false;
      }
      if (Object.prototype.toString.call(this.rules) === "[object String]") {
        const rulesList = this.rules.split("|");
        return (
          rulesList.includes("required") || rulesList.includes("required:true")
        );
      } else if (
        Object.prototype.toString.call(this.rules) === "[object Object]"
      ) {
        return this.rules.required;
      }
    }
  }
};
