<template>
  <div class="has-nav-bar view-user-password-forget">
    <van-nav-bar title="激活账号" fixed left-arrow @click-left="onClickLeft" />
    <van-form ref="form" @submit="onSubmit">
      <van-field
        v-model="form.mobile"
        name="password"
        type="number"
        placeholder="输入平台登记的手机号"
        maxlength="11"
        @blur="getTenantList"
      />
      <div
        v-if="form.tenantId"
        class="project-name text-overflow"
        @click="projectPopupVisible = true"
      >
        {{ form.projectName }}
      </div>
      <van-field
        v-model="form.code"
        center
        clearable
        placeholder="输入短信验证码"
      >
        <template #button>
          <van-button
            size="small"
            native-type="button"
            type="primary"
            :disabled="disabledCode"
            @click="sendCode"
          >
            {{ text }}
          </van-button>
        </template>
      </van-field>
      <div class="support-mobile">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :disabled="loading.status"
          :loading="loading.status"
        >
          下一步
        </van-button>
      </div>
    </van-form>
    <select-popup
      v-model="form.tenantId"
      title="选择企业"
      :visible="projectPopupVisible"
      :data="projectList"
      label-key="name"
      value-key="tenantId"
      :style="{ height: '40vh' }"
      @close="projectPopupVisible = false"
      @confirm="onConfirm"
    />
    <!--    <pre>{{ form }}</pre>-->
  </div>
</template>

<script>
import { Toast } from "vant";
import authApi from "@/api/authorization";
import { mapState } from "vuex";
import SelectPopup from "@/components/SelectPopup";
const DEFAULT_TEXT = "获短信取验证码";

export default {
  name: "UserPasswordForget",
  components: {
    SelectPopup,
    [Toast.name]: Toast
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      projectPopupVisible: false,
      form: {
        tenantId: "",
        projectName: ""
      },
      loading: {
        status: false
      },
      text: DEFAULT_TEXT,
      disabledCode: false,
      lock: false,
      tenantList: [],
      projectList: []
    };
  },
  methods: {
    onConfirm(row) {
      this.form.projectName = row.name;
    },
    async getTenantList() {
      const mobile = String(this.form.mobile);
      if (mobile.length !== 11) return;
      this.tenantList = await authApi.getTenantList(mobile);
      await this.getProjectList(this.tenantList.join(","));
      this.projectPopupVisible = true;
    },
    async getProjectList(tenantIds) {
      this.projectList = (await authApi.getListProjectByIds(tenantIds)) || [];
    },
    onClickLeft() {
      location.href = "/";
    },
    checkMobile() {
      const result = /^1\d{10}$/.test(this.form.mobile);
      if (!result) {
        Toast("请输入正确的手机号!");
      }
      return result;
    },
    async checkPhoneStatus() {
      const r = await authApi.getUserStatusByMobile({
        mobile: this.form.mobile,
        tenantId: this.form.tenantId
      });
      if (!r) {
        Toast("手机号不存在");
        return false;
      }
      if (r.accountState == 30) {
        // 禁用 or 不存在
        Toast("账号或密码错误");
        return false;
      }
      // 如果手机号已经验证了
      if (r.verified == 1 && r.accountState == 20) {
        Toast("账号已激活, 请直接登录");
        setTimeout(() => {
          if (window.android) {
            window.android.logout();
          } else {
            this.$router.replace({
              path: "/login"
            });
          }
        }, 2000);
        return false;
      }
      // true: 说明可以去发短信
      return true;
    },
    async sendCode() {
      if (!this.form.tenantId) return Toast("请选择企业");
      const r = await this.checkPhoneStatus();
      if (!r) {
        return;
      }
      if (this.checkMobile() && !this.lock) {
        this.lock = true;
        authApi
          .getAuthorizationInfoTelVerificationCode(
            this.form.mobile,
            this.form.tenantId
          )
          .then(res => {
            if (res) {
              Toast(`手机号验证码发送成功！`);
              this.renderButton();
            } else {
              Toast(`手机号验证码发送失败，请稍后再试！`);
            }
            this.lock = false;
          })
          .catch(() => {
            this.lock = false;
          });
      }
    },
    renderButton() {
      this.disabledCode = true;
      let time = 60;
      this.timer = setInterval(() => {
        time--;
        this.text = time + "s";
        if (time <= 0) {
          clearInterval(this.timer);
          this.disabledCode = false;
          this.text = DEFAULT_TEXT;
        }
      }, 1000);
    },
    onSubmit(values) {
      if (!this.checkMobile()) {
        return false;
      }
      if (!this.form.code) {
        return Toast("请输入验证码!");
      }
      if (this.form.mobile) {
        this.loading.status = true;
        authApi
          .getAuthorizationIValidateTelVerificationCode(this.form)
          .then(res => {
            if (res) {
              sessionStorage.setItem("mobile", this.form.mobile);
              sessionStorage.setItem("verificationCode", this.form.code);
              return this.$router.push({
                path: "/user/password/reset",
                query: {
                  isactive: true,
                  tenantId: this.form.tenantId
                }
              });
            }
            Toast.success("验证码错误");
          })
          .catch(err => {
            console.log("onSubmit -> err", err);
          })
          .finally(() => {
            this.loading.status = false;
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.view-user-password-forget {
  .project-name {
    margin-top: 12px;
    color: #323233;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    line-height: 20px;
  }
  .van-form {
    overflow: auto;
    padding: 20px;
  }
  .van-cell {
    .van-button {
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .support-mobile {
    margin: 6vh 0;
    .van-button {
      width: 100%;
      height: 46px;
      background: rgba(24, 144, 255, 1);
      box-shadow: 0px 5px 16px 0px rgba(24, 144, 255, 0.3);
      border-radius: 8px;
    }
  }
}
</style>
