export default [
  {
    path: "/vehicle",
    name: "VehicleList",
    meta: { cache: true, componentName: "VehicleList" },
    component: () =>
      import(
        /* webpackChunkName: "VehicleList" */ "@/views/Vehicle/VehicleList"
      )
  },
  {
    path: "/vehicle/:id(\\d+)",
    name: "VehicleDetails",
    props: true,
    meta: { cache: true, componentName: "VehicleDetails" },
    component: () =>
      import(
        /* webpackChunkName: "VehicleDetails" */ "@/views/Vehicle/VehicleDetails"
      )
  }
];
