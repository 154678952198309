import {
  getSelectContractorsNames,
  getWorkArea,
  getWorkStatus
} from "@/api/base";
import { getFireWay, getPagePerson, getSelectWorkTypes } from "@/api/psm";
import {
  getWorkHazard,
  getWorkSpaceType,
  getWorkWorkPerson
} from "@/api/workticket";

export default {
  state: {
    //作业区域
    areaOptions: [
      {
        label: "储罐",
        type: "13",
        key: "tanks",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      },
      {
        label: "罐区",
        type: "14",
        key: "tankAreas",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      },
      {
        label: "库区",
        type: "31",
        key: "warehouse-areas",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      },
      {
        label: "仓库",
        type: "15",
        key: "warehouses",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      },
      {
        label: "生产单元",
        type: "16",
        key: "devices",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      },
      {
        label: "其他设施",
        type: "17",
        key: "pois",
        status: "loading",
        page: 1,
        size: 10,
        total: -1,
        list: []
      }
    ],
    //作业类型
    workTypeOptions: [],
    //作业状态
    workStatusOptions: [],
    //所有人员
    allPersonOptions: [],
    allPersonOptionsLoading: false,
    //作业人员
    workticketPersionOptions: {},
    workticketPersionOptionsLoading: false,

    //动火方式
    fireWayOptionts: [],
    //危害辨识
    workHazardOptionts: [],
    //受限空间类型
    workSpaceTypeOptionts: [],

    //统计时间单位
    analysisDateUnitOptions: [
      { label: "日", value: "day" },
      { label: "周", value: "week" },
      { label: "月", value: "month" },
      { label: "年", value: "year" }
    ],

    //气体检测结果
    gasCheckResult: [
      { label: "合格", value: 1 },
      { label: "不合格", value: 0 }
    ]
  },
  mutations: {},
  actions: {
    getWorkSpaceTypeOptionts({ commit, state }) {
      if (
        state.workSpaceTypeOptionts &&
        state.workSpaceTypeOptionts.length > 0
      ) {
        return;
      }
      getWorkSpaceType().then(list => {
        state.workSpaceTypeOptionts = list;
      });
    },
    getWorkHazardList({ commit, state }) {
      if (state.workHazardOptionts && state.workHazardOptionts.length > 0) {
        return;
      }
      getWorkHazard().then(list => {
        console.log("getWorkHazardOptionts-----", list);
        state.workHazardOptionts = list.map(item => {
          item.name = item.value;
          item.type = 0;
          return item;
        });
      });
    },
    /**
     * 获取动火方式
     * @param commit
     * @param state
     */
    getFireWayOptions({ commit, state }) {
      if (state.fireWayOptionts && state.fireWayOptionts.length > 0) {
        return;
      }
      getFireWay().then(list => {
        state.fireWayOptionts = list;
      });
    },
    /**
     * 获取所有工作人员,old
     * @param commit
     * @param state
     */
    getAllPersonList({ commit, state }) {
      if (state.allPersonOptionsLoading || state.allPersonOptions.length > 0) {
        return;
      }
      state.allPersonOptionsLoading = true;
      getPagePerson({ size: 10000, page: 1 })
        .then(({ list, total }) => {
          state.allPersonOptions = list;
        })
        .finally(() => {
          state.allPersonOptionsLoading = false;
        });
    },
    /**
     * 获取所有工作人员
     * @param commit
     * @param state
     */
    getWorkticketPersionOptions({ commit, state }, workTypeId) {
      if (
        state.workticketPersionOptionsLoading ||
        (state.workticketPersionOptions[workTypeId] &&
          state.workticketPersionOptions[workTypeId].length > 0)
      ) {
        return;
      }
      state.workticketPersionOptionsLoading = true;
      getWorkWorkPerson(workTypeId)
        .then(list => {
          state.workticketPersionOptions[workTypeId] = list.map(item => {
            item.value = item.personId;
            item.label = item.personName;
            return item;
          });
        })
        .finally(() => {
          state.workticketPersionOptionsLoading = false;
        });
    },
    /**
     * 获取作业状态
     * @param commit
     * @param state
     */
    getWorkStatusOptions({ commit, state }) {
      if (state.workStatusOptions.length > 0) {
        return;
      }
      getWorkStatus().then(res => {
        state.workStatusOptions = res;
      });
    },
    /**
     * 获取作业类型
     * @param commit
     * @param state
     */
    getWorkTypeOptions({ commit, state, rootState }) {
      if (state.workTypeOptions.length > 0) {
        return;
      }
      let orgCode = rootState.login.userInfo.orgCode;
      getSelectWorkTypes(orgCode).then(res => {
        state.workTypeOptions = res;
      });
    },

    /**
     * 分页加载单个罐区
     * @param key  罐区key
     */
    getAreaOptionsList({ commit, state }, key) {
      let { areaOptions } = state;
      let index = areaOptions.findIndex(item => item.key === key);

      if (index == -1) {
        return;
      }

      let option = areaOptions[index];
      let { page, size, total, list } = option;
      //加载完成不加载
      if (total == list.length) {
        return;
      }

      getWorkArea(key, { page, size })
        .then(res => {
          option.list = option.list.concat(res.list);
          option.total = res.total;
          option.status = "success";
          if (res.list && res.list.length > 0) {
            option.page++;
          }
        })
        .catch(error => {
          option.status = "error";
        });
    }
  }
};
