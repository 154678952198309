import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

export function getPageSelectWarehouseAreas(params) {
  return axios.get(api + "/page/select/warehouse-areas", { params });
}

export function getPageSelectWarehouseAreasForOrg(params) {
  return axios.get(api + "/page/select/org/warehouse-areas", { params });
}
