export default [
  {
    path: "/danger",
    name: "danger",
    component: () => import("@/views/danger/danger.vue"),
    props: true
  },
  {
    path: "/danger/detail/:id",
    name: "danger-detail",
    component: () => import("@/views/danger/dangerDetail.vue")
  },
  {
    path: "/danger/interlock/account/detail/:id",
    name: "danger-interlock-account-detail",
    component: () => import("@/views/danger/InterlockAccountDetail.vue")
  },
  {
    path: "/danger/interlock/detail/:id",
    name: "danger-interlock-detail",
    component: () => import("@/views/danger/InterlockDetail.vue")
  }
];
