import store from "../store";
import { Dialog } from "vant";
import android from "./android";
window.APP = {
  getPlatform() {
    const ua = window.navigator.userAgent;
    const key = "Platform/";
    return {
      isAndroid: ua.includes(key + "Android"),
      isMobile: ua.includes("Mobile")
    };
  }
};

// 推送消息
window.onPushNotify = param => {
  const obj = JSON.parse(param);
  //0:应用外消息  1:应用内消息
  if (obj.in_app === "1") {
    // 更新总数
    store.dispatch("getMessageCountNoticesAction");
  } else {
    store.dispatch("redirectMessageRouteAction", obj);
  }
};

//将获取cache的callback函数名传过来，callback函数默认一个参数 ：缓存大小 单位 byte
window.getCacheSize = size => {
  if (window.APP.getPlatform().isAndroid) {
    window.androidH5.getCacheSize("cacheSizeCallBack");
  }
};
//获取cache体积 回调
window.cacheSizeCallBack = size => {
  store.commit("setCacheSize", size);
};

window.clearCache = () => {
  if (window.APP.getPlatform().isAndroid) {
    Dialog.confirm({
      message: "此操作会删除缓存，是否继续？"
    }).then(() => {
      window.androidH5.clearCache("clearCacheCallBack");
    });
  }
};

window.clearCacheCallBack = (code, msg) => {};

// 获取版本信息
window.getVersion = () => {
  //将获取version的callback函数名传过来，callback函数默认两个参数 ：versionName, versionCode
  if (window.APP.getPlatform().isAndroid) {
    window.androidH5.getVersion("versionCallBack");
  }
};

window.versionCallBack = (versionName, versionCode) => {
  store.commit("setVersion", {
    versionName,
    versionCode
  });
};

window.androidH5 = android;
