export default [
  //救援任务
  {
    path: "/rescue",
    name: "Rescue",
    component: () => import("@/views/rescueTask/index.vue"),
    meta: { cache: false, componentName: "Rescue" }
  },
  //救援任务详情
  {
    path: "/rescueDetail/:formData",
    name: "RescueDetail",
    component: () => import("@/views/rescueTask/detail.vue"),
    meta: { cache: false, componentName: "RescueDetail" }
  }
];
