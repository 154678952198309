const defaultTextConfig = {
  TEXT_SAFETY_ANALYSIS_HEAD: "安全分析负责人",
  TEXT_SAFETY_ANALYSIS_WAITING: "待安全分析",
  TEXT_SAFETY_ANALYSIS_PROGRESS: "安全分析中",
  TEXT_SAFETY_ANALYSIS_DELIVER: "待安全交底",
  TEXT_WORK_APPROVER: "作业批准人",
  TEXT_PRODUCTION_UNIT_WORK_COMMANDER: "生产单位作业指挥",
  TEXT_OPEN_ANALYSIS: "作业前安全分析(JSA)",
  TEXT_ANALYSIS_MODULE: "作业前安全分析(JSA)",
  TEXT_ANALYSIS_TABLE: "作业前安全分析表",
  TEXT_CONTROL_RISK_TITLE: "控制风险",
  TEXT_CONTROL_RISK_AREA_TITLE: "分析对象",
  TEXT_RISK_POINT_TITLE: "风险点",
  TEXT_RISK_POINT_AREA: "区域",
  TEXT_RISK_POINT_LABEL_NAME: "风险点",
  TEXT_RISK_POINT_LABEL_AREA: "所属区域",
  TEXT_RISK_POINT_LABEL_TYPE: "风险点类型",
  TEXT_RISK_POINT_LIST_AREA: "区域",
  TEXT_RISK_POINT_LIST_RP: "风险点",
  TEXT_RISK_POINT_ADD_GK_LABEL1: "公司级",
  TEXT_RISK_POINT_ADD_GK_LABEL2: "车间级",
  TEXT_RISK_POINT_ADD_GK_LABEL3: "班组级",
  TEXT_RISK_POINT_ADD_GK_LABEL4: "岗位级",
  TEXT_RISK_POINT_ADD_GK_PL: "责任人",
  TEXT_CONTROL_RISK_HAZARDOUS_FACTORS: "风险事件",
  TEXT_CONTROL_RISK_SEARCH_NAME: "风险事件名称",
  TEXT_RISK_POINT_SEARCH_NAME: "分析单元名称",
  TEXT_RISK_POINT_TOTAL_NAME: "分析单元",
  // 作业活动
  TEXT_JSA_NAME: "作业名称/内容",
  TEXT_FACILITY_NAME: "作业区域",
  TEXT_WORK_LOCATION: "作业位置",
  TEXT_WORK_DEPT: "作业单位",
  TEXT_WORK_ANALYST: "检测分析人"
};
const projectTextConfig = {
  RT: {
    TEXT_SAFETY_ANALYSIS_HEAD: "作业地点安全措施负责人",
    TEXT_SAFETY_ANALYSIS_WAITING: "待安全措施确定",
    TEXT_SAFETY_ANALYSIS_PROGRESS: "安全措施确定中",
    TEXT_SAFETY_ANALYSIS_DELIVER: "措施落实及安全交底",
    TEXT_WORK_APPROVER: "属地批准人",
    TEXT_PRODUCTION_UNIT_WORK_COMMANDER: "属地现场负责人",
    TEXT_OPEN_ANALYSIS: "需要工作前安全技术交底(JSA)",
    TEXT_ANALYSIS_MODULE: "工作前安全技术交底(JSA)",
    TEXT_ANALYSIS_TABLE: "工作前安全技术交底(JSA)表",
    TEXT_CONTROL_RISK_AREA_TITLE: "风险事件",
    TEXT_CONTROL_RISK_HAZARDOUS_FACTORS: "危险有害因素",
    TEXT_CONTROL_RISK_SEARCH_NAME: "危害因素名称",
    TEXT_RISK_POINT_SEARCH_NAME: "风险点名称",
    TEXT_RISK_POINT_TOTAL_NAME: "风险点"
  },
  YN: {
    TEXT_CONTROL_RISK_TITLE: "风险事件",
    TEXT_CONTROL_RISK_AREA_TITLE: "分析对象",
    TEXT_RISK_POINT_TITLE: "分析单元",
    TEXT_RISK_POINT_AREA: "分析对象",
    TEXT_RISK_POINT_LABEL_NAME: "分析单元名称",
    TEXT_RISK_POINT_LABEL_AREA: "分析对象",
    TEXT_RISK_POINT_LABEL_TYPE: "分析对象类型",
    TEXT_RISK_POINT_LIST_AREA: "对象",
    TEXT_RISK_POINT_LIST_RP: "单元",
    TEXT_RISK_POINT_ADD_GK_LABEL1: "公司",
    TEXT_RISK_POINT_ADD_GK_LABEL2: "车间",
    TEXT_RISK_POINT_ADD_GK_LABEL3: "班组",
    TEXT_RISK_POINT_ADD_GK_LABEL4: "岗位",
    TEXT_RISK_POINT_ADD_GK_PL: "负责人",
    TEXT_CONTROL_RISK_HAZARDOUS_FACTORS: "风险事件",
    TEXT_CONTROL_RISK_SEARCH_NAME: "风险事件名称",
    TEXT_RISK_POINT_SEARCH_NAME: "分析单元名称",
    TEXT_RISK_POINT_TOTAL_NAME: "分析单元"
  },
  BY: {
    TEXT_JSA_NAME: "检维修内容",
    TEXT_FACILITY_NAME: "所在装置",
    TEXT_WORK_LOCATION: "作业地点",
    TEXT_WORK_DEPT: "施工单位"
  },
  ZX: {
    TEXT_WORK_ANALYST: "气体检测分析人",
    TEXT_SAFETY_ANALYSIS_HEAD: "安全措施确认人"
  }
};

const textConfig = Object.assign(
  {},
  defaultTextConfig,
  projectTextConfig[globalConfig?.VUE_APP_NAME] || {},
  projectTextConfig[globalConfig?.VUE_APP_CUSTOM_MADE] || {}
);
export default textConfig;
