export default [
  {
    path: "/upkeep-record",
    name: "deviceServeList",
    component: () => import("@/views/deviceServe/deviceServe.vue"),
    meta: {
      cache: true,
      componentName: "DeviceServeList",
      roles: ["upkeep-record"]
    }
  },
  {
    path: "/serveDone/data/:id?",
    name: "deviceServeDone",
    component: () => import("@/views/deviceServe/serveDone.vue"),
    props: true,
    meta: {
      componentName: "DeviceServeDone",
      roles: ["upkeep-record"]
    }
  },
  {
    path: "/serveDoing/data/:id?",
    name: "deviceServeDoing",
    component: () => import("@/views/deviceServe/serveDoing.vue"),
    props: true,
    meta: {
      componentName: "DeviceServeDoing",
      roles: ["upkeep-record"]
    }
  }
];
