export default [
  {
    path: "/occupational-health",
    name: "occupational-health",
    component: () =>
      import(
        /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/OccupationalHealth.vue"
      )
  },
  {
    path: "/health",
    name: "health",
    component: { render: h => h("router-view") },
    meta: {
      roles: ["occupational-health-conf"]
    },
    redirect: "/health/inspect",
    children: [
      {
        path: "inspect",
        name: "health-inspect",
        meta: {
          roles: ["occupational-health-conf"]
        },
        component: () =>
          import(
            /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/HealthInspect.vue"
          )
      },
      {
        path: "inspect/detail/:id",
        name: "health-inspect-detail",
        meta: {
          roles: ["occupational-health-conf"]
        },
        component: () =>
          import(
            /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/HealthInspectDetail.vue"
          )
      },
      {
        path: "labor",
        name: "health-labor",
        meta: {
          roles: ["occupational-health-conf"]
        },
        component: () =>
          import(
            /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/LaborProtection.vue"
          )
      },
      {
        path: "batch-sign",
        name: "health-labor-batch-sign",
        meta: {
          roles: ["occupational-health-conf"]
        },
        component: () =>
          import(
            /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/LaborBatchSign.vue"
          )
      },
      {
        path: "labor/detail/:id",
        name: "health-labor-detail",
        meta: {
          roles: ["occupational-health-conf"]
        },
        component: () =>
          import(
            /* webpackChunkName: "occupational" */ "@/views/occupationalHealth/LaborProtectionDetail.vue"
          )
      }
    ]
  }
];
