import Vue from "vue";
import "./plugins/globalConfig";
import "reset-css";
import "../src/utils/sentry";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import login from "./utils/login";
import { Dialog, Lazyload } from "vant";
import { setHeaderToken } from "./utils/axios";
import "./polyfill";
import "./utils/appApi";
import "./utils/networkCheck";
//南京引用 -----------事故websocket
import socket from "./utils/socket";
const env = window.globalConfig;
if (env.VUE_APP_VCONSOLE === "true" && env.NODE_ENV !== "production") {
  new (require("vconsole"))();
}

//----------------------南京引入-----------------------
//地图画点，线，面组件
import TyMap from "@/views/accidentReport/components/tyMap.vue";
Vue.component("ty-map", TyMap);

// 注册全局组件，全局组件不要写到文件夹下，全局组件统一扁平化
import installVant from "./plugins/vant";
import installComponents from "./plugins/components";
import readMessage from "@/mixins/readMessage";
import userInfoMixin from "@/mixins/userInfoMixin";
import projectTextMixin from "@/mixins/projectTextMixin";
import projectConditionMixin from "@/mixins/projectConditionMixin";
import VanEmpty from "@/components/VanEmpty";

// 自己的样式中有覆盖第三方的样式，所以最后引用自己的样式，确保自己的样式能够覆盖第三方的样式。
import "./assets/scss/index.scss";

installVant(Vue);
installComponents(Vue);

Vue.component("van-empty", VanEmpty);

Vue.mixin(readMessage);
Vue.mixin(userInfoMixin);
Vue.mixin(projectTextMixin);
Vue.mixin(projectConditionMixin);

Vue.use(Lazyload, {
  lazyComponent: true
});

Vue.config.productionTip = false;

// token有效登录后的页面，无效进入登录页
router.beforeEach((to, from, next) => {
  // 使用 name 跳转控制路由跳转
  if (to.matched.length === 0) {
    return next({ path: "/403" });
  }

  // token存在
  if (login.isLogin()) {
    store.dispatch("keepToken");
    store.commit("setBtnAuth", to.path);
    if (to.path === "/login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.meta && to.meta.authority === false) {
      next();
    } else {
      if (window.APP.getPlatform().isAndroid) {
        Dialog.confirm({
          title: "",
          message: "登录已过期，请重新登录！"
        }).then(() => {
          window.androidH5.logout();
        });
      } else {
        next("/login");
      }
    }
  }
});

const initApp = () => {
  return new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
};

async function initAppData() {
  setHeaderToken();
  try {
    await store.dispatch("getProfile");
    await store.dispatch("getMenus");
    await store.dispatch("getDefinedMenus");
    await store.commit("setLoginStatus", true);
    await store.dispatch("getGlobalModuleFeatureConfigs");
    const roles = store.state.menu.roles;
    await store.dispatch("generateRoutes", roles);
    const dynamicRoutes = store.state.menu.dynamicRoutes;
    dynamicRoutes.forEach(dr => router.addRoute("/", dr));
    //开启websocket
    if (window.globalConfig.VUE_APP_ACCIDENT_SOCKET) {
      socket.startSocket();
    }
    initApp();
  } catch (e) {
    console.log("🚀 ~ file: main.js ~ line 100 ~ initAppData ~ e", e);
    window.sentry?.captureException(e);
  }
}
if (window.APP.getPlatform().isAndroid) {
  window.tokenCallBack = (code, value, expireTime) => {
    if (code === "0") {
      login.setToken({
        token: value,
        expireTime: expireTime
      });
      initAppData();
    } else {
      window.androidH5.logout();
    }
  };
  if (
    location.href.includes("/user/password") ||
    location.href.includes("/user/validphone") ||
    location.href.includes("/user/setnewpwd") ||
    location.href.includes("/user/activated")
  ) {
    initApp();
  } else {
    window.android.getToken("tokenCallBack");
  }
} else {
  if (login.isLogin()) {
    initAppData();
  } else {
    store.commit("setLoginStatus", false);
    initApp();
  }
}
