export default [
  //事故上报
  {
    path: "/accidentReport",
    name: "AccidentReport",
    component: () => import("@/views/accidentReport/index.vue"),
    meta: { cache: true, componentName: "AccidentReport" }
  },
  //事故预览
  {
    path: "/preview",
    name: "Preview",
    component: () => import("@/views/accidentReport/preview.vue"),
    meta: { cache: true, componentName: "Preview" }
  },
  //地图拾点
  {
    path: "/map",
    name: "Accidentmap",
    component: () => import("@/views/accidentReport/accidentmap.vue"),
    meta: { cache: false, componentName: "Accidentmap" }
  },
  //事故续报
  {
    path: "/accidentNextReport",
    name: "AccidentNextReport",
    component: () => import("@/views/accidentNextReport/index.vue"),
    meta: { cache: false, componentName: "AccidentNextReport" }
  }
];
