import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import ValidPhone from "../views/user/ValidPhone.vue";
import UserSetNewPwd from "../views/user/UserSetNewPwd.vue";
import UserPasswordForget from "../views/user/UserPasswordForget";
import UserActivated from "../views/user/UserActivated";
import UserPasswordReset from "../views/user/UserPasswordReset";

import moduleRoutes from "./requireAll";

//解决重复路由报错
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(e => e);
};
const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(e => e);
};

Vue.use(VueRouter);

const constantRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      authority: false
    }
  },
  {
    path: "/user/password",
    name: "userPasswordForget",
    component: UserPasswordForget,
    meta: {
      authority: false
    }
  },
  {
    path: "/user/activated",
    name: "userActivated",
    component: UserActivated,
    meta: {
      authority: false
    }
  },
  {
    path: "/user/password/reset",
    name: "userPasswordReset",
    component: UserPasswordReset,
    meta: {
      authority: false
    }
  },
  {
    path: "/user/validphone",
    name: "ValidPhone",
    component: ValidPhone,
    meta: {
      authority: false,
      cache: true,
      componentName: "ValidPhone"
    }
  },
  {
    path: "/user/setnewpwd",
    name: "UserSetNewPwd",
    component: UserSetNewPwd,
    meta: {
      authority: false
    }
  },
  {
    path: "/403",
    component: () =>
      import(/* webpackChunkName: "403" */ "@/views/permission/403.vue")
  }
];

const asyncRoutes = [
  ...moduleRoutes,
  {
    path: "*",
    redirect: "/403"
  }
];

function createRouter() {
  return new VueRouter({
    mode: "history",
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
}

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export { constantRoutes, asyncRoutes };

export default router;
