import { deepFreeze } from "@/utils";
import projectTextConfig from "@/utils/projectTextConfig";
export const WORKTICKET_STATUS = {
  Draft: 0, //待开票审批
  Cancel: 1, //取消
  Safety_analysis: 2, //待安全分析
  Analysing: 3, //安全分析中
  Gas_monitor: 4, //待气体检测
  Safety_disclosure: 5, //待安全交底
  Safety_disclosureing: 5.001, //安全交底中:客户端特有，服务器没有这个字段
  Safety_disclosure_sign: 5.002, // 安全交底签字
  ReferralApproving: 6.001, //转审
  ApprovingPassOrBack: 6.002, //退回/通过页面
  Approving: 6, //审批中
  Working: 7, //作业中
  Delivering: 8, //待验收
  Qualified: 9, //合格
  Unqualified: 10, //不合格--待验收
  // Stop: 11, // 终止，实际是一个功能，不存在这个流转状态；终止--待验收
  PendingJob: 11, // 待作业
  Paused: 12, // 暂停中
  Close: 13, // 关闭
  waitSubmit: 14, // 待提交
  Craft_safety_analysis: 15, // 待工艺安全分析
  Craft_analysing: 16, // 工艺安全分析中
  Craft_safety_disclosure: 17, // 待工艺安全交底
  Overhaul_safety_analysis: 18, // 待检修安全分析
  Overhaul_analysing: 19, // 检修安全分析中
  Overhaul_safety_disclosure: 20 // 待检修安全交底
};

export const WORKTICKET_STATUS_NAME = {
  [WORKTICKET_STATUS.Draft]: "待开票审批",
  [WORKTICKET_STATUS.Cancel]: "取消",
  [WORKTICKET_STATUS.Safety_analysis]:
    projectTextConfig.TEXT_SAFETY_ANALYSIS_WAITING,
  [WORKTICKET_STATUS.Analysing]:
    projectTextConfig.TEXT_SAFETY_ANALYSIS_PROGRESS,
  [WORKTICKET_STATUS.Gas_monitor]: "待气体检测",
  [WORKTICKET_STATUS.Safety_disclosure]:
    projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
  [WORKTICKET_STATUS.Safety_disclosure_sign]:
    projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
  [WORKTICKET_STATUS.Safety_disclosureing]: "安全交底",
  [WORKTICKET_STATUS.ReferralApproving]: "审批中",
  [WORKTICKET_STATUS.ApprovingPassOrBack]: "审批",
  [WORKTICKET_STATUS.Approving]: "审批中",
  [WORKTICKET_STATUS.Working]: "作业中",
  [WORKTICKET_STATUS.Delivering]: "待验收",
  [WORKTICKET_STATUS.Qualified]: "待关闭",
  [WORKTICKET_STATUS.Unqualified]: "待验收",
  // [WORKTICKET_STATUS.Stop]: "终止",
  [WORKTICKET_STATUS.PendingJob]: "待作业",
  [WORKTICKET_STATUS.Paused]: "暂停中",
  [WORKTICKET_STATUS.Close]: "关闭",
  [WORKTICKET_STATUS.waitSubmit]: "待提交",
  [WORKTICKET_STATUS.Craft_safety_analysis]: "待工艺安全分析",
  [WORKTICKET_STATUS.Craft_analysing]: "工艺安全分析中",
  [WORKTICKET_STATUS.Craft_safety_disclosure]: "待工艺安全交底",
  [WORKTICKET_STATUS.Overhaul_safety_analysis]: "待检修安全分析",
  [WORKTICKET_STATUS.Overhaul_analysing]: "检修安全分析中",
  [WORKTICKET_STATUS.Overhaul_safety_disclosure]: "待检修安全交底"
};

export const REAL_START_WHITE_LIST = [
  WORKTICKET_STATUS.Working,
  WORKTICKET_STATUS.Delivering,
  WORKTICKET_STATUS.Qualified,
  WORKTICKET_STATUS.Unqualified,
  WORKTICKET_STATUS.Paused,
  WORKTICKET_STATUS.Close
];

export const REAL_END_WHITE_LIST = [
  WORKTICKET_STATUS.Delivering,
  WORKTICKET_STATUS.Qualified,
  WORKTICKET_STATUS.Unqualified,
  WORKTICKET_STATUS.Close
];

export const GUARDIAN_BTN_STATUS_MAP_WHITE_LIST = {
  [WORKTICKET_STATUS.Draft]: ["取消"],
  [WORKTICKET_STATUS.Safety_analysis]: ["安全分析"],
  [WORKTICKET_STATUS.Analysing]: ["完成分析"],
  [WORKTICKET_STATUS.Gas_monitor]: ["完成"],
  [WORKTICKET_STATUS.PendingJob]: ["开始作业"],
  [WORKTICKET_STATUS.Working]: ["终止", "暂停", "完成"],
  [WORKTICKET_STATUS.Paused]: ["继续", "终止", "完成"],
  [WORKTICKET_STATUS.Delivering]: ["验收合格", "验收不合格"],
  [WORKTICKET_STATUS.Unqualified]: ["验收合格", "验收不合格"],
  [WORKTICKET_STATUS.Qualified]: ["关闭"]
};

export const SPECIAL_TICKETS_EXPORT_WHITE_LIST = [
  WORKTICKET_STATUS.Working,
  WORKTICKET_STATUS.Delivering,
  WORKTICKET_STATUS.Qualified,
  WORKTICKET_STATUS.Unqualified,
  WORKTICKET_STATUS.PendingJob,
  WORKTICKET_STATUS.Paused,
  WORKTICKET_STATUS.Close
];

/**
 * 9种作业类型
 * @type {{}}
 */
export const WORKTICKET_TYPE = {
  hoisting: "5", //吊装作业
  normal: "0", //普通作业
  ground: "6", //动土作业
  fire: "1", //动火作业
  elevation: "3", //高处作业
  breaker: "7", //断路作业
  blind_disk: "4", //盲板抽堵作业
  space: "2", //受限空间作业
  temporary_power: "8", //临时用电作业
  pipeline: "9", // 管线打开
  overhaul: "10", // 一般检修
  repair: "11", // 一般检修2
  "equipment-safety": "18" // 设备检修安全作业
};
for (let key in WORKTICKET_TYPE) {
  WORKTICKET_TYPE[WORKTICKET_TYPE[key]] = WORKTICKET_TYPE[key];
}

export const BUSINESSTYPE = {
  indicator: 1,
  alarm: 2
};

// 流程图节点
export const PROCESS_LIST = () => [
  {
    nodeName: "申请",
    type: "circle",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: "",
    personName: ""
  },
  {
    nodeName: "待开票审批",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 9,
    personName: projectTextConfig.TEXT_WORK_APPROVER
  },
  {
    nodeName: "取消",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: "",
    personName: ""
  },
  {
    nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_WAITING,
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 4,
    personName: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD
  },
  {
    nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_PROGRESS,
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 4,
    personName: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD
  },
  {
    nodeName: "待气体检测",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 5,
    personName: projectTextConfig.TEXT_WORK_ANALYST
  },
  {
    nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 4,
    personName: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD
  },
  {
    nodeName: "审批中",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: "",
    personName: ""
  },
  {
    nodeName: "待作业",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    lineType: "polyline",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "作业中",
    type: "diamond",
    hasNext: true,
    isShow: true,
    category: "normal",
    lineType: "polyline",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "暂停中",
    type: "diamond",
    hasNext: true,
    isShow: true,
    category: "normal",
    lineType: "polyline",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "待验收",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "待关闭",
    type: "rect",
    hasNext: true,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "作业关闭",
    type: "rect",
    hasNext: false,
    isShow: true,
    category: "normal",
    arrowStatus: "",
    personType: 2,
    personName: "监护人"
  },
  {
    nodeName: "待工艺安全分析",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 36,
    personName: "安全负责人"
  },
  {
    nodeName: "工艺安全分析中",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 36,
    personName: "安全负责人"
  },
  {
    nodeName: "待工艺安全交底",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 36,
    personName: "安全负责人"
  },
  {
    nodeName: "待检修安全分析",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 37,
    personName: "检修负责人"
  },
  {
    nodeName: "检修安全分析中",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 37,
    personName: "检修负责人"
  },
  {
    nodeName: "待检修安全交底",
    type: "rect",
    hasNext: false,
    isShow: false,
    category: "normal",
    arrowStatus: "",
    personType: 37,
    personName: "检修负责人"
  }
];

// 流程图节点对象
// export const PROCESS_OBJ = () => ({
//   start: {
//     nodeName: "申请",
//     type: "circle",
//     hasNext: true
//   },
//   0: {
//     index: 0,
//     nodeName: "待开票审批",
//     type: "rect",
//     hasNext: true,
//     params: {
//       typeText: projectTextConfig.TEXT_WORK_APPROVER,
//       type: 9
//     }
//   },
//   1: {
//     index: 1,
//     nodeName: "已取消",
//     type: "rect"
//   },
//   2: {
//     index: 2,
//     nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_WAITING,
//     type: "rect",
//     hasNext: true,
//     params: {
//       typeText: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD,
//       type: 4
//     }
//   },
//   3: {
//     index: 3,
//     nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_PROGRESS,
//     type: "rect",
//     hasNext: true,
//     params: {
//       typeText: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD,
//       type: 4
//     }
//   },
//   4: {
//     index: 4,
//     nodeName: "待气体检测",
//     type: "rect",
//     hasNext: true,
//     isHide: false,
//     params: {
//       typeText: "检测分析人",
//       type: 5 // 气体检测分析人
//     }
//   },
//   5: {
//     index: 5,
//     nodeName: projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
//     type: "rect",
//     hasNext: true,
//     params: {
//       typeText: projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD,
//       type: 4
//     }
//   },
//   6: {
//     index: 6,
//     nodeName: "审批中",
//     type: "rect",
//     hasNext: true,
//     params: {
//       typeText: "审批人",
//       type: 6
//     }
//   },
//   7: {
//     index: 7,
//     nodeName: "待作业",
//     type: "rect",
//     hasNext: true,
//     category: "current",
//     lineType: "polyline",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   8: {
//     index: 8,
//     nodeName: "作业中",
//     type: "diamond",
//     hasNext: true,
//     category: "normal",
//     lineType: "polyline",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   9: {
//     index: 9,
//     nodeName: "暂停中",
//     type: "diamond",
//     hasNext: true,
//     category: "normal",
//     lineType: "polyline",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   10: {
//     index: 10,
//     nodeName: "待验收",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   11: {
//     index: 11,
//     nodeName: "待关闭",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   12: {
//     index: 12,
//     nodeName: "作业关闭",
//     type: "rect",
//     category: "normal",
//     params: {
//       typeText: "监护人",
//       type: 2
//     }
//   },
//   13: {
//     index: 13,
//     nodeName: "待工艺安全分析",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "安全负责人",
//       type: 36
//     }
//   },
//   14: {
//     index: 14,
//     nodeName: "工艺安全分析中",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "安全负责人",
//       type: 36
//     }
//   },
//   15: {
//     index: 15,
//     nodeName: "待工艺安全交底",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "安全负责人",
//       type: 36
//     }
//   },
//   16: {
//     index: 16,
//     nodeName: "待检修安全分析",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "检修负责人",
//       type: 37
//     }
//   },
//   17: {
//     index: 17,
//     nodeName: "检修安全分析中",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "检修负责人",
//       type: 37
//     }
//   },
//   18: {
//     index: 18,
//     nodeName: "待检修安全交底",
//     type: "rect",
//     hasNext: true,
//     category: "normal",
//     params: {
//       typeText: "检修负责人",
//       type: 37
//     }
//   }
// });

export const iconConf = deepFreeze({
  1: {
    name: "daiqueren1",
    color: "#3683F4"
  },
  2: {
    name: "daifankui",
    color: "#F68446"
  },
  3: {
    name: "yiwancheng",
    color: "#8EB433"
  }
});

export const RISK_LEVEL = [
  {
    label: "重大风险",
    value: "1004"
  },
  {
    label: "较大风险",
    value: "1003"
  },
  {
    label: "一般风险",
    value: "1002"
  },
  {
    label: "低风险",
    value: "1001"
  }
];

export const LS_DATA = [
  {
    value: "5",
    label: "5"
  },
  {
    value: "4",
    label: "4"
  },
  {
    value: "3",
    label: "3"
  },
  {
    value: "2",
    label: "2"
  },
  {
    value: "1",
    label: "1"
  }
];

export const lecLTableData = [
  {
    value: "10.00",
    label: "10"
  },
  {
    value: "6.00",
    label: "6"
  },
  {
    value: "3.00",
    label: "3"
  },
  {
    value: "1.00",
    label: "1"
  },
  {
    value: "0.50",
    label: "0.5"
  },
  {
    value: "0.20",
    label: "0.2"
  },
  {
    value: "0.10",
    label: "0.1"
  }
];

export const lecETableData = [
  {
    value: "10.00",
    label: "10"
  },
  {
    value: "6.00",
    label: "6"
  },
  {
    value: "3.00",
    label: "3"
  },
  {
    value: "2.00",
    label: "2"
  },
  {
    value: "1.00",
    label: "1"
  },
  {
    value: "0.50",
    label: "0.5"
  }
];

export const lecCTableData = [
  {
    value: "100.00",
    label: "100"
  },
  {
    value: "40.00",
    label: "40"
  },
  {
    value: "15.00",
    label: "15"
  },
  {
    value: "7.00",
    label: "7"
  },
  {
    value: "2.00",
    label: "2"
  },
  {
    value: "1.00",
    label: "1"
  }
];

export const evaluationMethodData = [
  {
    value: "1",
    label: "LS"
  },
  {
    value: "0",
    label: "LEC"
  }
];
