import { mapActions } from "vuex";
import messageApi from "@/api/message";
export default {
  watch: {
    "$route.query": {
      handler(val) {
        if (val && val.messageId) {
          this.readMessage();
        }
      }
    }
  },
  methods: {
    ...mapActions(["getMessageCountNoticesAction"]),
    readMessage() {
      const { query } = this.$route;
      if (query.messageId) {
        // console.log(`%c 读取 ${path} 页面的消息`, "color: #136ad5");
        messageApi
          .updateBasePageNotices({
            ids: query.messageId
          })
          .then(() => {
            // console.log("%c 读取消息成功，更新消息数量", "color: #fb8a2e");
            this.getMessageCountNoticesAction();
          });
      }
    }
  }
};
