const appName = globalConfig?.VUE_APP_SERVICE_PROJECT?.toUpperCase();
export default [
  {
    path: "/faultadmin/:id?",
    name: "DeviceFault",
    meta: {
      roles: ["faultadmin"],
      cache: true,
      componentName: "DeviceFault"
    },
    component: () =>
      import(
        /* webpackChunkName: "DeviceFault" */ "@/views/device/fault/deviceFault"
      )
  },
  {
    path: "/faultadmin/add",
    name: "DeviceFaultAdd",
    meta: { type: "add", roles: ["faultadmin"] },
    component: () =>
      import(
        /* webpackChunkName: "DeviceFaultAdd" */ `@/views/device/${
          appName === "RT" ? "fault" : "faultRx"
        }/deviceFaultDetail`
      )
  },
  {
    path: "/faultadmin/:id(\\d+)",
    name: "DeviceFaultDetail",
    props: true,
    meta: { roles: ["faultadmin"] },
    component: () =>
      import(
        /* webpackChunkName: "DeviceFaultDetail" */ `@/views/device/${
          appName === "RT" ? "fault" : "faultRx"
        }/deviceFaultDetail`
      )
  },
  {
    path: "/faultadmin/flow",
    name: "DeviceFaultFlow",
    meta: { roles: ["faultadmin"] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "trouble-flow" */ "@/views/device/fault/deviceFaultFlow"
      )
  }
];
